import { Component, OnInit, ViewChild, ModuleWithComponentFactories } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { Constants } from '../../../services/constants.service';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from '../../../app/app.service';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AutomatedTestingService } from '../../../services/automated-testing.service';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class AutomatedTestingListComponent implements OnInit {
  public loginUser: any = '';
  public testingList: any = [];
  public testId = 0;
  public totalCount: number = 0;
  public pageNumber = 1;
  public pageSize = 25;
  public sortOrder = "desc";
  public sortBy = "DateAdded";
  public search: any = "";
  public year: any = moment().format('YYYY');
  public projectId = 0;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  constructor(
    private appService: AppService,
    private noti: NotificationService,
    private autoTestSrv: AutomatedTestingService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Automated Testing"
    });
    this.projectId = this.route.snapshot.queryParams.id;
    if (!!this.projectId) {

    } else {
      this.router.navigateByUrl('/project/list');
    }
      this.loginUser = JSON.parse(localStorage.getItem('user'));
      this.getAutomatedTesting();
      $('[data-toggle="tooltip"]').tooltip();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  // getAutomatedTesting() {
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     dom: 'frtlip',
  //     pageLength: 100,
  //     order: [[3, 'desc']],
  //     processing: true,
  //     "destroy": true,
  //     "columnDefs": [
  //       { "width": "auto", "targets": 0 },
  //       { "width": "auto", "targets": 1 },
  //       { "width": "auto", "orderable": false, "targets": 2 },
  //       { "width": "auto", "targets": 3 },
  //     ]
  //   };
  //   this.autoTestSrv.getTestingList().subscribe(response => {
  //     if (response.status == "ok") {
  //         console.log(response);
  //         this.testingList = response.data;
  //         this.rerender();
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });
  // }

  searchOrder(){
    if(this.search.length > 2 || this.search.length == 0){
      this.rerender();
    }
  }
  
  getAutomatedTesting() {
    this.dtOptions = {
      // pagingType: 'full_numbers',
      pageLength: this.pageSize,
      dom: 'rtlip',
      order: [[3, 'desc']],
      "columnDefs": [
        { "width": "20%","targets": 0, "orderable": false },
        { "width": "10%","targets": 1, "orderable": false },
        { "width": "50%","targets": 2, "orderable": false },
        { "width": "10%","targets": 3},
        { "width": "10%","targets": 4, "orderable": false },

      ],
      processing: true,
      serverSide: true, 
      ajax: (dTParameters: any, callback) => {
        this.pageSize = dTParameters['length'];
        this.pageNumber = dTParameters['start'] / this.pageSize + 1;
        let searchText = this.search;
        this.sortOrder = dTParameters.order[0].dir;

        this.autoTestSrv.getTestingList(this.projectId, this.pageNumber, this.pageSize, this.sortBy, this.sortOrder, searchText).subscribe(response => {
          if (response != null) {
            if (response.status == "ok") { 
              this.testingList = response.data.value;
              if (response.data.value.length > 0) {
                this.totalCount = response.data.value[0].totalRecords;
              } else {
                this.totalCount = 0;
              }
              
              if (this.testingList.length != 0) {
                // console.log(this.taskList.length);
                setTimeout(() => {
                  $(".dataTables_empty").css("display", "none");
                }, 200);
              }
              else{
                setTimeout(() => {
                  $(".dataTables_empty").css("display", "block");
                }, 200);
              }
              callback({
                recordsTotal: this.totalCount,
                recordsFiltered: this.totalCount,
                data: []
              });
            }
          }
        }, error => {
          if (error.status == 401) { }
        });
      },
      columns: [
        { "data": "projectName" },
        { "data": "test" },
        { "data": "description", "orderable": false},
        { "data": "dateAdded" },
      ]
    };
  }

  confirmDelete(id) {
    this.testId = id;
    $("#deleteModal").modal("show");
  }
  deleteTest() {
    this.autoTestSrv.deleteTest(this.testId).subscribe(res => {
      if (res.status == "ok") {
        for (var i = 0; i < this.testingList.length; i++) {
          if (this.testingList[i].id == this.testId) {
            this.testingList.splice(i, 1);
          }
        }
        this.rerender();
        this.noti.notification("Success", "Test Log Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Cannot Delete This Test Log.", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });

    $("#deleteModal").modal("hide");
  }

}
