import { NgbDateStruct, NgbCalendar, NgbDate, NgbInputDatepicker, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/services/user.service';
import { AppService } from '../../app.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GlobalVariable } from "src/app/globals";
import { NotesService } from 'src/services/notes.service';
import { NotificationService } from 'src/services/notification.service';
import { AuthenticateService } from 'src/services/authenticate.service';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Constants } from 'src/services/constants.service';
import { ProjectService } from 'src/services/projects.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
 
  public noteId : number = 0;
  public loginUser : any ='';
  public noteFiles = [];
  public commentList = [];
  public comments=[];
  public noteDetail : any ='';
  public commentId: number = 0;
  public comment = "";
  public userdeatils : any [];
  public noteList = [];
  public noteFileId: number = 0;
  public isRequested: boolean = true;
  public year: any = moment().format('YYYY');

  public pageNumber = 1;
  public pageSize = 1000;
  public sortBy = "DateAdded";
  public sortOrder = "desc";
  public search = "";
  public totalRecords = 0;

  sanitizedHtmlContent: SafeHtml;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor( 
    private router: Router,
   private appService: AppService,
   private route: ActivatedRoute,
   private notesService: NotesService,
   private noti: NotificationService,
   private authService: AuthenticateService,
   private sanitizer: DomSanitizer
  ){}

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Meeting Notes"
    });
    this.noteId = this.route.snapshot.queryParams.id;
     if (!!this.noteId) {
      this.loginUser = JSON.parse(localStorage.getItem('user'));
      
      this.getFiles(this.noteId);
     this.getAllComments(this.noteId);
     this.getNote(this.noteId);
    // this.getWebsites(this.noteId);
     
  }else{
    this.router.navigateByUrl('/notes/view');
  }
}

getFiles(id) {
  this.noteFiles = [];
  this.notesService.getFiles(id).subscribe(response => {
    if (response.status == "ok") {
      this.noteFiles = response.data;
     //console.log(this.noteFiles);
    } else {
        
    }
  }, error => {
    if (error.status == 401) { }
  });
}

getAllComments(id) {
  this.notesService.getComments(id).subscribe(response => {
    if (response.status == "ok") {
      this.commentList = response.data;
      //console.log(this.commentList);
    }
  }, error => {
    if (error.status == 401) { }
  });
}

getNote(id){
  this.notesService.getNote(id).subscribe(response => {
    if (response.status == "ok") {
      this.noteDetail = response.data;
      this.noteDetail.participants= JSON.parse(this.noteDetail.participants)
      console.log(this.noteDetail);
      this.sanitizedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.noteDetail.htmlText);
     // console.log(this.noteDetail.htmlText);
    }
  }, error => {
    if (error.status == 401) { }
  });
}

addComment() {
  if (this.comment.trim().length > 1) {
    var CommentObj = {
      NoteId: 0,
      UserId: 0,
      Comment: ""
    };
    CommentObj.NoteId = this.noteId;
    CommentObj.UserId = this.loginUser.userId;
    CommentObj.Comment = this.comment;

    this.notesService.addComment(CommentObj).subscribe(response => {
      if (response.status == "ok") {
        this.comment = "";
        $("#commenttextarea1").css("height", "25px");
       // $("#commentAddBtn").slideUp();
        this.commentList.push(response.data);
        this.commentList.sort(function (a, b) {
          if (a.dateAdded > b.dateAdded) { return -1; }
          if (a.dateAdded < b.dateAdded) { return 1; }
          return 0;
        });
        for (var i = 0; i < this.noteList.length; i++) {
          if (this.noteList[i].id == response.data.noteId) {
            if (this.noteList[i].commentsCount) {
              this.noteList[i].commentsCount += 1;
            } else {
              this.noteList[i].commentsCount = 1;
            }
            break;
          }
        }
        // this.noti.notification("Success", "Comment Added Successfully", "success");
      } else {
        this.noti.notification("Error", "Error Adding Comment ", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
  } else {
    this.noti.notification("Error", "Please write comment atleast 2 letters", "danger");
  }

}

slideAlert(action, id) {
  if (action == 'file') {
    let prevNId = this.noteFileId;
    if (prevNId != id) {
      $("#box-file-popup-" + prevNId).animate({
        width: "hide"
      }, 500);
      setTimeout(() => {
        $('#box-file-btn-' + prevNId).fadeIn(1200);
      }, 500);
    }
    this.noteFileId = id;
    $('#box-file-btn-' + id).fadeOut(150);
    $("#box-file-popup-" + id).animate({
      width: "toggle"
    });
  } else if (action == 'comment') {
    let prevCId = this.commentId;
    if (prevCId != id) {
      $("#box-comment-popup-" + prevCId).animate({
        width: "hide"
      }, 500);
      setTimeout(() => {
        $('#box-comment-btn-' + prevCId).fadeIn(1200);
      }, 500);
    }
    this.commentId = id;
    $('#box-comment-btn-' + id).fadeOut(150);
    $("#box-comment-popup-" + id).animate({
      width: "toggle"
    });
  }

}

// deleteCommentConfirm(id: number) {
//   this.notesService.deleteComment(id).subscribe(response => {
//     if (response.status == "ok") {
//       // Assuming 'this.comments' is the array holding the list of comments
//       window.location.reload(); 
//     // Handle any errors here
//     }
//   });
// }

deleteCommentConfirm(commentId: number) {
  this.notesService.deleteComment(commentId).subscribe(response => {
    if (response.status === "ok") {
      // Remove the comment from the list without reloading the page
      this.commentList = this.commentList.filter(c => c.id !== commentId);
    } else {
      console.error("Failed to delete comment.");
    }
  }, error => {
    console.error("Error deleting comment:", error);
  });
}



deleteCancel(action, id) {
    if (action == 'file') {
      $("#box-file-popup-" + id).animate({
        width: "toggle"
      }, 500);
      setTimeout(() => {
        $('#box-file-btn-' + id).fadeIn(1200);
      }, 500);
    } else if (action == 'comment') {
      $("#box-comment-popup-" + id).animate({
        width: "toggle"
      }, 500);
      setTimeout(() => {
        $('#box-comment-btn-' + id).fadeIn(1200);
      }, 500);
    }
  }

}
