import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { JobApplicationsService } from 'src/services/jobApplications.service';
import { NotificationService } from 'src/services/notification.service';
import { UserService } from 'src/services/user.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { event } from 'jquery';
declare var $;

@Component({
  selector: 'app-job-application-detail',
  templateUrl: './job-application-detail.component.html',
  styleUrls: ['./job-application-detail.component.css']
})
export class JobApplicationDetailComponent implements OnInit {
  public loginUser: any = "";
  public appDetail: any = "";
  public interviewerDetail: any = "";
  public applicationFeedback: any = "";
  public userList: any = [];
  public logsList: any = [];
  public documents: any = [];
  public interviewerId = null;
  public applicationId: number = null;
  public candidateName = "";
  public candidateEmail = "";
  public candidatePhoneNbr = "";
  public candidatePosition: any;
  public assignmentNotes = "";
  public selectedMoment = new Date();
  public isPdfFormat: boolean = false;
  public isDocFormat: boolean = false;
  public isPending: boolean = false;
  public isAssigned: boolean = false;
  public isCVConfirmed: boolean = false;
  // public isFeedback: boolean = false;
  public isScreening: boolean = false;
  public isScreeningSchld: boolean = false;
  public isInOffice: boolean = false;
  // public isReason: boolean = false;
  public documentUrlTest: SafeResourceUrl;
  public screeningFeedback = "";
  private submitNotes = "";
  public errorTextarea = "";  
  public year: any = moment().format('YYYY');
  //feedback Object
  public resumeFeedbackObj = {
    Id: 0,
    UserId: null,
    ResumeId: null,
    Status: "",
    ScheduleCount: 0,
    InterviewFeedback: "",
    isNotify: false,
    CandidateName: "",
    CandidateEmail: "",
    InterviewerName: "",
    InterviewerEmail: "",
    Position: "",
    Schedule: new Date(),
    DateAdded: new Date(),
    DateUpdated: new Date()
  };

  public resumeEvaluation = {
    UserId: null,
    ResumeId: null,
    Type: "",
    Enthusiasm: 0,
    WorkExperience: 0,
    Education: 0,
    RequiredSkills: 0,
    Attitude: 0,
    Appearance: 0,
    TotalEvaluation: 0,
    DateAdded: new Date(),
    DateUpdated: new Date()
  };
  public screenRating: any = "";
  public isScreenRating: boolean = false;
  public inpersonRating: any = "";
  public isPersonRating: boolean = false;

  public parseData: any = "";

  public isNotifyCan: boolean = false;


  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private noti: NotificationService,
    private router: Router,
    private jobApplicationsService: JobApplicationsService,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.applicationId = this.route.snapshot.queryParams.id;
    this.resumeSeenSave(this.applicationId);
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.getUsersList();
    this.getApplication();
    this.selectedMoment.setDate(this.selectedMoment.getDate() + 1);

    // if (!!this.applicationId) {
    //   this.loginUser = JSON.parse(localStorage.getItem('user'));
    //   this.getUsersList();
    //   this.getApplication();
    //   this.selectedMoment.setDate(this.selectedMoment.getDate() + 1);
    // } else {
    //   this.router.navigateByUrl('/job-applications');
    // }   
    $('#evaluateCandidateModal').modal({
      backdrop: 'static',
      keyboard: false,
      show: false
    });
    
  }

  Detail(){
          $("#deleteModal").modal("show");
    
  }
  adjustHeight(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set new height based on scroll height
  }
  resumeSeenSave(id) {
    let data = JSON.parse(localStorage.getItem('resumeAction'));
    if (data != null) {
      var filteredEvents = data.filter(function (event) {
        return event.id == id;
      });

      if (filteredEvents == null || filteredEvents == "") {
        data.push({ 'id': id, 'seen': true });
        localStorage.setItem('resumeAction', JSON.stringify(data));
      }
    } else {
      let data = [{ 'id': id, 'seen': true }];
      localStorage.setItem('resumeAction', JSON.stringify(data));
    }
  }

  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    event.preventDefault();
    // if (tagName !== 'textarea') {
    //   return false;
    // }
  }

  getResumeLogs() {
    this.logsList = [];
    this.jobApplicationsService.getResumeLogs(this.appDetail.id).subscribe(response => {
      if (response.status == "ok") {
        this.logsList = response.data;
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  getApplication() {
    this.documents.length = 0;
    this.jobApplicationsService.getApplication(this.applicationId).subscribe((response) => {
      if (response.status == "ok") {
        this.appDetail = "";
        this.candidateName = "";
        this.candidateEmail = "";
        this.candidatePosition = "";
        this.candidatePhoneNbr = "";
        this.assignmentNotes = "";
        this.interviewerId = null;
        this.appDetail = response.data;
        this.applicationId = this.appDetail.id;
        this.applicationprocess();
      } else {

      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  getApplicationByIndex(action) {
    $(".loader-container").css("display", "table");
    $(".loader-response").css("display", "none");
    this.documents.length = 0;
    this.submitNotes = "";
    this.isScreenRating = false;
    this.isPersonRating = false;
    this.jobApplicationsService.getApplicationByIndex(this.applicationId, action).subscribe((response) => {
      if (response.status == "ok") {
        this.appDetail = "";
        this.candidateName = "";
        this.candidateEmail = "";
        this.candidatePosition = "";
        this.candidatePhoneNbr = "";
        this.assignmentNotes = "";
        this.interviewerId = null;
        this.appDetail = response.data;
        this.applicationId = this.appDetail.id;
        this.applicationprocess();
      } else if (response.status == "error") {
        this.noti.notification("Error", "No More Records.", "danger");
        $(".loader-container").css("display", "none");
        $(".loader-response").css("display", "block");
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  applicationprocess() {
    if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
      document.getElementById("note").innerHTML = "";

      if (this.appDetail.resumeStatus != 'pending' || this.appDetail.resumeStatus != 'cv_rejected') {
        this.getResumeEvaluationRating(this.applicationId, "screening");
        this.getResumeEvaluationRating(this.applicationId, "inperson")
      }
    }
    this.getResumeLogs();
    this.getResumeParseData(this.applicationId)
    this.resumeSeenSave(this.applicationId);
    if (!!this.appDetail) {
      if (!!this.appDetail.userId) {
        this.getUser();
      }
      if (!!this.appDetail.userId) {
        this.interviewerId = this.appDetail.userId;
      } else {

      }
      this.candidateName = this.appDetail.name;
      this.candidateEmail = this.appDetail.email;
      this.candidatePosition = this.appDetail.position;
      this.candidatePhoneNbr = this.appDetail.phone;
      switch (this.appDetail.resumeStatus) {
        case "pending":
          this.isPending = true;
          if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
            document.getElementById("note").innerHTML = "";
          }
          break;
        case "screening_assigned":
          this.isAssigned = true;
          this.assignmentNotes = this.appDetail.assignmentNotes;
          // document.getElementById("note").innerHTML = "";
          break;
        case "screening_accepted":
          if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
            document.getElementById("note").innerHTML = "Screening interview accepted";
          }
          break;
        case "screening_rejected":
          if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
            document.getElementById("note").innerHTML = "Screening interview rejected";
          }
          break;
        case "accepted":
          // this.getAppllicationFeedback();
          break;
        case "rejected":
          // this.getAppllicationFeedback();
          break;
        case "cv_rejected":
          break;
        case "cv_confirmed":
          this.isCVConfirmed = true;
          break;
        case "screening_scheduled":
          if (this.loginUser.accessLevel != 'super_admin' && this.loginUser.accessLevel != 'admin') {
            this.getAppllicationFeedbackFilter();
            this.isCVConfirmed = true;
            this.isScreening = true;
          }
          break;
        case "inperson_assigned":
          if (this.loginUser.accessLevel != 'super_admin' && this.loginUser.accessLevel != 'admin') {
            this.getAppllicationFeedback();
            // this.isCVConfirmed = true;   
            this.isScreeningSchld = true;
          }
          break;
        case "inperson_scheduled":
          if (this.loginUser.accessLevel != 'super_admin' && this.loginUser.accessLevel != 'admin') {
            this.getAppllicationFeedbackFilter();
            this.isCVConfirmed = true;
            this.isInOffice = true;
          }
          break;
        // default:
        //   document.getElementById("note").innerHTML = "";
      }
      let documentsUrl = this.appDetail.documentUrl.split(",");
      for (let docs = 0; docs < documentsUrl.length; docs++) {
        let extension = documentsUrl[docs].split('.').pop();
        if (extension == "pdf") {
          this.documentUrlTest = this.sanitizer.bypassSecurityTrustResourceUrl(documentsUrl[docs]);
          this.documents.push({ 'format': 'pdf', 'url': this.documentUrlTest });
        } else if (extension == "docx" || extension == "doc") {
          let url = "https://view.officeapps.live.com/op/embed.aspx?src=" + documentsUrl[docs];
          this.documentUrlTest = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.documents.push({ 'format': 'docx', 'url': this.documentUrlTest });
        } else if (extension == "html") {
          this.documentUrlTest = this.sanitizer.bypassSecurityTrustResourceUrl(documentsUrl[docs]);
          this.documents.push({ 'format': 'html', 'url': this.documentUrlTest });
        }

      }

      $(".loader-container").css("display", "none");
      $(".loader-response").css("display", "block");
    }
  }

  getAppllicationFeedback() {
    this.jobApplicationsService.getFeedback(this.applicationId).subscribe(response => {
      if (response.status == "ok") {
        this.applicationFeedback = response.data;
        if (this.applicationFeedback.scheduleCount == 4) {
          $(".reSchedule-btn").addClass('disabled');
        }
        if (this.applicationFeedback.status == "pending") {
          var local = moment.utc(this.applicationFeedback.schedule).local().format();
          this.selectedMoment = new Date(local);
          this.screeningFeedback = this.applicationFeedback.interviewFeedback;
        } else {
          this.selectedMoment.setDate(this.selectedMoment.getDate() + 1);
          this.screeningFeedback = "";
        }
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  getAppllicationFeedbackFilter() {
    this.jobApplicationsService.getFeedbackFilter(this.appDetail.id).subscribe(response => {
      if (response.status == "ok") {
        this.applicationFeedback = response.data;
        if (this.applicationFeedback.scheduleCount == 4) {
          $(".reSchedule-btn").addClass('disabled');
        }
        var local = moment.utc(this.applicationFeedback.schedule).local().format();
        this.selectedMoment = new Date(local);
        this.screeningFeedback = this.applicationFeedback.interviewFeedback;
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  getUsersList() {
    this.jobApplicationsService.getUserList().subscribe(response => {
      if (response.status == "ok") {
        this.userList = response.data;       
        if (!!this.appDetail.userId) {
          this.interviewerId = this.appDetail.userId;
        } else {
          // this.interviewerId = this.userList[0].userId;
        }
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  getUser() {
    this.userService.getUser(this.appDetail.userId).subscribe(response => {
      if (response.status == "ok") {
        this.interviewerDetail = response.data;
        switch (this.appDetail.resumeStatus) {
          case "pending":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "";
            }
            break;
          case "screening_assigned":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "This interview has been assigned to <b>" + this.interviewerDetail.firstName + "</b>"
            } else { }
            break;
          case "screening_accepted":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "Screening interview accepted";
            }
            break;
          case "screening_rejected":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "Screening interview rejected";
            }
            break;
          case "inperson_accepted":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "Inperson interview accepted by <b>" + this.interviewerDetail.firstName + "</b>";
            }
            break;
          case "inperson_rejected":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "Inperson interview rejected by <b>" + this.interviewerDetail.firstName + "</b>";
            }
            break;
          case "cv_rejected":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "CV Rejected by <b>" + this.interviewerDetail.firstName + "</b>";
            }
            break;
          case "cv_confirmed":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "";
            }
            break;
          case "screening_scheduled":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "Screening scheduled created by <b>" + this.interviewerDetail.firstName + "</b>";
            }
            break;
          case "inperson_assigned":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "This inperson interview has been assigned to <b>" + this.interviewerDetail.firstName + "</b>"
            }
            break;
          case "inperson_scheduled":
            if (this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'hr_manager') {
              document.getElementById("note").innerHTML = "Inperson scheduled created by <b>" + this.interviewerDetail.firstName + "</b>";
            }
            break;
          // default:
          //   document.getElementById("note").innerHTML = "";
        }
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  assignConfirm(data) {
    let isValid = this.validate();
    $(".assign-button").prop('disabled', true);
    if (data == "screening_assigned") {
      if (isValid) {
        if (!!this.interviewerId) {
          this.appDetail.name = this.candidateName;
          this.appDetail.email = this.candidateEmail;
          this.appDetail.position = this.candidatePosition;
          this.appDetail.phone = this.candidatePhoneNbr;
          this.appDetail.userId = this.interviewerId;
          this.appDetail.assignById = this.loginUser.userId;
          this.appDetail.resumeStatus = "screening_assigned";
          this.appDetail.assignmentNotes = this.assignmentNotes;
          this.appDetail.preInterviewNotes = "";

          this.updateApplicationStatus(this.appDetail);
        } else {
          $(".assign-button").prop('disabled', false);
          this.noti.notification("Error", "Please select Interviewer", "danger");
        }
      } else {
        this.noti.notification("Error", "Please fill all details", "danger");
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "inperson_assigned") {
      if (isValid) {
        this.appDetail.name = this.candidateName;
        this.appDetail.email = this.candidateEmail;
        this.appDetail.position = this.candidatePosition;
        this.appDetail.phone = this.candidatePhoneNbr;
        this.appDetail.assignById = this.loginUser.userId;;
        this.appDetail.resumeStatus = "inperson_assigned";
        this.appDetail.assignmentNotes = this.assignmentNotes;
        this.appDetail.preInterviewNotes = "";
        this.appDetail.userId = this.interviewerId;

        this.updateApplicationStatus(this.appDetail);
      } else {
        this.noti.notification("Error", "Please fill all details", "danger");
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "ignore") {
      if (isValid) {
        $("#ignoreModal").modal("hide");
        this.appDetail.name = this.candidateName;
        this.appDetail.email = this.candidateEmail;
        this.appDetail.position = this.candidatePosition;
        this.appDetail.phone = this.candidatePhoneNbr;
        // this.appDetail.assignById = null;
        this.appDetail.resumeStatus = "ignored";
        this.appDetail.assignmentNotes = "";
        this.appDetail.preInterviewNotes = this.submitNotes;

        this.updateApplicationStatus(this.appDetail);
      } else {
        this.noti.notification("Error", "Please fill all details", "danger");
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "rejected_submit") {
      if (isValid) {
        this.appDetail.name = this.candidateName;
        this.appDetail.email = this.candidateEmail;
        this.appDetail.position = this.candidatePosition;
        this.appDetail.phone = this.candidatePhoneNbr;
        this.appDetail.resumeStatus = "cv_rejected";
        this.appDetail.preInterviewNotes = this.submitNotes;

        this.updateApplicationStatus(this.appDetail);
      } else {
        this.noti.notification("Error", "Please fill all details", "danger");
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "cv_rejected") {
      if (this.submitNotes.trim().length > 3) {
        this.appDetail.name = this.candidateName;
        this.appDetail.email = this.candidateEmail;
        this.appDetail.position = this.candidatePosition;
        this.appDetail.phone = this.candidatePhoneNbr;
        this.appDetail.resumeStatus = "cv_rejected";
        this.appDetail.preInterviewNotes = this.submitNotes;
        this.appDetail.userId = this.loginUser.userId;
        this.appDetail.assignById = this.loginUser.userId;

        this.updateApplicationStatus(this.appDetail);
      } else {
        this.noti.notification("Error", "Please enter comment", "danger");
        $(".assign-button").prop('disabled', false);
      }

    } else if (data == "save") {
      if (isValid) {
        this.appDetail.name = this.candidateName;
        this.appDetail.email = this.candidateEmail;
        this.appDetail.position = this.candidatePosition;
        this.appDetail.phone = this.candidatePhoneNbr;
        this.appDetail.resumeStatus = "update";

        this.updateApplicationStatus(this.appDetail);
      } else {
        this.noti.notification("Error", "Please fill all details", "danger");
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "pending") {
      if (isValid) {
        this.appDetail.assignById = null;
        this.appDetail.resumeStatus = "schedule_cancelled";
        this.appDetail.assignmentNotes = "";
        this.appDetail.preInterviewNotes = "";

        this.updateApplicationStatus(this.appDetail);
      } else {
        this.noti.notification("Error", "Please fill all details", "danger");
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "nfa") {
      this.appDetail.resumeStatus = "nfa";
      this.updateNoFurtherAction(this.appDetail);
    } else {
      $(".assign-button").prop('disabled', false);
    }
  }

  updateFeedback(data) {
    $(".assign-button").prop('disabled', true);
    if (data == "pending") {
      this.resumeFeedbackObj.Id = 0;
      this.resumeFeedbackObj.UserId = this.appDetail.userId;
      this.resumeFeedbackObj.ResumeId = this.appDetail.id;
      this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
      this.resumeFeedbackObj.Status = "pending";
      this.resumeFeedbackObj.Schedule = this.selectedMoment;
      this.resumeFeedbackObj.isNotify = this.isNotifyCan;
      this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
      this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
      this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
      this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
      this.resumeFeedbackObj.Position = this.appDetail.position;//job position
      this.resumeFeedbackObj.ScheduleCount = 1;

      this.addAppllicationFeedback(this.resumeFeedbackObj);
    } else if (data == "inperson_scheduled") {
      this.resumeFeedbackObj.Id = this.applicationFeedback.id;
      this.resumeFeedbackObj.UserId = this.appDetail.userId;
      this.resumeFeedbackObj.ResumeId = this.appDetail.id;
      this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
      this.resumeFeedbackObj.Status = "inperson_scheduled";
      this.resumeFeedbackObj.Schedule = this.selectedMoment;
      this.resumeFeedbackObj.isNotify = this.isNotifyCan;
      this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
      this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
      this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
      this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
      this.resumeFeedbackObj.Position = this.appDetail.position;//job position
      this.resumeFeedbackObj.DateAdded = this.applicationFeedback.dateAdded;
      this.resumeFeedbackObj.DateUpdated = this.applicationFeedback.dateUpdated;
      this.resumeFeedbackObj.ScheduleCount = 1;

      this.appDetail.resumeStatus = "inperson_scheduled"
      // this.appDetail.JobId = null;
      this.updateAppllicationFeedback(this.resumeFeedbackObj);
    } else if (data == "screening_accepted") {
      let isValid = this.validateText();
      if (isValid) {
        this.resumeFeedbackObj.Id = this.applicationFeedback.id;
        this.resumeFeedbackObj.UserId = this.appDetail.userId;
        this.resumeFeedbackObj.ResumeId = this.appDetail.id;
        this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
        this.resumeFeedbackObj.Status = "screening_accepted";
        this.resumeFeedbackObj.isNotify = false;
        this.resumeFeedbackObj.Schedule = this.selectedMoment;
        this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
        this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
        this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
        this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
        this.resumeFeedbackObj.DateAdded = this.applicationFeedback.dateAdded;
        this.resumeFeedbackObj.DateUpdated = this.applicationFeedback.dateUpdated;
        this.resumeFeedbackObj.Position = this.appDetail.position;//job position

        this.appDetail.resumeStatus = "screening_accepted"
        this.updateAppllicationFeedback(this.resumeFeedbackObj);
        this.calculateEvaluation("screening");
        // this.updateApplicationStatus(this.appDetail);
      } else {
        this.errorTextarea = "Please Enter Feedback Alteast 30 Characters.";
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "screening_rejected") {
      let isValid = this.validateText();
      if (isValid) {
        this.resumeFeedbackObj.Id = this.applicationFeedback.id;
        this.resumeFeedbackObj.UserId = this.appDetail.userId;
        this.resumeFeedbackObj.ResumeId = this.appDetail.id;
        this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
        this.resumeFeedbackObj.Status = "screening_rejected";
        this.resumeFeedbackObj.isNotify = this.isNotifyCan;;
        this.resumeFeedbackObj.Schedule = this.selectedMoment;
        this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
        this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
        this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
        this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
        this.resumeFeedbackObj.DateAdded = this.applicationFeedback.dateAdded;
        this.resumeFeedbackObj.DateUpdated = this.applicationFeedback.dateUpdated;
        this.resumeFeedbackObj.Position = this.appDetail.position;//job position

        this.appDetail.resumeStatus = "screening_rejected"
        this.updateAppllicationFeedback(this.resumeFeedbackObj);
        this.calculateEvaluation("screening");
        // this.updateApplicationStatus(this.appDetail);
      } else {
        this.errorTextarea = "Please Enter Feedback Alteast 30 Characters.";
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "update") {
      this.resumeFeedbackObj.Id = this.applicationFeedback.id;
      this.resumeFeedbackObj.UserId = this.appDetail.userId;
      this.resumeFeedbackObj.ResumeId = this.appDetail.id;
      this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
      this.resumeFeedbackObj.Status = "pending";
      this.resumeFeedbackObj.isNotify = this.isNotifyCan;
      this.resumeFeedbackObj.Schedule = this.selectedMoment;
      this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
      this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
      this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
      this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
      this.resumeFeedbackObj.DateAdded = this.applicationFeedback.dateAdded;
      this.resumeFeedbackObj.DateUpdated = this.applicationFeedback.dateUpdated;
      this.resumeFeedbackObj.Position = this.appDetail.position;//job position
      this.resumeFeedbackObj.ScheduleCount = 2;

      this.updateAppllicationFeedback(this.resumeFeedbackObj);
    } else if (data == "inperson_accepted") {
      let isValid = this.validateText();
      if (isValid) {
        this.resumeFeedbackObj.Id = this.applicationFeedback.id;
        this.resumeFeedbackObj.UserId = this.appDetail.userId;
        this.resumeFeedbackObj.ResumeId = this.appDetail.id;
        this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
        this.resumeFeedbackObj.Status = "inperson_accepted";
        this.resumeFeedbackObj.isNotify = false;
        this.resumeFeedbackObj.Schedule = this.selectedMoment;
        this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
        this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
        this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
        this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
        this.resumeFeedbackObj.DateAdded = this.applicationFeedback.dateAdded;
        this.resumeFeedbackObj.DateUpdated = this.applicationFeedback.dateUpdated;
        this.resumeFeedbackObj.Position = this.appDetail.position;//job position

        this.appDetail.resumeStatus = "inperson_accepted"
        this.updateAppllicationFeedback(this.resumeFeedbackObj);
        this.calculateEvaluation("inperson");
        // this.updateApplicationStatus(this.appDetail);
      } else {
        this.errorTextarea = "Please Enter Feedback Alteast 30 Characters.";
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "inperson_rejected") {
      let isValid = this.validateText();
      if (isValid) {
        this.resumeFeedbackObj.Id = this.applicationFeedback.id;
        this.resumeFeedbackObj.UserId = this.appDetail.userId;
        this.resumeFeedbackObj.ResumeId = this.appDetail.id;
        this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
        this.resumeFeedbackObj.Status = "inperson_rejected";
        this.resumeFeedbackObj.isNotify = this.isNotifyCan;;
        this.resumeFeedbackObj.Schedule = this.selectedMoment;
        this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
        this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
        this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
        this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
        this.resumeFeedbackObj.DateAdded = this.applicationFeedback.dateAdded;
        this.resumeFeedbackObj.DateUpdated = this.applicationFeedback.dateUpdated;
        this.resumeFeedbackObj.Position = this.appDetail.position;//job position

        this.appDetail.resumeStatus = "inperson_rejected"
        this.updateAppllicationFeedback(this.resumeFeedbackObj);
        this.calculateEvaluation("inperson");
        // this.updateApplicationStatus(this.appDetail);
      } else {
        this.errorTextarea = "Please Enter Feedback Alteast 30 Characters.";
        $(".assign-button").prop('disabled', false);
      }
    } else if (data == "inperson_update") {
      this.resumeFeedbackObj.Id = this.applicationFeedback.id;
      this.resumeFeedbackObj.UserId = this.appDetail.userId;
      this.resumeFeedbackObj.ResumeId = this.appDetail.id;
      this.resumeFeedbackObj.InterviewFeedback = this.screeningFeedback;
      this.resumeFeedbackObj.Status = "inperson_scheduled";
      this.resumeFeedbackObj.isNotify = this.isNotifyCan;
      this.resumeFeedbackObj.Schedule = this.selectedMoment;
      this.resumeFeedbackObj.CandidateName = this.appDetail.name;//Candidate name
      this.resumeFeedbackObj.CandidateEmail = this.appDetail.email;//Candidate email
      this.resumeFeedbackObj.InterviewerName = this.interviewerDetail.firstName;//Assigned To name
      this.resumeFeedbackObj.InterviewerEmail = this.interviewerDetail.email;//Assigned To email
      this.resumeFeedbackObj.DateAdded = this.applicationFeedback.dateAdded;
      this.resumeFeedbackObj.DateUpdated = this.applicationFeedback.dateUpdated;
      this.resumeFeedbackObj.Position = this.appDetail.position;//job position
      this.resumeFeedbackObj.ScheduleCount = 2;

      this.updateAppllicationFeedback(this.resumeFeedbackObj);
    } else if (data == "cancel_schedule") {
      $("#scheduledCancelModal").modal("hide");
      this.deleteAppllicationFeedback();
    }
  }

  updateNoFurtherAction(resumeUpdate) {
    this.jobApplicationsService.noFurtherAction(resumeUpdate).subscribe(response => {
      $(".assign-button").prop('disabled', false);
      if (response.status == "ok") {
        this.documents.length = 0;
        this.submitNotes = "";
        $(".loader-container").css("display", "table");
        $(".loader-response").css("display", "none");
        this.appDetail = "";
        this.candidateName = "";
        this.candidateEmail = "";
        this.candidatePosition = "";
        this.candidatePhoneNbr = "";
        this.assignmentNotes = "";
        this.interviewerId = null;
        this.appDetail = response.data;
        this.applicationId = this.appDetail.id;
        this.applicationprocess();
      } else if (response.status == "error") {
        this.noti.notification("Error", "Error Resume Status Updating", "danger");
        $(".loader-container").css("display", "none");
        $(".loader-response").css("display", "block");
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  updateApplicationStatus(resumeUpdate) {
    this.jobApplicationsService.updateApplication(resumeUpdate).subscribe(response => {
      if (response.status == "ok") {
        this.noti.notification("Success", "Resume Status Updated Successfully", "success");
        if (this.loginUser.accessLevel == 'super_admin') {
          this.getApplication();
          $(".assign-button").prop('disabled', false);
        } else {
          $("#evaluateCandidateModal").modal("hide");
          this.router.navigateByUrl('/job-applications');
        }
      } else {
        this.noti.notification("Error", "Error Resume Status Updating", "danger");
        $(".assign-button").prop('disabled', false);
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  addAppllicationFeedback(FeedbackObj) {
    this.jobApplicationsService.addFeedback(FeedbackObj).subscribe(response => {
      if (response.status == "ok") {
        if (response.data.status == "pending") {
          this.appDetail.resumeStatus = "screening_scheduled"
          this.updateApplicationStatus(this.appDetail);
        } else {
          this.noti.notification("Success", "Schedule Create Successfully", "success");
          this.router.navigateByUrl('/job-applications');
        }
      } else {
        this.noti.notification("Error", "Error Schedule Creating", "danger");
        $(".assign-button").prop('disabled', false);
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  updateAppllicationFeedback(FeedbackObj) {
    this.jobApplicationsService.updateFeedback(FeedbackObj).subscribe(response => {
      if (response.status == "ok") {
        if (response.data.status == "screening_accepted" || response.data.status == "screening_rejected" || response.data.status == "inperson_scheduled" || response.data.status == "inperson_rejected" || response.data.status == "inperson_accepted") {
          this.updateApplicationStatus(this.appDetail);
        } else {
          this.noti.notification("Success", "Schedule Updated Successfully", "success");
          this.router.navigateByUrl('/job-applications');
        }
      } else {
        this.noti.notification("Error", "Error Schedule Updated", "danger");
        $(".assign-button").prop('disabled', false);
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  deleteAppllicationFeedback() {
    this.jobApplicationsService.deleteFeedback(this.applicationId).subscribe(response => {
      if (response.status == "ok") {
        // this.noti.notification("Success", "Schedule Cancelled Successfully", "success");
        this.assignConfirm("pending");
      } else {
        this.noti.notification("Error", "Error Schedule Cancel", "danger");
        $(".assign-button").prop('disabled', false);
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  // ignoreConfirm() {
  //   $("#ignoreModal").modal("show");
  // }

  feedbackChange($event) {
    if (this.screeningFeedback.length > 30) {
      this.errorTextarea = ""
    } else {
      // this.errorTextarea = "Please Enter Feedback Alteast 30 Characters."
    }
  }

  validate() {
    // if (!(!!this.interviewerId)) {
    //   return false;
    // }
    if (!(!!this.candidateName)) {
      return false;
    }
    if (!!this.candidateEmail) {
      this.candidateEmail = this.candidateEmail.replace(/\s/g, '');
      if (!(/^([a-zA-Z0-9_\.\-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/.test(this.candidateEmail))) {
        this.candidateEmail = "";
        return false;
      }
    }
    if (!!this.candidatePhoneNbr) {
      this.candidatePhoneNbr = this.candidatePhoneNbr.replace(/\s/g, '');
      if (!(/^((\+92)|(0092)|(92))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(this.candidatePhoneNbr))) {
        this.candidatePhoneNbr = "";
        return false;
      }
    }
    // if (!(!!this.candidatePosition)) {
    //   return false;
    // }
    return true;
  }

  validateText() {
    if (!!this.screeningFeedback) {
      if (this.screeningFeedback.length > 30) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  confirmScheduledCancel() {
    $("#scheduledCancelModal").modal("show");
  }

  showEvaluateModal() {
    this.errorTextarea = "";
    $("#evaluateCandidateModal").modal("show");
  }

  calculateEvaluation(type) {
    var enthusiasm = parseInt($('input[name=enthusiasm]:checked').val());
    var workExp = parseInt($('input[name=workExp]:checked').val());
    var edu = parseInt($('input[name=edu]:checked').val());
    var skills = parseInt($('input[name=skills]:checked').val());
    var attitude = parseInt($('input[name=attitude]:checked').val());
    var apperanc = parseInt($('input[name=apperanc]:checked').val());
    var totalEvaluation = ((enthusiasm + workExp + edu + skills + attitude + apperanc) / 600) * 100;

    totalEvaluation = parseFloat(totalEvaluation.toFixed(2));

    // if (totalEvaluation <= 25) {
    //   if (this.isScreening) {
    //     // this.updateFeedback('screening_rejected');
    //   }
    //   if (this.isInOffice) {
    //     // this.updateFeedback('inperson_rejected');
    //   }
    // } else {
    //   if (this.isScreening) {
    //     // this.updateFeedback('screening_accepted')
    //   }
    //   if (this.isInOffice) {
    //     // this.updateFeedback('inperson_accepted');
    //   }
    // }

    this.resumeEvaluation.UserId = this.appDetail.userId;
    this.resumeEvaluation.ResumeId = this.appDetail.id;
    this.resumeEvaluation.Enthusiasm = enthusiasm;
    this.resumeEvaluation.WorkExperience = workExp;
    this.resumeEvaluation.Education = edu;
    this.resumeEvaluation.RequiredSkills = skills;
    this.resumeEvaluation.Attitude = attitude;
    this.resumeEvaluation.Appearance = apperanc;
    this.resumeEvaluation.TotalEvaluation = totalEvaluation;
    this.resumeEvaluation.Type = type;

    this.jobApplicationsService.postResumeEvaluation(this.resumeEvaluation).subscribe(response => {

    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  getResumeEvaluationRating(id, type) {
    this.jobApplicationsService.getResumeEvaluation(id, type).subscribe(response => {
      if (response.status == "ok") {
        if (response.data.type == "screening") {
          this.screenRating = response.data;
          this.isScreenRating = true;
        } else if (response.data.type == "inperson") {
          this.inpersonRating = response.data;
          this.isPersonRating = true;
        }
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }

  getResumeParseData(id) {
    this.parseData = "";
    this.jobApplicationsService.getResumeParseData(id).subscribe(response => {
      if (response.status == "ok") {
        if (response.data.skills != "" && response.data.skills != null) {
          response.data.skills = JSON.parse(response.data.skills);
          response.data.skills = response.data.skills.filter(function (x) { return x !== "" });
        }
        this.parseData = response.data;
      }
    }, error => {
      if (error.status == 401) {
        this.appService.signOut();
      }
    });
  }
}
