import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class CodeCommitService {
    private baseApiUrl = GlobalVariable.BASE_API_URL;
    constructor(
        private http: Http,
        private authService: AuthenticateService
    ) {
    }
    getRepositories() {
        let request = this.baseApiUrl + 'api/baltoro/getrepositorylist';
        return this.http.get(request).pipe(
            map(
                response => {
                    return response.json()
                }
            ),
            catchError(err => {
                return "error";
            }
            )
        );
    }
    getRepositoriesnewLog() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + `api/codecommit/GetLog`;
        return this.http.get(request, options).pipe(
            map(
                response => {
                    return response.json()
                }
            ),
            catchError(err => {
                return "error";
            }
            )
        );
    }


    getRepositoriesDetail() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + `api/codecommit`;
        return this.http.get(request, options).pipe(
            map(
                response => {
                    return response.json()
                }
            ),
            catchError(err => {
                return "error";
            }
            )
        );
    }

    getLastModified() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + `api/codecommit/getuserslastpush`;
        return this.http.get(request, options).pipe(
            map(
                response => {
                    return response.json()
                }
            ),
            catchError(err => {
                return "error";
            }
            )
        );
    }

    getRepositoryLogs(pageNumber, pageSize, sortBy, sortOrder, search, id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + `api/codecommit/codecommitlogs?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}&repositoryId=${id}`;
        return this.http.get(request, options).pipe(
            map(
                response => {
                    return response.json()
                }
            ),
            catchError(err => {
                return "error";
            }
            )
        );
    }
}
