import { NgbDateStruct, NgbCalendar, NgbDate, NgbInputDatepicker, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NotificationService } from 'src/services/notification.service';
import { AuthenticateService } from 'src/services/authenticate.service';
import { ProjectService } from 'src/services/projects.service';
import { NotesService } from 'src/services/notes.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/services/user.service';
import { GlobalVariable } from "src/app/globals";
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
declare var $;
import { Constants } from 'src/services/constants.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  public loginUser: any = '';
  public userList = [];
  public noteList = [];
  public allnotes=[];
  public noteId: number = 0;
  public noteFileId: number = 0;
  public noteDetail: any = '';
  public settings = {};
  public isAdded: boolean = true;
  public commentList = [];
  public isRequested: boolean = true;
  public year: any = moment().format('YYYY');
  //project select settings
  public projectList: any = [];
  public proSettings = {};
  public proItemList = [];
  public proSelectedItems = [];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  // note Obj
  public planeText: string = "";
  public noteTitle: string = "";
  public itemList = [];
  public selectedItems = [];
  public noteDuration: any = null;
  public placeholder = "Write description here...";
  public selectedDate: any;
  public selectedDatepicker: NgbDateStruct;
  public editorModel: any;
  @ViewChild('editor') editor;
  public noteFiles = [];

  // comment note obj
  public commentId: number = 0;
  public comment = "";

  //paging Obj
  public pageNumber = 1;
  public pageSize = 1000;
  public sortBy = "DateAdded";
  public sortOrder = "desc";
  public search = "";
  public totalRecords = 0;

  //attachments obj
  private baseUrl = GlobalVariable.BASE_API_URL;
  public URL: string = this.baseUrl + "api/FileUpload/notefileupload?id=";
  public localAttachments = [];
  public files: FileList;
  public formData: FormData = new FormData();


  constructor(
    private http: Http,
    private calendar: NgbCalendar,
    private appService: AppService,
    private userService: UserService,
    private notesService: NotesService,
    private projectService: ProjectService,
    private authService: AuthenticateService,
    private noti: NotificationService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Meeting Notes"
    });

    this.loginUser = JSON.parse(localStorage.getItem('user'));
    //this.getAllNotes();
    this.getAdminlevelUsers();
    this.getProject();
    this.getWebsites();
    this.settings = {
      singleSelection: false,
      text: "Select Participants",
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      classes: "myclass participants-note",
      enableSearchFilter: true,
      addNewItemOnFilter: false
    };

    //project setting
    this.proSettings = {
      singleSelection: true,
      text: "Select Project",
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      classes: "myclass participants-note",
      enableSearchFilter: true,
      addNewItemOnFilter: false
    };

    $('.dropdown .dropdown-item').on("click", function (e) {
      $(this).next('ul').toggle();
      e.stopPropagation();
      e.preventDefault();
    });
    $("#commentAddBtn").slideUp();
    $('#commenttextarea1').bind('input propertychange', function () {
      if (this.value.length > 0) {
        $("#commentAddBtn").slideDown();
      } else {
        $("#commentAddBtn").slideUp();
      }
      var el = this;
      setTimeout(function () {
        el.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';         
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
      }, 0);
    });

    $("#spinnerLoading").fadeOut();
    $(".loading-request-spin").hide();
    window.onscroll = () => {
      let windowHeight = "innerHeight" in window ? window.innerHeight
        : document.documentElement.offsetHeight;
      let body = document.body, html = document.documentElement;
      let docHeight = Math.max(body.scrollHeight,
        body.offsetHeight, html.clientHeight,
        html.scrollHeight, html.offsetHeight);
      let windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
        if (!this.isRequested && this.totalRecords > this.pageSize * this.pageNumber) {
          $("#spinnerLoading").fadeIn();
          this.isRequested = true;
          this.pageNumber++
          //this.getAllNotes();
        }
      }
    };

    $('select#selectDuration').on('change', function (e) {
      var optionSelected = $("option:selected", this);
      var valueSelected = this.value;
      if (valueSelected == 'custom') {
        $("#customDurationInput").slideDown();
      } else {
        $("#customDurationInput").slideUp();
      }
    });

    $('#addNoteModal').modal({
      backdrop: 'static',
      keyboard: false,
      show: false
    });
  }

 
 
  onItemSelect(item: any) { }

  OnItemDeSelect(item: any) { }

  onItemSelectPro(item: any) { }

  OnItemDeSelectPro(item: any) { }

  onContentChanged($event) {
    this.planeText = $event.text
    if (this.editorModel) {
      if (this.editorModel.length > 10000) {
        this.noti.notification("Error", "Content should be less than 4000 character.", "danger");
        $event.editor.deleteText(4000, $event.editor.getLength());
      }
    }

  }

  getWebsites() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,  // Number of records per page
      dom: 'frtlip',
      order: [[3, 'desc']], // Sorting by the fourth column (Date)
      destroy: true,
      columnDefs: [
        { width: "40%", targets: 0 },  // Title column width
        { width: "15%", targets: 1 },  // Participants column width
        { width: "15%", targets: 2 },  // Duration column width
        { width: "15%", targets: 3, type: 'date' },  // Date sorting on the 4th column
        { width: "15%", targets: 4 }   // Action column width
      ]
    };
   
    this.notesService.getNotes(this.pageNumber, this.pageSize, this.sortBy, this.sortOrder, this.search).subscribe(response => {
        if (response.status === "ok" && response.data.value.length > 0) {
          $(".loader-container").css("display", "none");
            this.totalRecords = response.data.value[0].totalRecords;

            this.noteList = [];  // Reset the note list before pushing new data
            response.data.value.forEach(value => {
                value.participants = JSON.parse(value.participants);
                this.noteList.push(value);
            });
            $("#spinnerLoading").fadeOut();
            this.isRequested = false;
            $(".custom-loader-note").css("display", "none");
            $(".loader-response-note").css("display", "block");
        } else {
            $("#spinnerLoading").fadeOut();
            this.isRequested = false;
            $(".custom-loader-note").css("display", "none");
            $(".loader-response-note").css("display", "block");
        }
        this.rerender();  // Re-render the table after updating the data
    }, error => {
        if (error.status === 401) {
            // Handle unauthorized error
        }
    });
  }
  
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  getAdminlevelUsers() {
    if (Constants.adminLevelUsers.length == 0) {
      this.userService.getAdminlevel().subscribe(response => {
        if (response.status == "ok") {
          this.userList = response.data.value;
          for (var i = 0; i < this.userList.length; i++) {
            if (this.userList[i].isActive){
            this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto })
          }
        }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.userList = Constants.adminLevelUsers;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].isActive){
        this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto })
      }
    }
    }
  }

  getProject() {
    if (Constants.projectList.length == 0) {
      this.projectService.getProjectList().subscribe(response => {
        if (response.status == "ok") {
          if (response.data.value.length > 0) {
            Constants.projectList = this.projectList = response.data.value;
            for (var i = 0; i < this.projectList.length; i++) {
              this.proItemList.push({ "id": this.projectList[i].id, "itemName": this.projectList[i].projectName, "projectLogo": this.projectList[i].projectLogo })
            }
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.projectList = Constants.projectList;
      for (var i = 0; i < this.projectList.length; i++) {
        this.proItemList.push({ "id": this.projectList[i].id, "itemName": this.projectList[i].projectName, "projectLogo": this.projectList[i].projectLogo })
      }
    }

  }

  // getAllNotes() {
  //   this.notesService.getNotes(this.pageNumber, this.pageSize, this.sortBy, this.sortOrder, this.search).subscribe(response => {
  //     if (response.status == "ok" && response.data.value.length > 0) {
  //       this.totalRecords = response.data.value[0].totalRecords;
  //       $("#spinnerLoading").fadeOut();
  //       let that = this;
  //       response.data.value.forEach(function (value) {
  //         let data = value;
  //         data.participants = JSON.parse(data.participants);
  //         that.noteList.push(value);
  //       });
  //       this.isRequested = false;
  //       $(".custom-loader-note").css("display", "none");
  //       $(".loader-response-note").css("display", "block");
  //     } else {
  //       $("#spinnerLoading").fadeOut();
  //       this.isRequested = false;
  //       $(".custom-loader-note").css("display", "none");
  //       $(".loader-response-note").css("display", "block");
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });

  // }

  showAddModel() {
    this.isAdded = true;
    this.selectedDatepicker = this.calendar.getToday();
    this.noteTitle = "";
    this.planeText = "";
    this.editorModel = "";
    this.selectedItems = []
    this.proSelectedItems = []
    this.noteDuration = null;
    this.noteFiles = [];
    this.localAttachments = [];
    this.formData.delete('Files');
    $("#customDurationInput").slideUp();
    $("#customDuration").val(0);
    $("#addNoteModal").modal("show");
    setTimeout(() => {
      $(".loading-request-spin").hide();
      $(".submit-note-btn").prop('disabled', false);
    }, 280);
  }

  onDateSelectModel(event) {
    this.selectedDate = moment().year(event.year).month(event.month - 1).date(event.day).format();
  }

  addNote() {
    this.noteTitle = this.noteTitle.trim();
    this.planeText = this.planeText.trim();
    if (this.noteTitle.length < 4) {
      this.noti.notification("Error", "Please enter title atleast 3 characters", "danger");
      return;
    }

    if (this.proSelectedItems.length == 0) {
      this.noti.notification("Error", "Please Select Project", "danger");
      return;
    }

    $(".submit-note-btn").prop('disabled', true);
    $(".loading-request-spin").show();
    $(".loading-request-text").hide();

    // if (this.noteTitle.trim().length > 3) {
    this.selectedDate = moment().year(this.selectedDatepicker.year).month(this.selectedDatepicker.month - 1).date(this.selectedDatepicker.day).format();
    var note = {
      CreatedBy: 0,
      Title: "",
      PlaneText: "",
      HtmlText: "",
      Participants: "",
      ProjectId: null,
      Duration: 0,
      DurationType: "",
      SelectedDate: new Date(this.selectedDate)
    };
    note.CreatedBy = this.loginUser.userId;
    note.Title = this.noteTitle;
    note.PlaneText = this.planeText;
    note.HtmlText = this.editorModel;
    if (this.noteDuration == 'custom') {
      note.Duration = $("#customDuration").val();
      note.DurationType = this.noteDuration;
    } else {
      note.Duration = this.noteDuration;
    }

    note.Participants = JSON.stringify(this.selectedItems);
    note.ProjectId = this.proSelectedItems[0].id;

    var detail = JSON.stringify(note);
    this.formData.append('Param', detail);
    let options = this.authService.formDataHeaderAuth();
    this.http.post(this.baseUrl + "api/Notes", this.formData, options)
      .subscribe(response => {
        let res = response.json();
        if (res.status == "ok") {
          let data = res.data;
          if (this.proSelectedItems.length != 0) {
            data.projectId = this.proSelectedItems[0].id;
            data.projectName = this.proSelectedItems[0].itemName;
          }
          data.participants = JSON.parse(data.participants);
          data.totalRecords = this.noteList.length > 0 ? this.noteList[0].totalRecords : 1;
          this.noteList.push(data);
          this.noteList.sort(function (a, b) {
            if (a.dateAdded > b.dateAdded) { return -1; }
            if (a.dateAdded < b.dateAdded) { return 1; }
            return 0;
          });
          this.formData.delete('Files');
          this.formData.delete('Param');
          $(".loading-request-spin").hide();
          $(".loading-request-text").show();
          $(".submit-note-btn").prop('disabled', false);
          this.noti.notification("Success", "Note Added Successfully", "success");

          $("#addNoteModal").modal("hide");
        } else {
          if (res.data == "File uploading error") {
            this.noti.notification("Success", "Please check your internet connection", "danger");
          } else {
            this.noti.notification("Error", "Error Adding Note ", "danger");
          }
          this.formData.delete('Param');
          $(".loading-request-spin").hide();
          $(".loading-request-text").show();
          $(".submit-note-btn").prop('disabled', false);
        }

      }, (err: HttpErrorResponse) => {
        this.formData.delete('Param');
        $(".loading-request-spin").hide();
        $(".loading-request-text").show();
        $(".submit-note-btn").prop('disabled', false);
        this.noti.notification("Error", "Error Adding Note ", "danger");
      });
  }

  showEditNoteModal(event, data) {
    event.stopPropagation();
    this.localAttachments = [];
    this.formData.delete('Files');
    this.noteDetail = "";
    $("#customDurationInput").slideUp();
    this.isAdded = false;
    this.noteDetail = data;
    this.selectedItems = [];
    this.proSelectedItems = [];
    this.noteTitle = this.noteDetail.title;
    this.editorModel = this.noteDetail.htmlText;
    // this.noteDuration = this.noteDetail.duration;
    let yearT: number = moment(this.noteDetail.selectedDate).get('year');
    let monthT: number = moment(this.noteDetail.selectedDate).get('month') + 1;
    let dayT: number = moment(this.noteDetail.selectedDate).get('date');
    this.selectedDatepicker = { year: yearT, month: monthT, day: dayT };
    if (this.noteDetail.durationType == 'custom') {
      this.noteDuration = 'custom';
      $("#customDurationInput").slideDown();
      $("#customDuration").val(this.noteDetail.duration);
    } else {
      this.noteDuration = this.noteDetail.duration;
    }
    // let participants = this.noteDetail.participants;
    for (var i = 0; i < this.noteDetail.participants.length; i++) {
      this.selectedItems.push({ "id": this.noteDetail.participants[i].id, "itemName": this.noteDetail.participants[i].itemName, "profilePhoto": this.noteDetail.participants[i].profilePhoto })
    }
    for (var i = 0; i < this.projectList.length; i++) {
      if (this.projectList[i].id == this.noteDetail.projectId) {
        this.proSelectedItems.push({ "id": this.projectList[i].id, "itemName": this.projectList[i].projectName, "projectLogo": this.projectList[i].projectLogo })
      }
    }
    this.getFiles(data.id);
    $("#addNoteModal").modal("show");
    setTimeout(() => {
      $(".loading-request-spin").hide();
      $(".submit-note-btn").prop('disabled', false);
    }, 280);
  }

  editNote() {
    $(".submit-note-btn").prop('disabled', true);
    $(".loading-request-spin").show();
    $(".loading-request-text").hide();
    if (this.noteTitle.trim().length > 3) {
      this.selectedDate = moment().year(this.selectedDatepicker.year).month(this.selectedDatepicker.month - 1).date(this.selectedDatepicker.day).format();
      var note = {
        Id: 0,
        CreatedBy: 0,
        Title: "",
        PlaneText: "",
        HtmlText: "",
        Participants: "",
        ProjectId: null,
        Duration: 0,
        DurationType: "",
        SelectedDate: new Date(this.selectedDate)
      };
      note.Id = this.noteDetail.id;
      note.Title = this.noteTitle;
      note.PlaneText = this.planeText;
      note.HtmlText = this.editorModel;
      note.CreatedBy = this.loginUser.userId;
      if (this.noteDuration == 'custom') {
        note.Duration = $("#customDuration").val();
        note.DurationType = this.noteDuration;
      } else {
        note.Duration = this.noteDuration;
      }
      note.Participants = JSON.stringify(this.selectedItems);
      note.ProjectId = this.proSelectedItems[0].id;

      var detail = JSON.stringify(note);
      this.formData.append('Param', detail);

      let options = this.authService.formDataHeaderAuth();
      this.http.put(this.baseUrl + "api/Notes", this.formData, options)
        .subscribe(response => {
          let res = response.json();
          if (res.status == "ok") {
            for (var i = 0; i < this.noteList.length; i++) {
              if (this.noteList[i].id == res.data.id) {
                let data = res.data;
                if (this.proSelectedItems.length != 0) {
                  data.projectId = this.proSelectedItems[0].id;
                  data.projectName = this.proSelectedItems[0].itemName;
                }
                data.participants = JSON.parse(data.participants);
                data.totalRecords = this.noteList[i].totalRecords;
                this.noteList[i] = data;
                break;
              }
            }
            this.formData.delete('Param');
            this.formData.delete('Files');
            $(".loading-request-text").show();
            $(".loading-request-spin").hide();
            $(".submit-note-btn").prop('disabled', false);
            $("#addNoteModal").modal("hide");
            this.noti.notification("Success", "Note Updated Successfully", "success");
          } else {
            this.noti.notification("Error", "Error Updating Note ", "danger");
            this.formData.delete('Param');
            $(".loading-request-spin").hide();
            $(".loading-request-text").show();
            $(".submit-note-btn").prop('disabled', false);
          }

        }, (err: HttpErrorResponse) => {
          this.formData.delete('Param');
          $(".loading-request-spin").hide();
          $(".loading-request-text").show();
          $(".submit-note-btn").prop('disabled', false);
          this.noti.notification("Error", "Error Updating Note ", "danger");
        });
    } else {
      $(".submit-note-btn").prop('disabled', false);
      $(".loading-request-spin").hide();
      $(".loading-request-text").show();
      this.noti.notification("Error", "Please enter title atleast 3 characters", "danger");
    }
  }

  showDeleteNoteModal(event, id) {
    event.stopPropagation();
    this.noteId = id;
    $("#deleteNoteModal").modal("show");
  }

  deleteNote() {
    this.notesService.deleteNote(this.noteId).subscribe(response => {
      if (response.status == "ok") {
        for (var i = 0; i < this.noteList.length; i++) {
          if (this.noteList[i].id == this.noteId) {
            this.noteList.splice(i, 1);
          }
        }
        this.noti.notification("Success", "Note Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Error Deleting Note", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#deleteNoteModal").modal("hide");
  }

  // showCommentModel(event, data) {
  //   if ($(event.target).prop("class") !== "la la-ellipsis-v") {
  //     const newUrl = `/comment-details/${data.id}`;
  //     window.open(newUrl, '_blank');
  //   }
  // }

  // showCommentModel(event, data) {
  //   if ($(event.target).prop("class") !== "la la-ellipsis-v") {
  //     this.router.navigate(['/notes/notedetails' , data.id]);
  //   }
  // }
  showCommentModel(event, data) {
    if ($(event.target).prop("class") == "la la-ellipsis-v") {
    } else {
      this.noteDetail = data;
      this.comment = "";
      this.getFiles(data.id);
      this.getAllComments(this.noteDetail.id);
      $("#noteCommentsModal").modal("show");
    }
  }

  getAllComments(id) {
    this.notesService.getComments(id).subscribe(response => {
      if (response.status == "ok") {
        this.commentList = response.data;
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  addComment() {
    if (this.comment.trim().length > 1) {
      var CommentObj = {
        NoteId: 0,
        UserId: 0,
        Comment: ""
      };
      CommentObj.NoteId = this.noteDetail.id;
      CommentObj.UserId = this.loginUser.userId;
      CommentObj.Comment = this.comment;

      this.notesService.addComment(CommentObj).subscribe(response => {
        if (response.status == "ok") {
          this.comment = "";
          $("#commenttextarea1").css("height", "25px");
          $("#commentAddBtn").slideUp();
          this.commentList.push(response.data);
          this.commentList.sort(function (a, b) {
            if (a.dateAdded > b.dateAdded) { return -1; }
            if (a.dateAdded < b.dateAdded) { return 1; }
            return 0;
          });
          for (var i = 0; i < this.noteList.length; i++) {
            if (this.noteList[i].id == response.data.noteId) {
              if (this.noteList[i].commentsCount) {
                this.noteList[i].commentsCount += 1;
              } else {
                this.noteList[i].commentsCount = 1;
              }
              break;
            }
          }
          // this.noti.notification("Success", "Comment Added Successfully", "success");
        } else {
          this.noti.notification("Error", "Error Adding Comment ", "danger");
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.noti.notification("Error", "Please write comment atleast 2 letters", "danger");
    }

  }


  

  addFiles(event) {
    if (this.localAttachments.length < 5) {
      this.files = event.target.files;
      for (let i = 0; i < this.files.length; i++) {
        this.localAttachments.push({ 'name': this.files[i].name }); //= this.files[i].name;
        this.formData.append('Files', this.files[i], this.files[i].name);
      }
    } else {
      this.noti.notification("Error", "You Already Three Files Selected", "danger");
    }
  }

  

  getFiles(id) {
    this.noteFiles = [];
    this.notesService.getFiles(id).subscribe(response => {
      if (response.status == "ok") {
        this.noteFiles = response.data;
      } else {

      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  deleteAttachment() {
    this.notesService.deleteFiles(this.noteFileId).subscribe(response => {
      if (response.status == "ok") {
        for (var i = 0; i < this.noteFiles.length; i++) {
          if (this.noteFiles[i].id == this.noteFileId) {
            this.noteFiles.splice(i, 1);
            break;
          }
        }
        this.noti.notification("Success", "Attachment Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Error Deleting Attachment", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  deleteLocalAttachments(data) {
    var values = this.formData.getAll("Files");
    values.splice(data, 1);
    this.localAttachments.splice(data, 1);
    this.formData.delete('Files');
    values.filter(name => name !== 'Bob').forEach(name => this.formData.append('Files', name));
  }

  

  slideAlert(action, id) {
    if (action == 'file') {
      let prevNId = this.noteFileId;
      if (prevNId != id) {
        $("#box-file-popup-" + prevNId).animate({
          width: "hide"
        }, 500);
        setTimeout(() => {
          $('#box-file-btn-' + prevNId).fadeIn(1200);
        }, 500);
      }
      this.noteFileId = id;
      $('#box-file-btn-' + id).fadeOut(150);
      $("#box-file-popup-" + id).animate({
        width: "toggle"
      });
    } else if (action == 'comment') {
      let prevCId = this.commentId;
      if (prevCId != id) {
        $("#box-comment-popup-" + prevCId).animate({
          width: "hide"
        }, 500);
        setTimeout(() => {
          $('#box-comment-btn-' + prevCId).fadeIn(1200);
        }, 500);
      }
      this.commentId = id;
      $('#box-comment-btn-' + id).fadeOut(150);
      $("#box-comment-popup-" + id).animate({
        width: "toggle"
      });
    }

  }

  deleteCancel(action, id) {
    if (action == 'file') {
      $("#box-file-popup-" + id).animate({
        width: "toggle"
      }, 500);
      setTimeout(() => {
        $('#box-file-btn-' + id).fadeIn(1200);
      }, 500);
    } else if (action == 'comment') {
      $("#box-comment-popup-" + id).animate({
        width: "toggle"
      }, 500);
      setTimeout(() => {
        $('#box-comment-btn-' + id).fadeIn(1200);
      }, 500);
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
