import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class NotesService {
    private baseApiUrl = GlobalVariable.BASE_API_URL;
    constructor(
        private http: Http,
        private authService: AuthenticateService
    ) {
    }
    //note methods
    // addNote(asset) {
    //     let options = this.authService.setHeaderAuth();
    //     let request = this.baseApiUrl + 'api/Notes';
    //     return this.http.post(request, asset, options).pipe(map(
    //         response => {
    //             return response.json()
    //         }
    //     ),
    //         catchError(err => {
    //             return "error";
    //         }
    //         ));
    // }
    getNotes(pageNumber, pageSize, sortBy, sortOrder, search) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + `api/Notes?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}`;
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    
    getNote(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/' + id;
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
        ));
    }

    // updateNote(note) {
    //     let options = this.authService.setHeaderAuth();
    //     let request = this.baseApiUrl + 'api/Notes/' + note.Id;
    //     return this.http.put(request, note, options).pipe(map(
    //         response => {
    //             return response.json()
    //         }
    //     ),
    //         catchError(err => {
    //             return "error";
    //         }
    //         ));
    // }
    deleteNote(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/' + id;
        return this.http.delete(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    getFiles(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/files/' + id;
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
        ));
    }


    
    deleteFiles(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/files/' + id;
        return this.http.delete(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }
    //note comments methods
    addComment(comment) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/Comment';
        return this.http.post(request, comment, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    getuserInformations(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/users/check-user-status' + id;
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }
    
    getComments(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/Comment/' + id;
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    getComment(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/Comment/' + id;
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    updateComment(comment) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/Comment/' + comment.id;
        return this.http.put(request, comment, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    deleteComment(id) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/Notes/Comment/' + id;
        return this.http.delete(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }
}
