import { Component, OnInit, ViewChild } from '@angular/core';
import { TaskService } from 'src/services/task.service';
import { DashboardService } from 'src/services/dashboard.service';
import { UserService } from 'src/services/user.service';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-plugin-labels';

declare var google: any;

@Component({
  selector: 'app-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.css']
})
export class HistogramComponent implements OnInit {
  @ViewChild("baseChartDPro") baseChartDPro: BaseChartDirective;
  @ViewChild("baseChartDPeo") baseChartDPeo: BaseChartDirective;
  public startDateDaily = moment().format('MM/DD/YYYY');
  public endDateDaily = moment().format('MM/DD/YYYY');
  public selectedDateDaily: any;
  public projectsListDaily = [];
  public year: any = moment().format('YYYY');

  public chartOptionsDPro = {
    title: {
      display: true,
      text: 'Projects',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  public chartLabelsDPro = [];
  public chartTypeDPro = 'bar';
  public chartLegendDPro = true;
  public chartDataDPro = [
    { data: [], label: 'Hours' }
  ];
  public chartDProColors = [{ backgroundColor: [] }];

  public usersListDaily = [];
  public chartOptionsDPeo = {
    title: {
      display: true,
      text: 'People',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  public chartLabelsDPeo = [];
  public chartTypeDPeo = 'bar';
  public chartLegendDPeo = true;
  public chartDataDPeo = [
    { data: [], label: 'Actual Hours' }
  ];
  public chartDPeoColors = [{ backgroundColor: [] }];


  @ViewChild("baseChartWPro") baseChartWPro: BaseChartDirective;
  @ViewChild("baseChartWPeo") baseChartWPeo: BaseChartDirective;
  public startDateWeekly = moment().format('MM/DD/YYYY');
  public endDateWeekly = moment().format('MM/DD/YYYY');
  public selectedDateWeekly: any;
  public projectsListWeekly = [];
  public chartOptionsWPro = {
    title: {
      display: true,
      text: 'Projects',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  public chartLabelsWPro = [];
  public chartTypeWPro = 'bar';
  public chartLegendWPro = true;
  public chartDataWPro = [
    { data: [], label: 'Hours' }
  ];
  public chartWProColors = [{ backgroundColor: [] }];


  public usersListWeekly = [];
  public chartOptionsWPeo = {
    title: {
      display: true,
      text: 'People',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  public chartLabelsWPeo = [];
  public chartTypeWPeo = 'bar';
  public chartLegendWPeo = true;
  public chartDataWPeo = [
    { data: [], label: 'Hours' }
  ];
  public chartWPeoColors = [{ backgroundColor: [] }];


  @ViewChild("baseChartMPro") baseChartMPro: BaseChartDirective;
  @ViewChild("baseChartMPeo") baseChartMPeo: BaseChartDirective;
  public startDateMonthly = moment().format('MM/DD/YYYY');
  public endDateMonthly = moment().format('MM/DD/YYYY');
  public selectedDateMonthly: any;
  public projectsListMonthly = [];
  public chartOptionsMPro = {
    title: {
      display: true,
      text: 'Projects',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  public chartLabelsMPro = [];
  public chartTypeMPro = 'bar';
  public chartLegendMPro = true;
  public chartDataMPro = [
    { data: [], label: 'Hours' }
  ];
  public chartMProColors = [{ backgroundColor: [] }];


  public usersListMonthly = [];
  public chartOptionsMPeo = {
    title: {
      display: true,
      text: 'People',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  public chartLabelsMPeo = [];
  public chartTypeMPeo = 'bar';
  public chartLegendMPeo = true;
  public chartDataMPeo = [
    { data: [], label: 'Hours' }
  ];
  public chartMPeoColors = [{ backgroundColor: [] }];

  //projects pie chart properties
  public startDatePro = moment().format('MM/DD/YYYY');
  public endDatePro = moment().format('MM/DD/YYYY');
  public dateRangeProChart = '';
  @ViewChild("baseChartPro") baseChartPro: BaseChartDirective;
  public doughnutChartColors: any[] = [{ backgroundColor: ["#b8436d", "#00d9f9", "#a4c73c", "#a4add3", "#ffa1b5", "#ffe29a", "#d0b886", "#bfc5d0", "#fa9092", "#96bcb6", "#b8436d", "#00d9f9", "#a4c73c", "#a4add3", "#ffa1b5", "#ffe29a", "#d0b886", "#bfc5d0", "#fa9092", "#96bcb6"] }];

  //start custom chart pro
  @ViewChild("baseChartCustomPro") baseChartCustomPro: BaseChartDirective;
  public chartCustomProData = [
    { data: [], label: 'Hours' }
  ];
  public chartCustomProLabels = [];
  public chartCustomProLegend = true;
  public chartCustomProType = 'bar';
  public chartCustomProColors = [{ backgroundColor: [] }];
  public chartCustomProOptions = {
    title: {
      display: true,
      text: 'Projects',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  //end custom chart pro

  //start custom chart peo
  @ViewChild("baseChartCustomPeo") baseChartCustomPeo: BaseChartDirective;
  public chartCustomPeoData = [
    { data: [], label: 'Hours' }
  ];
  public chartCustomPeoLabels = [];
  public chartCustomPeoLegend = true;
  public chartCustomPeoType = 'bar';
  public chartCustomPeoColors = [{ backgroundColor: [] }];
  public chartCustomPeoOptions = {
    title: {
      display: true,
      text: 'Users',
      position: 'bottom'
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
        ticks: {
          maxRotation: 0,
          padding: 0
        }
      }],
      yAxes: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ticks: {
          beginAtZero: true
          // stepSize: 5
        }
      }]
    },
    plugins: {
      labels: {
        render: function (args) {
          return args.value;
        },
        arc: true
      }
    }
  };
  //end custom chart peo

  public topProjects = [];
  public topUsers = [];

  // calender chart  
  public dateLatePeople = moment().format('MM/DD/YYYY');
  public dateRangeLatePeopleChart = '';
  private dtDaily; // Chart Data Object
  private lstDailyData = []; // Processed Data Array
  private chartDaily;
  private chartOptionsDaily = {
    title: "",
    height: 350,
    calendar: { cellSize: 18 },
    // animation: {
    //   duration: 1000,
    //   easing: 'out',
    //   startup: true
    // },
    // title: '',
    // curveType: 'function',
    // width: "100%",
    // chartArea: { top: '15%', bottom: "65px", left: "12%", width: '83%' },
    // legend: {
    //   position: 'bottom',
    //   alignment: "end"
    // },
    // theme: 'material',
    colorAxis: { colors: ['#38f55f', '#079229'] },
    //colors: ['#09ae32', '#09ae32'],//['#c7cbd5', 'blue'],
    // vAxis: {
    //   ticks: [0, 3, 6, 9, 12],
    //   gridlines: {
    //     count: 5
    //   },
    //   viewWindowMode: "explicit",
    //   viewWindow: {
    //     min: 0
    //   }
    // }
  };


  //project base chart
  public startDateProjectW = moment().format('MM/DD/YYYY');
  public endDateProjectW = moment().format('MM/DD/YYYY');
  private lstProjectWData = []; // Processed Data Array
  private dtProjectW;
  private chartProjectW;
  private chartOptionsProjectW = {
    width: 600,
    height: 400,
    legend: { position: 'top', maxLines: 3 },
    bar: { groupWidth: '75%' },
    isStacked: true,
  };
  public selectedDateProjectW: any;

  public totalSumProject = 0;
  public totalSumUser = 0;

  constructor(
    private appService: AppService,
    private taskService: TaskService,
    private userService: UserService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Histogram"
    });
    this.changeDailyReport('yrs-day');
    this.changeDateWeekly('today');
    this.changeDateMonthly('today');
    this.getPeopleTaskList();
    this.getProjectsList();
    google.charts.load("current", { packages: ["calendar"] });
    google.charts.setOnLoadCallback(() => {
      this.changeLatePeopleDate('current');
    });
    // google.charts.load("current", {packages:['corechart']});
    // google.charts.setOnLoadCallback(() => {
    //   this.changeDateProjectW('today');
    // });   
  }

  // getUser() {
  //   this.userService.getAdminlevel().subscribe(response => {
  //     if (response != null) {
  //       if (response.status == "ok") {
  //         if (response.data != null) {
  //           if (response.data.value.length > 0) {
  //             this.userList = response.data.value;
  //             this.filterUserId = this.userList[0].userId;
  //             // this.filterTaskEstReport(this.filterUserId);
  //             this.userList.sort(function (a, b) {
  //               if (a.firstName < b.firstName) { return -1; }
  //               if (a.firstName > b.firstName) { return 1; }
  //               return 0;
  //             });
  //             // this.filterTaskEstReport(this.userList[0].userId);
  //           }
  //         }
  //       }
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });
  // }

  changeDailyReport(data?: any) {
    let todayDate = moment().format('MM/DD/YYYY');
    let requestCall = false;
    if (data == 'prev') {
      this.startDateDaily = moment(this.startDateDaily).subtract(1, 'day').format('MM/DD/YYYY');
      this.endDateDaily = this.startDateDaily;
      requestCall = true;
    } else if (data == 'next' && todayDate != this.endDateDaily) {
      this.startDateDaily = moment(this.startDateDaily).add(1, 'day').format('MM/DD/YYYY');
      this.endDateDaily = this.startDateDaily;
      requestCall = true;
    } else if (data == "yrs-day") {
      this.startDateDaily = moment().format('MM/DD/YYYY');//subtract(1, 'day')
      this.endDateDaily = this.startDateDaily;
      requestCall = true;
    }
    if (requestCall) {
      $(".custom-loader-d").css("display", "table");
      $(".loader-response-d").css("display", "none");
      this.selectedDateDaily = moment(this.startDateDaily).format('MM/DD/YYYY');
      this.getTasksByUsersDaily(this.startDateDaily, this.endDateDaily);
      this.getTasksByProjectsDaily(this.startDateDaily, this.endDateDaily);
    }

    if (this.endDateDaily == todayDate) {
      $("#nextBtnDaily").css("cursor", "not-allowed");
    } else {
      $("#nextBtnDaily").css("cursor", "pointer");
    }
  }

  getTasksByProjectsDaily(startDate, endDate) {
    this.dashboardService.getProjectsTimeline(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        this.chartLabelsDPro.length = 0;
        this.chartDataDPro[0].data.length = 0;
        this.chartDProColors[0].backgroundColor.length = 0;
        let data = res.data;
        this.projectsListDaily = data;
        for (var i = 0; i < this.projectsListDaily.length; i++) {
          this.chartLabelsDPro.push(this.projectsListDaily[i].projectName);
          this.chartDataDPro[0].data.push(this.projectsListDaily[i].hours.toPrecision(2));
          this.chartDProColors[0].backgroundColor.push("#63c2de");
        }
        this.baseChartDPro.chart.update();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  getTasksByUsersDaily(startDate, endDate) {
    this.dashboardService.getUsersTimeline(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        this.chartLabelsDPeo.length = 0;
        this.chartDataDPeo[0].data.length = 0;
        this.chartDPeoColors[0].backgroundColor.length = 0;
        this.usersListDaily = res.data;

        for (var i = 0; i < this.usersListDaily.length; i++) {
          this.chartLabelsDPeo.push(this.usersListDaily[i].userName);
          this.chartDataDPeo[0].data.push(this.usersListDaily[i].hours.toPrecision(2));
          this.chartDPeoColors[0].backgroundColor.push("#9ccc65");
        }
        this.baseChartDPeo.chart.update();
        $(".custom-loader-d").css("display", "none");
        $(".loader-response-d").css("display", "block");
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  changeDateWeekly(data?: any) {
    let todayDate = moment().endOf('week').add(1, 'day').format('MM/DD/YYYY');//.subtract(1, 'w')
    let requestCall = false;
    if (data == 'prev') {
      this.startDateWeekly = moment(this.startDateWeekly).subtract(1, 'w').format('MM/DD/YYYY');
      this.endDateWeekly = moment(this.startDateWeekly).add(1, 'w').subtract(1, 'day').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'next' && todayDate != this.endDateWeekly) {
      this.startDateWeekly = moment(this.startDateWeekly).add(1, 'w').format('MM/DD/YYYY');
      this.endDateWeekly = moment(this.startDateWeekly).add(1, 'w').subtract(1, 'day').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'today') {
      this.startDateWeekly = moment().startOf('week').add(1, 'day').format('MM/DD/YYYY');//.subtract(1, 'w')
      this.endDateWeekly = moment(this.startDateWeekly).endOf('week').add(1, 'day').format('MM/DD/YYYY');
      requestCall = true;
    }

    if (requestCall) {
      $(".custom-loader-w").css("display", "table");
      $(".loader-response-w").css("display", "none");
      this.selectedDateWeekly = moment(this.startDateWeekly).format('MM/DD/YYYY') + " - " + moment(this.endDateWeekly).format('MM/DD/YYYY');
      this.getTasksByProjectsWeekly(this.startDateWeekly, this.endDateWeekly);
      this.getTasksByUsersWeekly(this.startDateWeekly, this.endDateWeekly);
    }

    if (this.endDateWeekly == todayDate) {
      $("#nextBtnWeekly").css("cursor", "not-allowed");
    } else {
      $("#nextBtnWeekly").css("cursor", "pointer");
    }
  }

  getTasksByProjectsWeekly(startDate, endDate) {
    this.dashboardService.getProjectsReport(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        this.chartLabelsWPro.length = 0;
        this.chartDataWPro[0].data.length = 0;
        this.chartWProColors[0].backgroundColor.length = 0;
        let data = res.data;
        this.projectsListWeekly = data;
        for (var i = 0; i < this.projectsListWeekly.length; i++) {
          this.chartLabelsWPro.push(this.projectsListWeekly[i].projectName);
          this.chartDataWPro[0].data.push(this.projectsListWeekly[i].totalHours.toFixed(2));
          this.chartWProColors[0].backgroundColor.push("#63c2de");
        }
        this.baseChartWPro.chart.update();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  getTasksByUsersWeekly(startDate, endDate) {
    this.dashboardService.getUsersReport(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        this.chartLabelsWPeo.length = 0;
        this.chartDataWPeo[0].data.length = 0;
        this.chartWPeoColors[0].backgroundColor.length = 0;
        let data = res.data;
        this.usersListWeekly = data;
        for (var i = 0; i < this.usersListWeekly.length; i++) {
          this.chartLabelsWPeo.push(this.usersListWeekly[i].userName);
          this.chartDataWPeo[0].data.push(this.usersListWeekly[i].totalHours.toFixed(2));
          this.chartWPeoColors[0].backgroundColor.push("#9ccc65");
        }
        this.baseChartWPeo.chart.update();
        $(".custom-loader-w").css("display", "none");
        $(".loader-response-w").css("display", "block");
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  changeDateMonthly(data?: any) {
    let requestCall = false;
    let todayDate = moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
    if (data == 'prev') {
      this.startDateMonthly = moment(this.startDateMonthly).subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
      this.endDateMonthly = moment(this.startDateMonthly).endOf('month').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'next' && todayDate != this.endDateMonthly) {
      this.startDateMonthly = moment(this.startDateMonthly).add(1, 'months').startOf('month').format('MM/DD/YYYY');
      this.endDateMonthly = moment(this.startDateMonthly).endOf('month').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'today') {
      this.startDateMonthly = moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
      this.endDateMonthly = moment(this.startDateMonthly).endOf('month').format('MM/DD/YYYY');
      requestCall = true;
    }

    if (requestCall) {
      $(".custom-loader-m").css("display", "table");
      $(".loader-response-m").css("display", "none");
      this.selectedDateMonthly = moment(this.startDateMonthly).format('MMMM YYYY');
      this.getTasksByProjectsMonthly(this.startDateMonthly, this.endDateMonthly);
      this.getTasksByUsersMonthly(this.startDateMonthly, this.endDateMonthly);
    }

    if (this.endDateMonthly == todayDate) {
      $("#nextBtnMonthly").css("cursor", "not-allowed");
    } else {
      $("#nextBtnMonthly").css("cursor", "pointer");
    }
  }

  getTasksByProjectsMonthly(startDate, endDate) {
    this.dashboardService.getProjectsReport(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        this.chartLabelsMPro.length = 0;
        this.chartDataMPro[0].data.length = 0;
        this.chartMProColors[0].backgroundColor.length = 0;
        let data = res.data;
        this.projectsListMonthly = data;
        for (var i = 0; i < this.projectsListMonthly.length; i++) {
          this.chartLabelsMPro.push(this.projectsListMonthly[i].projectName);
          this.chartDataMPro[0].data.push(this.projectsListMonthly[i].totalHours.toFixed(2));
          this.chartMProColors[0].backgroundColor.push("#63c2de");
        }
        this.baseChartMPro.chart.update();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  getTasksByUsersMonthly(startDate, endDate) {
    this.dashboardService.getUsersReport(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        this.chartLabelsMPeo.length = 0;
        this.chartDataMPeo[0].data.length = 0;
        this.chartMPeoColors[0].backgroundColor.length = 0;
        let data = res.data;
        this.usersListMonthly = data;
        for (var i = 0; i < this.usersListMonthly.length; i++) {
          this.chartLabelsMPeo.push(this.usersListMonthly[i].userName);
          this.chartDataMPeo[0].data.push(this.usersListMonthly[i].totalHours.toFixed(2));
          this.chartMPeoColors[0].backgroundColor.push("#9ccc65");
        }
        this.baseChartMPeo.chart.update();
        $(".custom-loader-m").css("display", "none");
        $(".loader-response-m").css("display", "block");
      }
    });
  }

  getProjectsList() {
    this.dashboardService.getTopProjects().subscribe(res => {
      if (res.status == "ok") {
        this.chartCustomProLabels.length = 0;
        this.chartCustomProData[0].data.length = 0;
        this.chartCustomProColors[0].backgroundColor.length = 0;
        this.topProjects = res.data;
        for (var i = 0; i < this.topProjects.length; i++) {
          this.totalSumProject += this.topProjects[i].hours;
          this.chartCustomProLabels.push(this.topProjects[i].projectName);
          this.chartCustomProData[0].data.push(this.topProjects[i].hours.toFixed(2));
          this.chartCustomProColors[0].backgroundColor.push("#63c2de");
        }
        $(".custom-loader-custom-pro").css("display", "none");
        $(".loader-response-custom-pro").css("display", "block");
        this.baseChartCustomPro.chart.update();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  getPeopleTaskList() {
    this.dashboardService.getTopUsers().subscribe(res => {
      if (res.status == "ok") {
        this.chartCustomPeoLabels.length = 0;
        this.chartCustomPeoData[0].data.length = 0;
        this.chartCustomPeoColors[0].backgroundColor.length = 0;
        this.topUsers = res.data;
        for (var i = 0; i < this.topUsers.length; i++) {
          this.totalSumUser += this.topUsers[i].hours;
          this.chartCustomPeoLabels.push(this.topUsers[i].userName);
          this.chartCustomPeoData[0].data.push(this.topUsers[i].hours.toFixed(2));
          this.chartCustomPeoColors[0].backgroundColor.push("#9ccc65");
        }
        $(".custom-loader-custom-peo").css("display", "none");
        $(".loader-response-custom-peo").css("display", "block");
        this.baseChartCustomPeo.chart.update();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  changeLatePeopleDate(data?: any) {
    let todayDate = moment().format('MM/DD/YYYY');
    let requestCall = false;
    if (data == 'prev') {
      this.dateLatePeople = moment(this.dateLatePeople).subtract(1, 'years').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'next' && todayDate != this.dateLatePeople) {
      this.dateLatePeople = moment(this.dateLatePeople).add(1, 'years').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'current') {
      this.dateLatePeople = moment().format('MM/DD/YYYY');
      requestCall = true;
    }

    if (requestCall) {
      $(".custom-loader-lateP").css("display", "table");
      $(".loader-response-lateP").css("display", "none");
      this.dateRangeLatePeopleChart = moment(this.dateLatePeople).format('YYYY');
      this.getLatePeopleFilter(this.dateLatePeople);
    }
    if (this.dateLatePeople == todayDate) {
      $("#nextBtnLatePeople").css("cursor", "not-allowed");
    } else {
      $("#nextBtnLatePeople").css("cursor", "pointer");
    }
  }

  getLatePeopleFilter(date) {
    this.dashboardService.getLatePeopleReport(date).subscribe(res => {
      if (res.status == "ok") {
        if (res.data.length > 0) {
          this.lstDailyData = [];
          this.lstDailyData.push(['Date', 'Count']);
          for (var i = 0; i < res.data.length; i++) {
            this.lstDailyData.push([new Date(res.data[i].date), res.data[i].count])
          }
          $(".custom-loader-lateP").css("display", "none");
          $(".loader-response-lateP").css("display", "block");
          this.dtDaily = google.visualization.arrayToDataTable(this.lstDailyData);
          this.chartDaily = new google.visualization.Calendar(document.getElementById('calendar_basic'));
          this.chartDaily.draw(this.dtDaily, this.chartOptionsDaily);
        } else {
          $(".custom-loader-lateP").css("display", "none");
          // $(".loader-response-lateP").css("display", "block");      
          $(".loader-response-lateP-nR").css("display", "block");
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  // getProjectsBaseFilter(startDate, endDate) {
  //   this.dashboardService.getProjectsBaseReport(startDate, endDate).subscribe(res => {
  //     if (res.status == "ok") {
  //       // if (res.data.length > 0) {
  //       this.lstProjectWData = [];
  //       this.lstProjectWData.push(
  //         ['Genre', 'Fantasy', 'Romance', 'Mystery/Crime', 'General','Western', 'Literature', { role: 'annotation' } ],
  //         ['2010', 10, 24, 20, 32, 18, 5, ''],
  //         ['2020', 16, 22, 23, 30, 16, 9, ''],
  //         ['2030', 28, 19, 29, 30, 12, 13, '']
  //       );
  //       // for (var i = 0; i < res.data.length; i++) {
  //       //   this.lstProjectWData.push([new Date(res.data[i].date), res.data[i].count])
  //       // }
  //       $(".custom-loader-dateProjectW").css("display", "none");
  //       $(".loader-response-dateProjectW").css("display", "block");
  //       this.dtProjectW = google.visualization.arrayToDataTable(this.lstProjectWData);
  //       this.chartProjectW = new google.visualization.ColumnChart(document.getElementById('chart_projectW'));
  //       this.chartProjectW.draw(this.dtProjectW, this.chartOptionsProjectW);
  //       // } else {
  //       // $(".custom-loader-dateProjectW").css("display", "none");
  //       // $(".loader-response-dateProjectW").css("display", "block");      
  //       // $(".loader-response-lateP-nR").css("display", "block");
  //       // }
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });
  // }

  // changeDateProjectW(data?: any) {
  //   let todayDate = moment().endOf('week').add(1, 'day').format('MM/DD/YYYY');//.subtract(1, 'w')
  //   let requestCall = false;
  //   if (data == 'prev') {
  //     this.startDateProjectW = moment(this.startDateProjectW).subtract(1, 'w').format('MM/DD/YYYY');
  //     this.endDateProjectW = moment(this.startDateProjectW).add(1, 'w').subtract(1, 'day').format('MM/DD/YYYY');
  //     requestCall = true;
  //   } else if (data == 'next' && todayDate != this.endDateProjectW) {
  //     this.startDateProjectW = moment(this.startDateProjectW).add(1, 'w').format('MM/DD/YYYY');
  //     this.endDateProjectW = moment(this.startDateProjectW).add(1, 'w').subtract(1, 'day').format('MM/DD/YYYY');
  //     requestCall = true;
  //   } else if (data == 'today') {
  //     this.startDateProjectW = moment().startOf('week').add(1, 'day').format('MM/DD/YYYY');//.subtract(1, 'w')
  //     this.endDateProjectW = moment(this.startDateProjectW).endOf('week').add(1, 'day').format('MM/DD/YYYY');
  //     requestCall = true;
  //   }

  //   if (requestCall) {
  //     $(".custom-loader-w").css("display", "table");
  //     $(".loader-response-w").css("display", "none");
  //     this.selectedDateProjectW = moment(this.startDateProjectW).format('MM/DD/YYYY') + " - " + moment(this.endDateProjectW).format('MM/DD/YYYY');
  //     this.getProjectsBaseFilter(this.startDateProjectW, this.endDateProjectW);

  //   }

  //   if (this.endDateProjectW == todayDate) {
  //     $("#nextBtnProjectW").css("cursor", "not-allowed");
  //   } else {
  //     $("#nextBtnProjectW").css("cursor", "pointer");
  //   }
  // }
}
