import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { AppService } from '../../app.service';
import { UserService } from 'src/services/user.service';
import { TasksService } from 'src/services/tasks.service';
import { RatingService } from 'src/services/rating.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from 'src/services/notification.service';
import * as moment from 'moment';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { ClickEvent, RatingChangeEvent } from 'angular-star-rating';
declare var $;
import { GlobalVariable } from "src/app/globals";
import { HttpErrorResponse } from '@angular/common/http/src/response';


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class UserViewComponent implements OnInit {
  public loginUser: any = '';
  public userDetail: any = "";
  public userId: number = 0;
  public ParamId = "";
  public assignTaskList: any = [];
  public allTaskList: any = [];
  public lateList: any = [];
  public countId = 0;
  public taskId = 0;
  userFiles: any = "";
  selectedSkills = [];
  public year: any = moment().format('YYYY');



  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;

  public pageNumber = 1;
  public pageSize = 25;
  public sortOrder = "";
  public sortBy = "";
  public search = "";

  //rating and reviews
  public ratingList: any = [];
  onClickResult: ClickEvent;
  onRatingChangeResult: RatingChangeEvent;
  public userrating = 0;
  public userReview = "";
  public defaultRating = 0;
  //attachments obj
  public attachDocumentName = [];
  public files: FileList;
  public formData: FormData = new FormData();
  private baseUrl = GlobalVariable.BASE_API_URL;
  public URL: string = this.baseUrl + "api/FileUpload/useruploadfile?userId=";

  constructor(
    private userService: UserService,
    // private taskService: TaskService,
    private tasksService: TasksService,
    private ratingService: RatingService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private noti: NotificationService,
    private http: Http
  ) {

  }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "People"
    });
    this.ParamId = this.route.snapshot.queryParams.id;
    if (!!this.ParamId) {

    } else {
      this.router.navigateByUrl('/user/list');
    }
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.getUserProfile(this.ParamId);

    // if(this.ParamId == this.loginUser.userId){
    //   this.userId = 0;
    // }else{
    //   this.userId = parseInt(this.ParamId);
    // }

    if (this.loginUser.accessLevel == 'super_admin') {
      if (this.ParamId == this.loginUser.userId) {
        this.userId = 0;
      } else {
        this.userId = parseInt(this.ParamId);
      }
    } else {
      this.userId = parseInt(this.ParamId);
    }
    $(".btn-submit-upload").prop('disabled', true);
    $("#attachment-wrapper").slideUp();

    // this.showAllTask();
    this.getUserFiles();
  }

  ngAfterViewInit(): void {
    this.dtTrigger2.next();
    this.dtTrigger3.next();
  }

  showProfile() {
  }

  // showAssignTask() {
  //   this.getAssignTask(this.userId);
  // }


  showlateList() {
    this.dtOptions3 = {
      pagingType: 'full_numbers',
      order: [[2, 'desc']],
      // "destroy": true,
      // "columnDefs": [
      //   { "width": "18%", "targets": 0 },
      //   { "width": "15%", "targets": 1 },
      //   { "width": "25%", "targets": 2 },
      // ],
    };
    this.userService.getLateuserList(this.userId).subscribe(response => {
      if (response.status == "ok") {
        this.lateList = response.data;
        this.rerenderLateList();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  getUserProfile(id) {
    this.userService.getUser(id).subscribe(response => {
      if (response.status == "ok") {
        this.userDetail = response.data;
        this.selectedSkills = JSON.parse(this.userDetail.skills);
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  // getAssignTask(id) {
  //   this.dtOptions2 = {
  //     pagingType: 'full_numbers',
  //     order: [[6, 'desc']],
  //     // "destroy": true,
  //     "columnDefs": [
  //       { "width": "20%", "targets": 0 },
  //       { "width": "15%", "targets": 1 },
  //       { "width": "25%", "targets": 2 },
  //       { "width": "12%", "targets": 3 },
  //       { "width": "8%", "targets": 4 },
  //       { "width": "10%", "targets": 5 },
  //       { "width": "10%", "targets": 6 }
  //     ],
  //   };
  //   this.tasksService.getAssignTask(id).subscribe(response => {
  //     if (response.status == "ok") {
  //       if (response.data.length > 0) {
  //         this.assignTaskList = response.data;
  //         this.rerenderAssignTask();
  //       }
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });
  // }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
  }

  // showAllTask() {
  //   const that = this;
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     pageLength: this.pageSize,
  //     order: [[5, 'desc']],
  //     "columnDefs": [
  //       { "width": "25%", "targets": 0 },
  //       { "width": "15%", "targets": 1 },
  //       { "width": "32%", "targets": 2 },
  //       { "width": "8%", "targets": 3 },
  //       { "width": "10%", "targets": 4 },
  //       { "width": "10%", "targets": 5 }
  //       // { "width": "10%", "targets": 6 }
  //     ],
  //     processing: true,
  //     serverSide: true,
  //     // orderMulti: false,
  //     ajax: (dTParameters: any, callback) => {
  //       that.pageSize = dTParameters['length'];
  //       that.pageNumber = dTParameters['start'] / this.pageSize + 1;
  //       that.sortOrder = dTParameters['order'][0].dir;
  //       that.search = dTParameters['search']['value'];
  //       // let column = dTParameters['order'][0].column;
  //       // switch (column) {
  //       //   case 0:
  //       //     that.sortBy = "assignedName";
  //       //     break;
  //       //   case 1:
  //       //     that.sortBy = "ProjectName";
  //       //     break;
  //       //   case 5:
  //       //     that.sortBy = "DateAdded";
  //       //     break;
  //       //   default:
  //       //     that.sortBy = "DateAdded";
  //       // }
  //       // that.taskService.getTaskList(that.pageNumber, that.pageSize, this.sortOrder, that.sortBy, that.search, that.userId).subscribe(response => {
  //       //   if (response != null) {
  //       //     if (response.status == "ok") {
  //       //       that.allTaskList = response.data.value.items;
  //       //       callback({
  //       //         recordsTotal: response.data.value.totalRecords,
  //       //         recordsFiltered: response.data.value.totalRecords,
  //       //         data: []
  //       //       });
  //       //     }
  //       //   }
  //       // });
  //       this.tasksService.getTaskList(this.pageNumber, this.pageSize, "DateAdded", this.sortOrder, this.search, this.userId).subscribe(response => {
  //         if (response.status == "ok") {
  //           this.allTaskList = response.data.value;            
  //           callback({
  //             recordsTotal: response.data.value[0].totalRecords,
  //             recordsFiltered: response.data.value[0].totalRecords,
  //             data: []
  //           });
  //         }
  //       });
  //     },
  //     columns: [
  //       { "data": 'title', "orderable": false },
  //       { "data": 'projectName', "orderable": false },
  //       { "data": 'description', "orderable": false },
  //       { "data": 'estimatedHours', "orderable": false },
  //       { "data": 'dueDate', "orderable": false },
  //       { "data": 'dateAdded', "orderable": false }
  //       // { "data": 'id', "orderable": false }
  //     ]
  //   };
  // }

  confirmDelete(id) {
    this.countId = id;
    $("#deleteModal").modal("show");
  }

  deleteCount() {
    this.userService.deleteLateCount(this.countId).subscribe(res => {
      if (res.status == "ok") {
        this.noti.notification("Success", "User Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "User not Deleted", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    for (var i = 0; i < this.lateList.length; i++) {
      if (this.lateList[i].id == this.countId) {
        this.lateList.splice(i, 1);
      }
    }
    this.rerenderLateList();
    $("#deleteModal").modal("hide");
  }

  rerenderAssignTask(): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == "second-table") {
          dtInstance.destroy();
          this.dtTrigger2.next();
        }
      });
    });
  }

  destroyAssignTask(): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == "second-table") {
          dtInstance.destroy();
        }
      });
    });
  }

  rerenderLateList(): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == "third-table") {
          dtInstance.destroy();
          this.dtTrigger3.next();
        }
      });
    });
  }

  destroyLateList(): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == "third-table") {
          dtInstance.destroy();
        }
      });
    });
  }

  showRatingNReviews() {
    if (this.userId == this.loginUser.userId || this.loginUser.accessLevel == 'super_admin' || this.loginUser.accessLevel == 'admin') {
      this.ratingService.getRatings(this.userId).subscribe(res => {
        if (res.status == "ok") {
          this.ratingList = res.data
          $(".custom-loader").css("display", "none");
          $(".loader-response").css("display", "block");
        }
      }, error => {
        if (error.status == 401) { }
      });
    }
  }

  addRating() {
    $(".btn-submit").prop('disabled', true);
    if (this.userrating > 0) {
      let data = {
        Rating: 0,
        Review: "",
        UserId: null,
        RatingBy: null
      }
      data.UserId = this.userId;
      data.Rating = this.userrating;
      data.Review = this.userReview;

      this.ratingService.addRating(data).subscribe(res => {
        if (res.status == "ok") {
          $('#formAdd').trigger("reset");
          this.userrating = 0;
          this.noti.notification("Success", "Review Added Successfully", "success");
          this.showRatingNReviews();
          $(".btn-submit").prop('disabled', false);
        } else {
          this.noti.notification("Error", "Review not Added", "danger");
          $(".btn-submit").prop('disabled', false);
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.noti.notification("Error", "Please fill all details", "danger");
      $(".btn-submit").prop('disabled', false);
    }
  }

  onRatingChange = ($event: RatingChangeEvent) => {
    this.onRatingChangeResult = $event;
    this.userrating = this.onRatingChangeResult.rating;
  };

  getUserFiles() {
    this.userService.getUsersFiles(this.userId).subscribe(response => {
      if (response.status == "ok") {
        if (!!response.data) {
          this.userFiles = response.data;
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  addFiles(event) {
    $(".btn-submit-upload").prop('disabled', false);
    if (this.attachDocumentName.length < 5) {
      this.files = event.target.files;
      for (let i = 0; i < this.files.length; i++) {
        this.attachDocumentName.push({ 'name': this.files[i].name, 'url': '' });
        this.formData.append('files', this.files[i], this.files[i].name)
      }
    } else {
      this.noti.notification("Error", "You Already Three Files Selected", "danger");
    }
  }

  deleteDoc(data) {
    this.attachDocumentName.splice(data, 1);
    var values = this.formData.getAll("files");
    values.splice(data, 1);
    this.formData.delete('files');
    values.filter(name => name !== 'Bob').forEach(name => this.formData.append('files', name));
    if (this.attachDocumentName.length == 0) {
      $(".btn-submit-upload").prop('disabled', true);
    }
  }

  uploadFiles() {
    $(".btn-submit-upload").prop('disabled', true);
    let url = this.URL + this.userId;
    this.http.post(url, this.formData)
      .subscribe(respo => {
        this.noti.notification("Success", "User Added Successfully", "success");
        this.formData.delete('files');
        this.attachDocumentName = [];
        this.getUserFiles();
        $("#attachment-wrapper").slideUp();
        $('input[name=file').val('');
      }, (error: HttpErrorResponse) => {
        // console.error(error);
      });
  }

  showAttachmentBox() {
    $("#attachment-wrapper").slideToggle();
  }
}
