import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'NumberFormater' })
export class NumberFormaterPipe implements PipeTransform {
    transform(num: number) {
        if (!num) return 0;
        num = parseFloat(num.toString().replace(',', ''));
        let formattedNumber = "";
        if (num > 100000) {
            formattedNumber = (num / 1000000).toFixed(1) + 'm';
        } else if (num > 999 && num < 99999) {
            formattedNumber = (num / 1000).toFixed(1) + 'k';
        } else {
            formattedNumber = num.toString();
        }

        return formattedNumber;
    }
}