import { Component, OnInit, ViewChild } from '@angular/core';
import { TaskService } from 'src/services/task.service';
import { DashboardService } from 'src/services/dashboard.service';
import { UserService } from 'src/services/user.service';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
  public taskEstList: any = [];
  public totalTasks: number = 0;
  public estCounts: number = 0;
  public nonEstCounts: number = 0;
  public totalEstHours: number = 0;
  public totalActHours: number = 0;

  public selectDate: any;
  public startDate = moment().format('MM/DD/YYYY');
  public endDate = moment().format('MM/DD/YYYY');
  public year: any = moment().format('YYYY');
  constructor(
    private appService: AppService,
    private taskService: TaskService,
    private userService: UserService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Estimated Hours"
    });
    this.filterTaskEstReport('this_week')
  }

  filterTaskEstReport(data) {
    this.dashboardService.getTaskEstReport(data).subscribe(res => {
      if (res.status == "ok") {
        this.totalTasks = 0;
        this.estCounts = 0;
        this.nonEstCounts = 0;
        this.totalEstHours = 0;
        this.totalActHours = 0;
        $(".custom-loader-s").css("display", "none");
        $(".loader-response-s").css("display", "block");
        this.taskEstList = res.data;
        for (var i = 0; i < this.taskEstList.length; i++) {
          this.totalTasks += this.taskEstList[i].totalTask;
          this.estCounts += this.taskEstList[i].estCount;
          this.nonEstCounts += this.taskEstList[i].nonEstCount;
          this.totalEstHours += this.taskEstList[i].totalEstHours;
          this.totalActHours += this.taskEstList[i].totalActHours;
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  // resetRecords(data) {

  // }
}
