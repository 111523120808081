import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class ValiService {
    private baseApiUrl = GlobalVariable.BASE_API_URL;
    constructor(
        private http: Http,
        private authService: AuthenticateService
    ) {
    }

    getLogs() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/vali';
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
        catchError(err => {
                return "error";
            }
        ));
    }
}
