import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/services/dashboard.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public user: any = '';
  public allCounts: any;
  public userId: number = 0;
  constructor(
    private appService: AppService,
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.user = user;
    this.appService.loginUser.subscribe((resp) => {
      if (resp.action == "update") {
        this.user = resp.data;
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(resp.data));
      }
      else if (resp.action == "remove") {
        localStorage.removeItem('user');
      }
    });
    this.getCounts();
  }
  signOut() {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
    this.appService.isAuthenticated.emit({
      action: "isAuthenticated",
      data: false
    });
    this.appService.loginUser.emit({
      action: "remove",
      data: ''
    });
    this.router.navigateByUrl('/login');
  }

  getCounts(){
    if (this.user.accessLevel != 'super_admin') {
      this.userId = this.user.userId;
    }
    this.dashboardService.getUserCounts(this.userId).subscribe(res => {
      if (res.status == "ok") {
        this.allCounts = res.data;       
      }
    }, error => {
      if (error.status == 401) { }
    });
  }
}
