import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { KeyValue } from '@angular/common';

declare var $;

@Pipe({ name: 'keyValue' })
export class KeyValuePipe implements PipeTransform {
    transform(value: (a: KeyValue<number,string>, b:  KeyValue<number,string>) => number): number {
        return 0;
    }

    //  transform(){
    //     originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    //     return 0;
    //   }
    // }

    // originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    //     return 0;
    //   }
}