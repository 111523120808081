import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';
//import 'rxjs/add/operator/map';

@Injectable()

export class ProjectService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) {
  }
  
  getActiveProjectList() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/projects/getActiveProject';
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }


  getProjectList() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/projects';
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  addProject(project) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/projects';
    return this.http.post(request, project, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  getProject(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/projects/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  updateProject(project) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/projects/' + project.id;
    return this.http.put(request, project, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  deleteProject(id){
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/projects/' + id;
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
}
