import { NotificationService } from 'src/services/notification.service';
import { forEach } from '@angular/router/src/utils/collection';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AssetsService } from 'src/services/assets.service';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import * as printJS from 'print-js'
import "jsbarcode";
declare var $;

@Component({
  selector: 'app-asset-management-system',
  templateUrl: './asset-management-system.component.html',
  styleUrls: ['./asset-management-system.component.css']
})
export class AssetManagementSystemComponent implements OnInit {
  public assetslist: any = [];
  public assetType: string = '';
  public equipmentCategory: string = '';
  public status: string = '';
  public modelName: string;
  public serialNo: string;
  public price: string;
  public barcodeNumber: string;
  public assetId = 0;
  public assetDeatil: any = "";
  categories = [
    { value: 'Laptop', label: 'Laptop', category: 'electronicDevices' },
    { value: 'Desktop', label: 'Desktop', category: 'electronicDevices' },
    { value: 'Lcd', label: 'LCD', category: 'electronicDevices' },
    { value: 'Printer', label: 'Printer', category: 'electronicDevices' },
    { value: 'Scanner', label: 'Scanner', category: 'electronicDevices' },
    { value: 'Projector', label: 'Projector', category: 'electronicDevices' },
    { value: 'Telephone', label: 'Telephone', category: 'electronicDevices' },
    { value: 'Tablet', label: 'Tablet', category: 'electronicDevices' },
    { value: 'Mobile', label: 'Mobile', category: 'electronicDevices' },
    { value: 'Mouse', label: 'Mouse', category: 'electronicDevices' },
    { value: 'Keyboard', label: 'Keyboard', category: 'electronicDevices' },
    { value: 'CPU', label: 'CPU', category: 'electronicDevices' },
    { value: 'NetworkingDevice', label: 'Networking Device', category: 'electronicDevices' },
    { value: 'Miscellaneous', label: 'Miscellaneous', category: 'electronicDevices' },
    
    { value: 'Desk', label: 'Desk', category: 'furniture' },
    { value: 'Chair', label: 'Chair', category: 'furniture' },
    { value: 'Cabinet', label: 'Cabinet', category: 'furniture' },
    { value: 'Table', label: 'Table', category: 'furniture' },
    { value: 'StorageUnit', label: 'Storage Unit', category: 'furniture' },
    { value: 'Sofa', label: 'Sofa', category: 'furniture' },

    { value: 'CCTVcameras', label: 'CCTV cameras', category: 'securityEquipments' },
    { value: 'BiometricDevice', label: 'Biometric Device', category: 'securityEquipments' },
    { value: 'DoorAccessCards', label: 'Door Access Cards', category: 'securityEquipments' },
    { value: 'Real-timeSurveillanceSystems', label: 'Real-time Surveillance Systems', category: 'securityEquipments' },
    
    { value: 'AirConditioner', label: 'Air Conditioner', category: 'electricalAppliances' },
    { value: 'Heater', label: 'Heater', category: 'electricalAppliances' },
    { value: 'Refrigerator', label: 'Refrigerator', category: 'electricalAppliances' },
    { value: 'Microwave', label: 'Microwave', category: 'electricalAppliances' },
    { value: 'Water Dispenser', label: 'Water Dispenser', category: 'electricalAppliances' },
    { value: 'Fan', label: 'Fan', category: 'electricalAppliances' },
    
    { value: 'Miscellaneous', label: 'Miscellaneous', category: 'miscellaneous' },

    { value: 'Stationery', label: 'Stationery', category: 'officeSupplies' },
    { value: 'Paper', label: 'Paper', category: 'officeSupplies' },
    { value: 'TonerandInk', label: 'Toner & Ink', category: 'officeSupplies' },
    { value: 'Organizers', label: 'Organizers', category: 'officeSupplies' },
    { value: 'Other', label: 'Other', category: 'officeSupplies' }
  ];

  
  filteredCategories : Category[] = [];
  filteredCategoriess : Category[]=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  barCodeHtml: any = "";
  public lcdCount = 0;
  public laptopCount = 0;
  public desktopCount = 0;
  public printerCount = 0;
  public macsCount = 0;
  public mobileCount = 0;
  public eStorageCount = 0;


  constructor(
    private assetsService: AssetsService,
    private appService: AppService,
    private noti: NotificationService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Assets"
    });
    this.assetsService.getassetscount().subscribe((response) => {
      for (var i = 0; i < response.data.length; i++) {
        switch (response.data[i].assetType) {
          case "electronicDevices":
            this.laptopCount = response.data[i].count;
            break;
          case "furniture":
            this.desktopCount = response.data[i].count;
            break;
          case "officeSupplies":
            this.lcdCount = response.data[i].count;
            break;
          case "electricalAppliances":
            this.printerCount = response.data[i].count;
            break;
          case "miscellaneous":
            this.macsCount = response.data[i].count;
            break;
          case "securityEquipments":
            this.mobileCount = response.data[i].count;
            break;
         
        }
      }
    }, error => {
      if (error.status == 401) { }
    }
    );
    this.getAssets();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  filterCategories() {
    if (this.assetType) {
      this.filteredCategories = this.categories.filter(category => category.category === this.assetType);
    } else {
      this.filteredCategories = [];
    }
  }
  filterCategoriess(){
    if (this.assetDeatil.assetType) {
      console.log(this.assetDeatil.assetType);
      this.filteredCategoriess = this.categories.filter(category => category.category === this.assetDeatil.assetType);
    } else {
      this.filteredCategoriess = [];
    }
  }
  getAssets() {
    this.dtOptions = {
      dom: 'frtlip',
      pagingType: 'full_numbers',
      pageLength: 100,
      order: [[4, 'desc']],
      processing: true,
      "destroy": true,
      "columnDefs": [
        { "width": "5%", "orderable": false, "targets": 0 },
        { "width": "13%", "targets": 1 },
        { "width": "15%", "targets": 2 },
        { "width": "15%", "targets": 3 },
        { "width": "15%", "targets": 4 },
        { "width": "10%", "orderable": false, "targets": 5 },
        { "width": "17%", "targets": 6 }
      ]
    };
    this.assetsService.getAssets().subscribe((response) => {
      if (response.status == "ok") {
        this.assetslist = response.data.value;
        for (var i = 0; i < this.assetslist.length; i++) {
          switch (this.assetslist[i].assetType) {
            case "electronicDevices":
              this.assetslist[i].assetType = "Electronic Devices"
              break;
            case "furniture":
              this.assetslist[i].assetType = "Furniture"
              break;
            case "officeSupplies":
              this.assetslist[i].assetType = "Office Supplies"
              break;
            case "electricalAppliances":
              this.assetslist[i].assetType = "ELectrical Appliances"
              break;
            case "miscellaneous":
              this.assetslist[i].assetType = "Miscellaneous"
              break;
            case "securityEquipments":
              this.assetslist[i].assetType = "Security Equipments"
              break;
           
            default:
              this.assetslist[i].assetType = "Electronic Devices"
          }

          // this.barCodeHtml += "<div style='border: 1px solid #E0E0E0;margin-bottom: 15px;'><p style='margin: 10px 10px 0;'>Model Name: " + this.assetslist[i].modelName + "</p><svg class='barcode' jsbarcode-format='CODE128' jsbarcode-value='" + this.assetslist[i].serialNo + "' jsbarcode-textmargin='0' jsbarcode-fontoptions='bold' jsbarcode-height='25'></svg><p style='margin-left: 10px;'>Enabling Systems</p></div>"          
        }
        this.rerender();
      }
      // document.getElementById("printarea").innerHTML = this.barCodeHtml;
      // var JsBarcode = require('jsbarcode');
      // JsBarcode(".barcode").init();

    }, error => {
      if (error.status == 401) { }
    });
  }

  saveAsset() {
    if (!!this.modelName && !!this.serialNo) {
      let item = {
        assetType: "",
        modelName: "",
        serialNo: "",
        barcodeNumber: "",
        equipmentCategory:"",
        status:""
        
      }
      
      item.assetType = this.assetType;
      item.modelName = this.modelName;
      item.serialNo = this.serialNo;
      item.barcodeNumber = this.barcodeNumber;
      item.equipmentCategory= this.equipmentCategory;
      item.status=this.status;

      this.assetsService.addAsset(item).subscribe((response) => {
        if (response.status == "ok") {
          this.getAssets();
          this.noti.notification("Success", "Asset Added Successfully", "success");
          $('#formAdd').trigger("reset");
        } else {
          this.noti.notification("Error", "Please fill all details", "danger");
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.noti.notification("Error", "Please fill all details", "danger");
    }
  }

  confirmUpdate(id) {
    this.assetId = id;
    this.assetsService.getAsset(this.assetId).subscribe((response) => {
      if (response.status == "ok") {
        this.assetDeatil = response.data;
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#updateModal").modal("show");
  }

  editAsset() {
    // let formId = event.target.form.id;
    var data = {};

    // let item = {
    //   assetType: "",
    //   modelName: "",
    //   serialNo: "",
    //   barcodeNumber: "",
    //   equipmentCategory:"",
    //   status:""
      
    // }
    
    // item.assetType = this.assetType;
    // item.modelName = this.modelName;
    // item.serialNo = this.serialNo;
    // item.barcodeNumber = this.barcodeNumber;
    // item.equipmentCategory= this.equipmentCategory;
    // item.status=this.status;

    // var dataArray = $("#" + formId).serializeArray();
    // let id = formId.replace("form", "");
    // dataArray.push({ name: 'id', value: id });
    // dataArray.push({ name: 'dateAdded', value: "" });
    // dataArray.push({ name: 'dateUpdated', value: "" });

    // for (var i = 0; i < dataArray.length; i++) {
    //   data[dataArray[i].name] = dataArray[i].value;
    // }
    this.assetsService.updateAsset(this.assetDeatil).subscribe((response) => {
      if (response.status == "ok") {
        this.getAssets();
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#updateModal").modal("hide");
  }

  confirmDelete(id) {
    this.assetId = id;
    $("#deleteModal").modal("show");
  }

  deleteAsset() {
    this.assetsService.deleteAsset(this.assetId).subscribe((response) => {
      if (response.status == "ok") {
        for (var i = 0; i < this.assetslist.length; i++) {
          if (this.assetslist[i].id == this.assetId) {
            this.assetslist.splice(i, 1);
            break; //Stop this loop, we found it!
          }
        }
        this.noti.notification("Success", "Asset Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Asset not Deleted", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#deleteModal").modal("hide");
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  printAsset() {
    var divToPrint = document.getElementById('printarea');
    // var htmlToPrint = '' +
    //   '<style type="text/css">' +
    //   '</style>';
    // htmlToPrint += divToPrint.outerHTML;
    // var newWin = window.open("");
    // // newWin.document.write("<h3 align='center'>Print Page</h3>");
    // newWin.document.write(htmlToPrint);
    // newWin.print();
    // newWin.close();
    printJS({ printable: 'printarea', type: 'html', showModal: true, targetStyles: ['*'] })
  }
}
interface Category {
  value: string;
  label: string;
  category: string;
}