import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { NotificationService } from 'src/services/notification.service';
import { AppBuildService } from 'src/services/appBuild.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalVariable } from "src/app/globals";
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-upload-build',
  templateUrl: './upload-build.component.html',
  styleUrls: ['./upload-build.component.css']
})
export class UploadBuildComponent implements OnInit {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  //build obj
  public buildDetail: any;
  public description: string = "";
  public formData: FormData = new FormData();
  public buildFile: FileList;
  public buildFileName: string = "";

  constructor(
    private appBuildService: AppBuildService,
    private noti: NotificationService,
    private router: Router,
    private http: Http
  ) { }

  ngOnInit() {
    $("#upload-data-form").hide();
    $(".loading-request-spin").hide();

    $(".submit-upload-btn").prop('disabled', true);
  }


  selectBuildFile(event) {
    let extension = event.target.files[0].name.split('.').pop();
    this.buildFileName = event.target.files[0].name;
    this.formData.delete('files')
    if (extension == "ipa" || extension == "apk") {
      this.buildFile = event.target.files;
      for (let i = 0; i < this.buildFile.length; i++) {
        this.formData.append('files', this.buildFile[i], this.buildFile[i].name)
      }
      $(".submit-upload-btn").prop('disabled', false);
      $(".invalid-name").css("color", "#000");
    } else {
      $(".submit-upload-btn").prop('disabled', true);
      $(".invalid-name").css("color", "red");
      this.noti.notification("Error", "Only select apk or ipa file format", "danger");
    }
  }

  // DocUpload(data) {
  //   let jsonData = JSON.parse(data.response);
  //   if (jsonData.status == "ok") {
  //     this.appLink = jsonData.data.buildUrl;
  //     this.appVersion = jsonData.data.bundleVersion;
  //     this.appBuild = jsonData.data.bundleBuildVersion;
  //     this.appName = jsonData.data.bundleName;
  //     this.appType = jsonData.data.type;
  //     $("#upload-data-form").show();
  //     $("#upload-build-wrapper").hide();
  //   } else {
  //     // this.fileUpload1.resetFileUpload();
  //     this.noti.notification("Error", "File uploading failed", "danger");
  //   }
  // }

  uploadBuildFile() {
    $(".submit-upload-btn").prop('disabled', true);
    $(".loading-request-spin").show();
    if (this.formData.has('files')) {

      let headers = new Headers({ 'Accept': 'multipart/form-data' });
      let options = new RequestOptions({ headers: headers });

      this.http.post(this.baseApiUrl + 'api/AppBuild/fileupload', this.formData)
        .subscribe(response => {
          let result = response.json();
          if (result.status == "ok") {
            this.buildDetail = result.data;
            $(".loading-request-spin").hide();
            $("#upload-data-form").show();
            $("#upload-build-wrapper").hide();
          } else {
            $(".loading-request-spin").hide();
            $(".submit-upload-btn").prop('disabled', false);
            if (result.data == "This app is not registered.") {
              this.noti.notification("Error", "Please contact administrator", "danger");
            } else {
              this.noti.notification("Error", "File uploading failed", "danger");
            }
          }
        }, (err: HttpErrorResponse) => {
          $(".loading-request-spin").hide();
          $(".submit-upload-btn").prop('disabled', false);
          this.noti.notification("Error", "File uploading failed", "danger");
        });

    } else {
      $(".loading-request-spin").hide();
      // $(".submit-upload-btn").prop('disabled', true);
    }
  }

  addBuildLog() {
    $(".submit-note-btn").prop('disabled', true);
    if (this.description.length > 5) {
      $(".loading-request-spin").show();
      $(".loading-request-text").hide();
      var log = {
        AppName: "",
        BundleName: "",
        BundleIdentifier: "",
        Version: "",
        Build: "",
        Url: "",
        Description: "",
        AppType: "",
        AppBinaryUrl: ""
      };
      log.AppName = this.buildDetail.appName;
      log.BundleName = this.buildDetail.bundleName;
      log.BundleIdentifier = this.buildDetail.bundleIdentifier;
      log.AppType = this.buildDetail.type;
      log.Version = this.buildDetail.bundleVersion;
      log.Build = this.buildDetail.bundleBuildVersion;
      log.AppBinaryUrl = this.buildDetail.buildUrl;
      log.Url = this.buildDetail.buildUrl;
      log.Description = this.description.trim();
      this.appBuildService.addBuild(log).subscribe(response => {
        if (response.status == "ok") {
          this.router.navigateByUrl('/build/build-history');
          this.noti.notification("Success", "Build Added Successfully", "success");
        } else {
          this.noti.notification("Error", "Error Adding Build", "danger");
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      $(".submit-note-btn").prop('disabled', false);
      this.noti.notification("Error", "Enter what's new atleast 3 characters.", "danger");
    }
  }
}
