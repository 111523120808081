import { NgModule } from '@angular/core';
export const GlobalVariable = Object.freeze({
  // local 
  // BASE_API_URL: 'http://localhost:8083/'
   //BASE_API_URL: 'https://localhost:44340/'
  // production
  // BASE_API_URL: 'https://dev.portal.enabling.systems/'
  // production
 //BASE_API_URL: 'https://localhost:44358/'
  BASE_API_URL: 'https://portal.enabling.systems/'


});
