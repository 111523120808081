import { Injectable } from '@angular/core';
declare var $;
@Injectable()

export class NotificationService {

    constructor() {
    }

    notification(title, message, type) {
        $.notify({
            icon: 'la la-bell',
            title: title,
            message: message,
        }, {
                type: type,
                placement: {
                    from: "top",
                    align: "right"
                },
                time: 1000,
            });
    }
}