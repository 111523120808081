import { Injectable, EventEmitter } from '@angular/core';
import { UserService } from 'src/services/user.service';
import { GlobalVariable } from "./globals"
import { Router } from '@angular/router';

@Injectable()

export class AppService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  public isAuthenticated: EventEmitter<{ action: string; data: any; }> = new EventEmitter();
  public loginUser: EventEmitter<{ action: string; data: any; }> = new EventEmitter();
  public headerTitle: EventEmitter<{ data: any; }> = new EventEmitter();
  constructor(
    private userService: UserService,
    private router: Router
  ) {  }
  //check user login status
  checkLoginStatus() {
    return new Promise((resolve, reject) => {
      let authenticated = Boolean(localStorage.getItem('isAuthenticated'));
      if (authenticated) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.isAuthenticated.emit({
          action: "isAuthenticated",
          data: true
        });
        this.loginUser.emit({
          action: "update",
          data: user
        });
        resolve();
      }
      else {
        this.signOut();
      }
    });
  }

  lastLoginupdate() {
    return new Promise((resolve, reject) => {
      let authenticated = Boolean(localStorage.getItem('isAuthenticated'));
      if (authenticated) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userService.updateLastLogin(user.userId).subscribe(res => {
          resolve();
        }, error => {
          if (error.status == 401) {
            this.signOut();
            // reject();
          }
        }
        );
      }
      else {
        this.signOut();
        // reject();
      }
    });
  }

  signOut() {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
    this.isAuthenticated.emit({
      action: "isAuthenticated",
      data: false
    });
    this.loginUser.emit({
      action: "remove",
      data: ''
    });
    this.router.navigateByUrl('/login');
  }
}
