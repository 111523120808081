import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class BaltoroService {
    private baseApiUrl = GlobalVariable.BASE_API_URL;
    constructor(
        private http: Http,
        private authService: AuthenticateService
    ) {
    }

    getAll() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/baltoro';
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    GetAllBack(){

    }

    getAllDownload() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/baltoro/downloadBackup';
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }
    getAllUploadRepostory() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/baltoro/RepositoryBackup';
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    getAllDownloadRepostory() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/baltoro/RepositoryDownload';
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }
    combineList() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/baltoro/combineListBackup';
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    getLastSuccess() {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + 'api/baltoro/lastsuccessful';
        return this.http.get(request, options).pipe(map(
            response => {
                return response.json()
            }
        ),
            catchError(err => {
                return "error";
            }
            ));
    }

    getFilterLogs(startDate, endDate) {
        let options = this.authService.setHeaderAuth();
        let request = this.baseApiUrl + `api/baltoro/getfilterlogs?startDate=${startDate}&endDate=${endDate}`;
        return this.http.get(request, options).pipe(
          map(
            response => {
              return response.json()
            }
          ),
          catchError(err => {
            return "error";
          }
          )
        );
      }

    //Get Baltoro Bucket Size
    getBBSize() {
        let request = this.baseApiUrl + 'api/FileUpload/bucketdetail';
        return this.http.get(request).pipe(
            map(
                response => {
                    return response.json()
                }
            ),
            catchError(err => {
                return "error";
            }
            )
        );
    }
}
