import { Component, OnInit } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { UserService } from 'src/services/user.service';
import { NotificationService } from 'src/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GlobalVariable } from "src/app/globals";
import { HttpErrorResponse } from '@angular/common/http';
import { AppService } from '../../app.service';
import * as moment from 'moment';
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { DomSanitizer } from '@angular/platform-browser';
import { forEach } from '@angular/router/src/utils/collection';
import { event } from 'jquery';
declare var $;

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class UserEditComponent implements OnInit {
  userId: string = '';
  fileId: string = '';
  userDetail: any = "";
  userFiles: any = "";
  confirmPassword: string = '';
  profilePhoto: FileList;
  croppedImage: any = '';
  private baseUrl = GlobalVariable.BASE_API_URL;
  public URL: string = this.baseUrl + "api/FileUpload/useruploadfile?userId=";
  files: FileList;
  formData: FormData = new FormData();
  public loginUser: any = '';
  public attachDocumentName = [];
  imageChangedEvent: any = '';
  showCropperModal = false;
  // public attachDocument: boolean = false;
  public isProfilePic: boolean = false;
  public userDoB = new Date();
  public userJoinDate = new Date();

  //skills multiselect-dropdown
  itemList = [];
  selectedItems = [];
  settings = {};
  itemListCount = 0;
  public year: any = moment().format('YYYY');


  constructor(
    private http: Http,
    private userService: UserService,
    private route: ActivatedRoute,
    private noti: NotificationService,
    private router: Router,
    private appService: AppService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "People"
    });
    this.userId = this.route.snapshot.queryParams.id;
    if (!!this.userId) {
      this.loginUser = JSON.parse(localStorage.getItem('user'));
      this.getUser();
      this.getUserFiles();
      this.getSkills();
    } else {
      this.router.navigateByUrl('/user/list');
    }

  }


  getSkills() {
    this.userService.getSkills().subscribe(res => {
      if (res.status == "ok") {
        this.itemList = res.data;
        this.itemListCount = this.itemList.length;
      }
    }, error => {
      if (error.status == 401) { }
    });

    this.selectedItems = [];
    this.settings = {
      enableCheckAll: false,
      singleSelection: false,
      text: "Select Skills",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // enableFilterSelectAll:false,
      addNewItemOnFilter: true
    };
  }
  onAddItem(data: string) {
    this.itemListCount++;
    this.itemList.push({ "id": this.itemListCount, "itemName": data });
    this.selectedItems.push({ "id": this.itemListCount, "itemName": data });
  }
  onItemSelect(item: any) {

  }
  OnItemDeSelect(item: any) {

  }
  onSelectAll(items: any) {

  }
  onDeSelectAll(items: any) {

  }

  getUser() {
    if (!!this.userId) {
      this.userService.getEditUser(this.userId).subscribe(response => {
        if (response.status == "ok") {
          if (!!response.data) {
            this.userDetail = response.data;
            this.userDoB = new Date(this.userDetail.dob);
            this.userJoinDate = new Date(this.userDetail.joinDate);
            this.selectedItems = JSON.parse(this.userDetail.skills);
            if (this.userDetail.profilePhoto != "") {
              this.isProfilePic = true;
            }
            $.trim(this.userDetail.isActive) == "true" ? this.userDetail.isActive = true : this.userDetail.isActive = false;
            this.confirmPassword = this.userDetail.password;
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {

    }
  }

  getUserFiles() {
    this.userService.getUsersFiles(this.userId).subscribe(response => {
      if (response.status == "ok") {
        if (!!response.data) {
          this.userFiles = response.data;
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  editUser() {
    $(".btn-submit").prop('disabled', true);
    let isValid = this.validate();
    if (isValid) {
      this.userDetail.documentUrl = "";
      this.userDetail.dob = this.userDoB;
      this.userDetail.joinDate = this.userJoinDate;
      this.userDetail.Skills = JSON.stringify(this.selectedItems);
      let newData = JSON.stringify(this.itemList);
      this.userService.updateSkills(newData).subscribe(res => {
        if (res.status == "ok") {
        }
      }, error => {
        if (error.status == 401) {
          console.error("Error: 401");
        }
      });
      this.userService.updateUser(this.userDetail).subscribe(res => {
        if (res.status == "ok") {
          if (this.formData.has('files') || this.formData.has('profile')) {
            this.uploadFiles();
          } else {
            this.updateUserProfile();
            this.noti.notification("Success", "User Updated Successfully", "success");
            this.router.navigateByUrl('/user/list');
          }
        } else {
          this.noti.notification("Error", "User not Updated", "danger");
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.noti.notification("Error", "Please enter correct details", "danger");
      $(".btn-submit").prop('disabled', false);
    }
  }

  validate() {
    if (!(!!this.userDetail.firstName.trim())) {
      return false;
    }
    if (!!this.userDetail.phoneNumber) {
      this.userDetail.phoneNumber = this.userDetail.phoneNumber.replace(/\s/g, '');
      if (!(/^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(this.userDetail.phoneNumber))) {
        return false;
      }
    }
    if (!(!!this.userDetail.email.trim())) {
      return false;
    }
    if (!!this.userDetail.email.trim()) {
      this.userDetail.email = this.userDetail.email.replace(/\s/g, '');
      if (!(/^([a-zA-Z0-9_\.\-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/.test(this.userDetail.email))) {
        return false;
      }
    }
    if (!(!!this.userDetail.password.trim()) && !(!!this.confirmPassword.trim())) {
      return false;
    }
    if (this.userDetail.password.trim() != this.confirmPassword.trim()) {
      return false;
    }
    if (!(!!this.userDetail.designation.trim())) {
      return false;
    }
    if (!(!!this.userDetail.accessLevel)) {
      return false;
    }
    return true;
  }

  //addProfilePic(event) {

    // this.formData.delete('profile')
    // if (event.target.files && event.target.files[0]) {
    //   var reader = new FileReader();

    //   reader.onload = function (e) {
    //     $('#profile-viewer').attr('src', e.target.result);
    //   }
    //   reader.readAsDataURL(event.target.files[0]);
    // }

    // this.profilePhoto = event.target.files;
    // for (let i = 0; i < this.profilePhoto.length; i++) {
    //   this.isProfilePic = true;
    //   this.formData.append('profile', this.profilePhoto[i], this.profilePhoto[i].name)
    // }
    
  //}

  addFiles(event) {
    if (this.attachDocumentName.length < 5) {
      this.files = event.target.files;
      for (let i = 0; i < this.files.length; i++) {
        this.attachDocumentName.push({ 'name': this.files[i].name, 'url': '' });
        this.formData.append('files', this.files[i], this.files[i].name)
      }
    } else {
      this.noti.notification("Error", "You Already Three Files Selected", "danger");
    }
  }

  uploadFiles() {
    let url = this.URL + this.userId;
    this.http.post(url, this.formData)
      .subscribe(respo => {
        let updateUser = JSON.parse(respo["_body"]);
        this.userDetail = updateUser.data;
        this.updateUserProfile();
        this.noti.notification("Success", "User Added Successfully", "success");
        this.router.navigateByUrl('/user/list');
      }, (error: HttpErrorResponse) => {
        // console.error(error);
      });
  }

  updateUserProfile() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.userDetail.token = user.token;
    if (this.userDetail.userId == user.userId) {
      this.appService.loginUser.emit({
        action: "update",
        data: this.userDetail
      });
    }
  }

  deleteUserFile() {
    this.userService.deleteUserFile(this.fileId).subscribe(response => {
      if (response.status == "ok") {
        for (var i = 0; i < this.userFiles.length; i++) {
          if (this.userFiles[i].id == this.fileId) {
            this.userFiles.splice(i, 1);
            break;
          }
        }
        this.noti.notification("Success", "File Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Cannot Delete This File", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#deleteModal").modal("hide");
  }

  deleteDoc(data) {
    this.attachDocumentName.splice(data, 1);
    var values = this.formData.getAll("files");
    values.splice(data, 1);
    this.formData.delete('files');
    values.filter(name => name !== 'Bob').forEach(name => this.formData.append('files', name));
  }

  confirmDelete(data) {
    this.fileId = data;
    $("#deleteModal").modal("show");
  }

  deleteProfilePic() {
    this.userDetail.profilePhoto = "";
    this.formData.delete('profile');
    this.isProfilePic = false;
    $('#profile-viewer').attr('src', '/assets/img/add-img.png');
    $('#profilePhoto').val(null);
  }


  

//   addProfilePic(event: any) {
//     this.formData.delete('profile')
//        this.isProfilePic = true;
//        this.imageChangedEvent = event;
//     if (event.target.files && event.target.files[0]) {
//       var reader = new FileReader();

//       reader.onload = function (e) {
//         $('#profile-viewer').attr('src', e.target.result);
//       }
//       reader.readAsDataURL(event.target.files[0]);
//     }
//     this.profilePhoto = event.target.files;
   
//     for (let i = 0; i < this.profilePhoto.length; i++) {
//       this.isProfilePic = true;
//       this.formData.append('profile', this.profilePhoto[i], this.profilePhoto[i].name)
//       this.showCropperModal = true;
//       console.log("Before Crop"+ this.profilePhoto);
//     }
// }

// confirmCrop() {
//   $('#profile-viewer').attr('src', this.croppedImage); 
//   this.profilePhoto = this.croppedImage; 
//   console.log("After Crop"+this.profilePhoto);
//   this.showCropperModal = false; 
// }
closeCropper(){
  this.showCropperModal = false;
}
imageLoaded() {
  console.log("Image has been loaded successfully in the cropper.");
  
}
    // imageCropped(event: ImageCroppedEvent) {
    //     this.croppedImage = event.base64;
    // }
    addProfilePic(event: any) {
      this.formData.delete('profile');
      this.isProfilePic = true;
      this.imageChangedEvent = event;
    
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
    
        reader.onload = (e: any) => {
          // Set the image preview source
          document.getElementById('profile-viewer').setAttribute('src', e.target.result);
        };
    
        reader.readAsDataURL(event.target.files[0]);
    
        // Save the file in the formData
        this.profilePhoto = event.target.files;
        for (let i = 0; i < this.profilePhoto.length; i++) {
          this.isProfilePic = true;
          this.formData.append('profile', this.profilePhoto[i], this.profilePhoto[i].name);
        }
    
        // Open the cropper modal
        this.showCropperModal = true;
      }
    }
    confirmCrop() {
      // Set the cropped image preview
      document.getElementById('profile-viewer').setAttribute('src', this.croppedImage);
    
      // Optionally, create a Blob from the cropped image base64 data and update the profilePhoto
      const blob = this.convertBase64ToBlob(this.croppedImage, 'image/png');
      const file = new File([blob], 'cropped-image.png', { type: 'image/png' });
    
      // Update the formData with the new cropped image
      this.formData.delete('profile');
      this.formData.append('profile', file, file.name);
    
      // Close the cropper modal
      this.showCropperModal = false;
    }
    imageCropped(event: ImageCroppedEvent) {
      // Store the base64 data of the cropped image
      this.croppedImage = event.base64;
    }
    convertBase64ToBlob(base64: string, mimeType: string): Blob {
      const byteCharacters = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    }
                
}
