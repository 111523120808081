import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { BaltoroService } from 'src/services/baltoro.service';

@Component({
  selector: 'app-view-log',
  templateUrl: './view-log.component.html',
  styleUrls: ['./view-log.component.css']
})
export class ViewLogComponent implements OnInit {
  public allRecords = [];
  public hostName = "";
  public serviceName = "";
  public bodyhtml = "";
  public requestMoreRecords = false;
  // public limit = 20;
  // public startKey = "";
  public selectDate: any;

  public startQueryDate: any;
  public startQueryAdd: any;
  public startQuery: any;
  public endQueryDate: any;
  public endQueryAdd: any;
  public endQuery: any;
  public year: any = moment().format('YYYY');

  constructor(
    private baltoroService: BaltoroService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.hostName = this.route.snapshot.queryParams.h;
    this.serviceName = this.route.snapshot.queryParams.s;
    $('#selectOptionServer').val(this.hostName ? this.hostName : 'view_all');
    this.changeDate('today');
  }

  changeDate(data?: any) {
    let todayDate = moment().endOf('day').format('YYYY-MM-DD h:mm a');
    let requestCall = false;
    if (data == 'prev') {
      this.startQueryDate = moment(this.startQueryDate).subtract(7, 'day').format('MM/DD/YYYY');
      this.startQueryAdd = moment(this.startQueryDate).add(5, 'hours');
      this.startQuery = moment(this.startQueryAdd).format('MM/DD/YYYY');

      this.endQueryDate = moment(this.startQueryDate).add(6, 'day').format('MM/DD/YYYY');
      this.endQueryAdd = moment(this.endQueryDate).add(5, 'hours');
      this.endQuery = moment(this.endQueryAdd).format('MM/DD/YYYY');

      requestCall = true;
    } else if (data == 'next' && todayDate != this.endQueryDate) {
      this.startQueryDate = moment(this.startQueryDate).add(7, 'day').format('MM/DD/YYYY');
      this.startQueryAdd = moment(this.startQueryDate).add(5, 'hours');
      this.startQuery = moment(this.startQueryAdd).format('MM/DD/YYYY');

      this.endQueryDate = moment(this.startQueryDate).add(6, 'day').format('MM/DD/YYYY');
      this.endQueryAdd = moment(this.endQueryDate).add(5, 'hours');
      this.endQuery = moment(this.endQueryAdd).format('MM/DD/YYYY');


      requestCall = true;
    } else if (data == 'today') {
      this.startQueryDate = moment().subtract(6, 'day').format('MM/DD/YYYY');
      this.startQueryAdd = moment(this.startQueryDate).add(5, 'hours');
      this.startQuery = moment(this.startQueryAdd).format('MM/DD/YYYY');

      this.endQueryDate = moment(this.startQueryDate).add(6, 'day').format('MM/DD/YYYY');
      this.endQueryAdd = moment(this.endQueryDate).add(5, 'hours');
      this.endQuery = moment(this.endQueryAdd).format('MM/DD/YYYY');
      requestCall = true;
    }

    if (requestCall) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      $(".loader-response").css("display", "none");
      $(".loader-container").css("display", "table");
      this.selectDate = moment(this.startQueryDate).format('MM/DD/YYYY') + " - " + moment(this.endQueryDate).format('MM/DD/YYYY');

      this.getAllLogs();
    }

    if (this.endQueryDate == todayDate) {
      $("#nextBtn").css("cursor", "not-allowed");
    } else {
      $("#nextBtn").css("cursor", "pointer");
    }

  }

  getAllLogs() {
    this.baltoroService.getFilterLogs(this.startQuery, this.endQuery).subscribe(res => {
      if (res.status == "ok") {
        if (res.data.length > 0) {
          // this.allRecords = res.data;
          for (var i = 0; i < res.data.length; i++) {
            this.allRecords.push(res.data[i]);
          }
          // this.allRecords.push(res.data[0]);
          this.resetRecords(this.hostName, this.serviceName);
        }
      }
      // }
    });
  }

  resetRecords(hostName, serviceName?) {
    if (serviceName && hostName) {
      this.viewFilterLogs();
    } else if (!serviceName && hostName != "view_all") {
      this.serviceName = "";
      this.hostName = hostName;
      this.viewFilterLogsByHost();
    } else if (hostName == "view_all") {
      this.serviceName = "";
      this.hostName = hostName;
      this.viewAllLogs();
    }
  }

  viewFilterLogs() {
    this.bodyhtml = "";
    var statusColor = "";
    var BorderLeftColor = "";
    var backgroundColor = "";
    for (var i = 0; i < this.allRecords.length; i++) {
      if ((moment(this.allRecords[i].dateAdded).format('MM/DD/YYYY') >= moment(this.startQueryDate).format('MM/DD/YYYY')) && (moment(this.allRecords[i].dateAdded).format('MM/DD/YYYY') <= moment(this.endQueryDate).format('MM/DD/YYYY'))) {
        if (this.allRecords[i].host == this.hostName) {
          if (this.allRecords[i].serviceName == this.serviceName) {
            this.allRecords[i].status == 'success' ? statusColor = '#4CAF50' : statusColor = '#ef0e1b';
            if (this.allRecords[i].host == "SkinnyShrimp Server") {
              BorderLeftColor = "#f44336";
              backgroundColor = "rgba(244, 67, 54, 0.1)";
            } else if (this.allRecords[i].host == "Gravity") {
              BorderLeftColor = "#9c27b0";
              backgroundColor = "rgba(156, 39, 176, 0.1)";
            } else if (this.allRecords[i].host == "ApproachMe Server") {
              BorderLeftColor = "#3f51b5";
              backgroundColor = "rgba(63, 81, 181, 0.1)";
            } else if (this.allRecords[i].host == "Rocket") {
              BorderLeftColor = "#ff9800";
              backgroundColor = "rgba(255, 152, 0, 0.1)";
            } else {
              BorderLeftColor = "#33A4DE";
              backgroundColor = "rgba(51, 164, 222, 0.1)";
            }
            // if (this.allRecords[i].host == "SkinnyShrimp Server" || this.allRecords[i].host == "ApproachMe Server") {
            var local = moment.utc(this.allRecords[i].dateAdded).local().format('MM-DD-YYYY h:mm a');
            // } else {
            //  var local = moment(this.allRecords[i].dateAdded).format('MM-DD-YYYY h:mm a');
            //}
            this.bodyhtml += "<div data-type='INFO' class='outer-wrapper' style='border: 1px solid " + statusColor
              + "; border-left: 8px solid " + BorderLeftColor + " !important;background-color:" + backgroundColor + "'><span>Date: " + local + "</span><span>, Host: " + this.allRecords[i].host
              + "</span><span>, Service Name: " + this.allRecords[i].serviceName + "</span><span> ,Description: "
              + this.allRecords[i].description + "</span></div>";
          }
        }
      }
    }
    document.getElementById("main-box").innerHTML = this.bodyhtml;
    $(".outer-wrapper").css({ "padding": "10px", "margin-bottom": "10px", "word-wrap": "break-word" });
    $(".loader-container").css("display", "none");
    $(".loader-response").css("display", "block");
  }

  viewFilterLogsByHost() {
    this.bodyhtml = "";
    var statusColor = "";
    var BorderLeftColor = "";
    var backgroundColor = "";
    for (var i = 0; i < this.allRecords.length; i++) {
      if ((moment(this.allRecords[i].dateAdded).format('MM/DD/YYYY') >= moment(this.startQueryDate).format('MM/DD/YYYY')) && (moment(this.allRecords[i].dateAdded).format('MM/DD/YYYY') <= moment(this.endQueryDate).format('MM/DD/YYYY'))) {
        if (this.allRecords[i].host == this.hostName) {
          // if (this.allRecords[i].serviceName == this.serviceName) {
          this.allRecords[i].status == 'success' ? statusColor = '#4CAF50' : statusColor = '#ef0e1b';
          if (this.allRecords[i].host == "SkinnyShrimp Server") {
            BorderLeftColor = "#f44336";
            backgroundColor = "rgba(244, 67, 54, 0.1)";
          } else if (this.allRecords[i].host == "Gravity") {
            BorderLeftColor = "#9c27b0";
            backgroundColor = "rgba(156, 39, 176, 0.1)";
          } else if (this.allRecords[i].host == "ApproachMe Server") {
            BorderLeftColor = "#3f51b5";
            backgroundColor = "rgba(63, 81, 181, 0.1)";
          } else if (this.allRecords[i].host == "Rocket") {
            BorderLeftColor = "#ff9800";
            backgroundColor = "rgba(255, 152, 0, 0.1)";
          } else {
            BorderLeftColor = "#33A4DE";
            backgroundColor = "rgba(51, 164, 222, 0.1)";
          }
          if (this.allRecords[i].host == "SkinnyShrimp Server" || this.allRecords[i].host == "ApproachMe Server") {
            var local = moment.utc(this.allRecords[i].dateAdded).local().format('MM-DD-YYYY h:mm a');
          } else {
            var local = moment(this.allRecords[i].dateAdded).format('MM-DD-YYYY h:mm a');
          }

          this.bodyhtml += "<div data-type='INFO' class='outer-wrapper' style='border: 1px solid " + statusColor
            + "; border-left: 8px solid " + BorderLeftColor + " !important;background-color:" + backgroundColor + "'><span>Date: " + local + "</span><span>, Host: " + this.allRecords[i].host
            + "</span><span>, Service Name: " + this.allRecords[i].serviceName + "</span><span> ,Description: "
            + this.allRecords[i].description + "</span></div>";
          // }
        }
      }
    }
    document.getElementById("main-box").innerHTML = this.bodyhtml;
    $(".outer-wrapper").css({ "padding": "10px", "margin-bottom": "10px", "word-wrap": "break-word" });
    $(".loader-container").css("display", "none");
    $(".loader-response").css("display", "block");
  }

  viewAllLogs() {
    this.bodyhtml = "";
    var statusColor = "";
    var BorderLeftColor = "";
    var backgroundColor = "";
    for (var i = 0; i < this.allRecords.length; i++) {
      if ((moment(this.allRecords[i].dateAdded).format('MM/DD/YYYY') >= moment(this.startQueryDate).format('MM/DD/YYYY')) && (moment(this.allRecords[i].dateAdded).format('MM/DD/YYYY') <= moment(this.endQueryDate).format('MM/DD/YYYY'))) {
        this.allRecords[i].status == 'success' ? statusColor = '#4CAF50' : statusColor = '#ef0e1b';
        if (this.allRecords[i].host == "SkinnyShrimp Server") {
          BorderLeftColor = "#f44336";
          backgroundColor = "rgba(244, 67, 54, 0.1)";
        } else if (this.allRecords[i].host == "Gravity") {
          BorderLeftColor = "#9c27b0";
          backgroundColor = "rgba(156, 39, 176, 0.1)";
        } else if (this.allRecords[i].host == "ApproachMe Server") {
          BorderLeftColor = "#3f51b5";
          backgroundColor = "rgba(63, 81, 181, 0.1)";
        } else if (this.allRecords[i].host == "Rocket") {
          BorderLeftColor = "#ff9800";
          backgroundColor = "rgba(255, 152, 0, 0.1)";
        } else {
          BorderLeftColor = "#33A4DE";
          backgroundColor = "rgba(51, 164, 222, 0.1)";
        }
        if (this.allRecords[i].host == "SkinnyShrimp Server" || this.allRecords[i].host == "ApproachMe Server") {
          var local = moment.utc(this.allRecords[i].dateAdded).local().format('MM-DD-YYYY h:mm a');//.format('MM-DD-YYYY h:mm a');
        } else {
          var local = moment(this.allRecords[i].dateAdded).format('MM-DD-YYYY h:mm a');//.format('MM-DD-YYYY h:mm a');
        }

        this.bodyhtml += "<div class='outer-wrapper' style='border: 1px solid " + statusColor
          + "; border-left: 8px solid " + BorderLeftColor + " !important;background-color:" + backgroundColor + "'><span>Date: " + local + "</span><span>, Host: " + this.allRecords[i].host
          + "</span><span>, Service Name: " + this.allRecords[i].serviceName + "</span><span> ,Description: "
          + this.allRecords[i].description + "</span></div>";
        // <span>Type: " + this.allRecords[i].type + "</span>
      }
    }
    document.getElementById("main-box").innerHTML = this.bodyhtml;
    $(".outer-wrapper").css({ "padding": "10px", "margin-bottom": "10px", "word-wrap": "break-word" });
    //hide loader
    $(".loader-container").css("display", "none");
    $(".loader-response").css("display", "block");
  }
}
