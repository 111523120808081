import { Component, OnInit, ViewChild } from '@angular/core';
import { WebsiteService } from 'src/services/websites.service';
import { NotificationService } from 'src/services/notification.service';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Chart, registerables } from 'chart.js';
import { get } from 'jquery';
declare var $;

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class WebsiteLogsComponent implements OnInit {
  public websiteLogs: any = [];
  public website7dayslog: any =[];
  public website30dayslog: any =[];
  public website365dayslog: any =[];
  public websitefilterlog : any=[];
  public webUrlLogs: any=[];
  public websiteId = 0;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public startDate: any  = moment().format('MM/DD/YYYY');
  public endDate: any  = moment().format('MM/DD/YYYY');
  public year: any = moment().format('YYYY');
  isDateRangePickerVisible: boolean ;
  title = 'ChartProject';
  chart: any;
  currentTimeRange: string = '7d'; // Default time range
  websiteLog: any = [];
  
  timeRanges = [
    { label: 'Last 7 Days', value: '7d' },
    { label: 'Last 30 Days', value: '30d' },
    { label: 'Last 24 Hours', value: '24h' },
    { label: 'Last Year', value: '365d' }
  ];

 

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  //paging logs
  public pageNumber = 1;
  public pageSize = 25;
  public sortOrder = "";
  public sortBy = "";
  public search = "";
  public filterLog: boolean = true;
  public errorCount: number = 0;
  public okCount: number = 0;
  public totalCount: number = 0;
  public errorPercentage: number = 0;
  public okPercentage: number = 0;
  public Percentage: number = 0;
  public totalCounts: number = 0;
  public ok: number = 0;
  public Percentage365: number = 0;
  public totalCounts365: number = 0;
  public ok365: number = 0;
  public lateUserDate: any;
  public error : number=0;
  public filterok:number=0;
  public filterpersontage:number;
  public filtertotalcount:number; 
  isSwitchActive: boolean ;
  switchLabel: string = ''; 
  public websiteList: any = [];
  public websiteLists: any = [];
  constructor(
    private websiteService: WebsiteService,
    private noti: NotificationService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar,
    
  ) { }
  

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "www Monitor"
    });
    this.websiteId = this.route.snapshot.queryParams.id;
    if (!!this.websiteId) {

    } else {
      this.router.navigateByUrl('/www-monitor/list');
    }
    $('[data-toggle="tooltip"]').tooltip();

     this.getWebsiteLogs(this.websiteId);
    //  this.getwebsite7dayslogs(this.websiteId);
    //  this.getwebsite30dayslogs(this.websiteId);
    // this.getwebsite365dayslogs(this.websiteId);
     this.getwebsiteUrlLogs(this.websiteId);
     this.updateLabel();
   
    
  }
toggleDateRangePicker(): void {
    this.isDateRangePickerVisible = !this.isDateRangePickerVisible;
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    
  }
  userLateModelOpen() {
    // this.lateUserDatepicker = this.calendar.getToday();
    // this.maxDate = this.lateUserDatepicker;
    $("#lateCountModal").modal("show");
  }
  
  setTimeRange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.currentTimeRange = target.value;
   // this.updateChart(); // Update chart with the new time range
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  onDateSelectModel(event) {
    this.lateUserDate = moment().year(event.year).month(event.month - 1).date(event.day).format();
  }
  filterLogs(data) {
    if (data == "all") {
      this.filterLog = false;
      this.rerender();
      // this.getWebsiteLogs(this.websiteId, false);
    } else if (data == "default") {
      this.filterLog = true;
      this.rerender();
      // this.getWebsiteLogs(this.websiteId, true);
    }
  }

//   getwebsite7dayslogs(Id) {
//     this.websiteService.getWebsite7daysLog(Id).subscribe(response => {
//         if (response.status === "ok") {
//             this.website7dayslog = response.data;
//            // console.log(this.website7dayslog);
//             this.okCount = this.website7dayslog.filter(log => log.statusCode === 'OK').length;
//             this.totalCount = this.website7dayslog.length;
//             this.errorPercentage = this.totalCount ? (this.errorCount / this.totalCount) * 100 : 0;
//             const decimalPlaces = 2;
//             this.okPercentage = this.totalCount ? Number(((this.okCount / this.totalCount) * 100).toFixed(decimalPlaces)) : 0;
//         }
//     });
// }

 
  //   Filterdate() {
  //     const formattedStartDate = moment()
  //     .year(this.startDate.year)
  //     .month(this.startDate.month - 1) // moment months are 0-based
  //     .date(this.startDate.day)
  //     .format('YYYY-MM-DD'); // Adjust format as needed

  //   const formattedEndDate = moment()
  //     .year(this.endDate.year)
  //     .month(this.endDate.month - 1)
  //     .date(this.endDate.day)
  //     .format('YYYY-MM-DD');

  //   this.getwebsitelogsstarttoend(formattedStartDate, formattedEndDate, this.websiteId);
  // }
  
  // getwebsitelogsstarttoend(startDate: string, endDate: string, websiteId: number) {
  //   this.websiteService.getwebsitelogsstarttoend(startDate, endDate, websiteId).subscribe(response => {
  //     if (response.status === "ok") {
  //       this.websitefilterlog = response.data;
  //      // console.log(this.websitefilterlog);
  
  //       this.filterok = this.websitefilterlog.filter(log => log.statusCode === 'OK').length;
  //       this.filtertotalcount = this.websitefilterlog.length;
  //       const decimalPlaces = 2;
  //       this.filterpersontage = this.totalCounts ? Number(((this.filterok / this.filtertotalcount) * 100).toFixed(decimalPlaces)) : 0;
        
  //     }
  //   });
  // }
  
//   getwebsite30dayslogs(Id) {
//     this.websiteService.getWebsite30daysLog(Id).subscribe(response => {
//         if (response.status === "ok") {
//             this.website30dayslog = response.data;
//             this.ok = this.website30dayslog.filter(log => log.statusCode === 'OK').length;
//             this.totalCounts = this.website30dayslog.length;
//             const decimalPlaces = 2;
//             this.Percentage = this.totalCounts ? 
//                 Number(((this.ok / this.totalCounts) * 100).toFixed(decimalPlaces)) : 0;
//         }
//     });
// }





  getWebsiteLogs(Id) {
    
    this.websiteService.getWebsiteLog(Id).subscribe(response => {
     
      //  console.log('Response Data:', response.data);
    
        if (response.status === "ok") {
         
        
            this.websiteLogs = response.data;
            
        }   
      }, error => {
        console.error('An error occurred:', error);
      });
  }
  
  getwebsiteUrlLogs(id)
{

  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 10,
    
    dom: 'frtlip',
    order: [[3, 'desc']],
   // processing: true,
    "destroy": true,
    columnDefs: [
      { width: "20%", targets: 0 },
      { width: "15%", targets: 1 },
      { width: "45%", targets: 2 },
      { width: "20%", targets: 3 },
      
    ]
  };
  this.websiteService.geturlLog(id).subscribe(response => {
    if (response.status == "ok") {
      if (response.data.length > 0) {
        this.webUrlLogs = response.data;
        console.log("this is "+this.websiteLogs.isActive);
         this.isSwitchActive = this.websiteLogs.isActive === true;
        console.log(this.webUrlLogs);
        this.rerender();
      }
    }

  }, error => {
    if (error.status == 401) { }
  });
}
    
    
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  calculateDateDifference(startDate: string): string {
    const start = new Date(startDate);
    const end = new Date(); // Current date
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();
    if (days < 0) {
      months--;
      days += this.daysInMonth(end.getMonth() - 1, end.getFullYear());
    }
    if (months < 0) {
      years--;
      months += 12;
    }
    return `${years}y ${months}m ${days}d`;
  }
  
  private daysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
  }
  

  // calculateTimeDifference(lastCheck: string): string {
  //   const lastCheckDate = new Date(lastCheck);
  //   const currentDate = new Date();

  //   const diffInMs = currentDate.getTime() - lastCheckDate.getTime();

  //   const diffInMinutes = Math.floor(diffInMs / 60000);
  //   const diffInHours = Math.floor(diffInMinutes / 60);
  //   const diffInDays = Math.floor(diffInHours / 24);

  //   const minutes = diffInMinutes % 60;
  //   const hours = diffInHours % 24;
  //   const days = diffInDays;

  //   let difference = '';
  //   if (days > 0) {
  //     difference += `${days} day${days !== 1 ? 's' : ''} `;
  //   }
  //   if (hours > 0) {
  //     difference += `${hours} hour${hours !== 1 ? 's' : ''} `;
  //   }
  //   if (minutes > 0) {
  //     difference += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
  //   }

  //   return difference.trim() || 'just now';
  // }

  // createChart(labels: string[], data: { x: string, y: number }[]): void {
  //   const ctx = document.getElementById('myChart') as HTMLCanvasElement | null;
  
  //   if (ctx) {
  //     if (this.chart) {
  //       this.chart.destroy(); // Destroy existing chart before creating a new one
  //     }
  
  //     this.chart = new Chart(ctx.getContext('2d')!, {
  //       type: 'line', // Specify line chart type
  //       data: {
  //         labels: this.getLabels(), 
  //         datasets: [{
  //           label: 'Load Time',
  //           data: data, // Use the data directly
  //           borderColor: 'black', // Line color
  //           borderWidth: 2, // Line width
  //           pointRadius: 0, // Hide points
  //           fill: false // No fill under the line
  //         }]
  //       },
  //       options: {
  //         responsive: true,
  //         plugins: {
  //           legend: {
  //             display: true // Show legend if needed
  //           },
  //           tooltip: {
  //             callbacks: {
  //               label: function(context) {
  //                 // Return a formatted string for the tooltip
  //                 const date = new Date(context.raw.x); // Convert x value to Date
  //                 const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  //                 return `Date: ${formattedDate}, Value: ${context.raw.y}`;
  //               }
  //             }
  //           }
  //         },
  //         scales: {
  //           x: {
  //             type: 'linear', // Use linear scale instead of time
  //             position: 'bottom',
  //             grid: {
  //               display: false // Hide vertical grid lines
  //             },
  //             ticks: {
  //               display: false // Hide x-axis labels
  //             },
  //             title: {
  //               display: false // Hide x-axis title
  //             }
  //           },
  //           y: {
  //             // suggestedMin: Math.min(...data.map(d => d.y)) - 1, // Adjusted to fit all values
  //             // suggestedMax: Math.max(...data.map(d => d.y)) + 1, // Adjusted to fit all values
  //             grid: {
  //               display: false // Hide horizontal grid lines
  //             },
  //             ticks: {
  //               display: true, // Show y-axis labels
  //               callback: function(value) {
  //                 return value; // Display values normally
  //               }
  //             },
  //             title: {
  //               display: false // Hide y-axis title
  //             }
  //           }
  //         }
  //       }
  //     });
  //   } else {
  //     console.error('Canvas element with id "myChart" not found.');
  //   }
  // }
  
  // getwebsite365dayslogs(Id: number): void {
  //   this.websiteService.getWebsite365daysLog(Id).subscribe(response => {
  //     if (response.status === "ok") {
  //       this.website365dayslog = response.data;
  //       this.ok365 = this.website365dayslog.filter(log => log.statusCode === 'OK').length;
  //       this.totalCounts365 = this.website365dayslog.length;
  
  //       const labels: string[] = [];
  //       const data: { x: string, y: number }[] = [];
  
  //       this.website365dayslog.forEach(log => {
  //         labels.push(new Date(log.logDate).toISOString()); // Format as ISO 8601
  //         data.push({ x: new Date(log.logDate).toISOString(), y: log.loadTime });
  //       });
  
  //       // Call createChart with the processed labels and data
  //       this.createChart(labels, data);
  
  //       const decimalPlaces = 2;
  //       this.Percentage365 = this.totalCounts365 ? 
  //           Number(((this.ok365 / this.totalCounts365) * 100).toFixed(decimalPlaces)) : 0;
  //     }
  //   });
  // }
  // updateChart(): void {
  //   if (this.chart) {
  //     const labels = this.getLabels();
  //     const data = this.website365dayslog.map(log => ({
  //       x: new Date(log.logDate).toISOString(),
  //       y: log.loadTime
  //     }));
  //     this.createChart(labels, data);
  //   }
  // }
      
  // getLabels(): string[] {
  //   const labels: string[] = [];
  //   const now = new Date();
  //   let startDate = new Date();
  
  //   switch (this.currentTimeRange) {
  //     case '7d':
  //       startDate.setDate(now.getDate() - 7);
  //       break;
  //     case '30d':
  //       startDate.setDate(now.getDate() - 30);
  //       break;
  //     case '24h':
  //       startDate.setHours(now.getHours() - 24);
  //       break;
  //     case '365d':
  //       startDate.setFullYear(now.getFullYear() - 1);
  //       break;
  //   }
  
  //   while (startDate <= now) {
  //     labels.push(startDate.toISOString()); // Format as ISO 8601
  //     if (this.currentTimeRange === '24h') {
  //       startDate.setHours(startDate.getHours() + 1); // Hourly labels for 24 hours
  //     } else {
  //       startDate.setDate(startDate.getDate() + 1); // Daily labels for other ranges
  //     }
  //   }
  
  //   return labels;
  // }

  onSwitchToggle(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.isSwitchActive = isChecked;

    // Make a service call to update the status
    this.websiteService.checkStatus(this.websiteId).subscribe(response => {
      if (response.status === "ok") {
        if (response.data.length > 0) {
          // Handle response if needed
          console.log('Status updated successfully');
        }
      }
    }, error => {
      if (error.status === 401) {
        // Handle unauthorized error
        console.error('Unauthorized access');
      }
    });
  }
  updateLabel(): void {
    this.switchLabel = this.isSwitchActive ? 'Active' : 'Active';
  }

  confirmDelete(id) {
    this.websiteId = id;
    $("#deleteModal").modal("show");
  }
  deleteWebsite() {
    this.websiteService.deleteWebsite(this.websiteId).subscribe(res => {
      if (res.status == "ok") {
        for (var i = 0; i < this.websiteList.length; i++) {
          if (this.websiteList[i].id == this.websiteId) {
            this.websiteList.splice(i, 1);
          }
        }
        this.rerender();
        this.noti.notification("Success", "Website Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Cannot Delete This Website.", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });

    $("#deleteModal").modal("hide");
  }

  
}




