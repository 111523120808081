import { NotificationService } from 'src/services/notification.service';
import { UserService } from 'src/services/user.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { data } from 'jquery';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // username: string = '';
  // loginHeading: string = 'Welcome to';
  // loginMainHeading: string = 'Login';
  // loginTwoHeading: string = 'Enabling Systems Portal';
  // loginSubHeading: string = 'Please enter your login details and continue';
  password: string = '';
  email: string = '';
  passType = 'password';
  code: string = '';
  code1: string = '';
  code2: string = '';
  code3: string = '';
  code4: string = '';
  code5: string = '';
  code6: string = '';
  isAuthenticatedUser: boolean = false;
  rememberMe = true;
  isSubmit: boolean = false;
  constructor(
    private appService: AppService,
    private userService: UserService,
    private noti: NotificationService,
    private router: Router,
    private cookieService: CookieService,
 
    
  ) { }

  ngOnInit():void {
    // document
    $('#password,#email').on("keyup", (event) => {
      if (event.keyCode == 13) {
        // alert();
        this.inputValidation();
      }
    });
    //  this.checkCookies();
  }


  inputValidation() {
    var isValidEmail = false;
    var isValidPassword = false;
    if (!!this.email) {
      isValidEmail = true;
      $('.username-input').removeClass('has-error');
    } else {
      isValidEmail = false;
      $('.username-input').addClass('has-error');
    }
    if (!!this.password) {
      isValidPassword = true;
      $('.password-input').removeClass('has-error');
    } else {
      isValidPassword = false;
      $('.password-input').addClass('has-error');
    }
    if (isValidPassword && isValidEmail) {
      this.login();
    } else {
      //Invalid Credentials
      $(".submit-button").prop('disabled', false);
      this.noti.notification("Error", "Invalid Credentials.", "danger");
    }
  }

  // checkCookies() {
  //   const encryptedCookie = this.cookieService.get('_encoo');

  //   if (encryptedCookie) {
  //     try {
      
  //       const [encryptedEmail, encryptedPassword] = encryptedCookie.split(':');
        
        
  //       const decryptedEmail = this.userService.decrypt(encryptedEmail);
  //       const decryptedPassword = this.userService.decrypt(encryptedPassword);

       
  //       this.email = decryptedEmail;
  //       this.password = decryptedPassword;
  //     } catch (error) {
  //       console.error('Error parsing or decrypting cookie data:', error);
  //     }
  //   }
  // }


  login() {
    $(".submit-button").prop('disabled', true);
    let _self = this;
    var user = {
      Email: '',
      Password: ''
    };
    user.Email = this.email;
    user.Password = this.password;
    this.userService.getuserAuthentication(user).subscribe(res => {
      if (res.status == "ok") {
        if (res.data) {

          var data=res.data.result;
          var accessLevel  = data.accessLevel;
        
           if (accessLevel === 'admin' || accessLevel === 'super_admin') {

            if (this.rememberMe) {
           
                      const encryptedEmail =this.userService.encrypt(user.Email);
                      const encryptedPassword =this.userService.encrypt (user.Password);
                      const expirationDate = new Date();
                      expirationDate.setDate(expirationDate.getDate() + 7); 
            this.cookieService.set('_encoo', `${user.Email}:${encryptedPassword}`, expirationDate);
          }
          }

          
          if (user.Email == "root@enabling.systems") {
            this.isAuthenticatedUser = true;
            // this.loginTwoHeading = "Enabling Systems Portal is protected by";
            // this.loginMainHeading = "Authentication";
            var _self = this;
            setTimeout(() => {
              _self.initInput();
              $("#code1").focus();
            }, 200);

          } else {
            
            this.code = "000000";
            this.verifyCode();
            }
          
      }
      } else if (res.status == "error") { 
        this.noti.notification("Error", res.data, "danger");
        $(".submit-button").prop('disabled', false);
      }
    });
  }

  initInput() {
    var container = <HTMLElement>document.getElementsByClassName("input-group")[0];
    container.addEventListener("keyup", function (e) {
      var target: any;
      target = e.srcElement;
      var maxLength = parseInt(target.attributes["maxlength"].value, 10);
      var myLength = target.value.length;
      if (e.code == "Backspace" && e.key == "Backspace") {
        if (myLength <= maxLength) {
          var next = target;
          while (next = next.previousElementSibling) {
            if (next == null) {
              break;
            }

            if (next.tagName.toLowerCase() == "input") {
              next.focus();
              break;
            }
          }
        }
      } else {
        if (myLength >= maxLength) {
          var next = target;
          while (next = next.nextElementSibling) {
            if (next == null) {
              break;
            }
            if (next.tagName.toLowerCase() == "input") {
              next.focus();
              break;
            }
          }
        }
      }
    });
  }
  verifyCode() {
    //this.isSubmit = true;
    if (this.email == "root@enabling.systems") {
      this.code = this.code1 + this.code2 + this.code3 + this.code4 + this.code5 + this.code6;
      if (!(!!this.code) || this.code.length != 6) {
        this.noti.notification("Error", "Please enter a valid code", "danger");
        this.isSubmit = false;
        return;
      }
      this.userService.getuserVerification(this.email, this.code).subscribe(res => {
        //{status: "ok", data: "{"status":"ok","data":"not_verfied"}"}
        if (res.status == "ok") {
          if (res.data != null) {
            localStorage.setItem('isAuthenticated', 'true')
            this.appService.isAuthenticated.emit({
              action: "isAuthenticated",
              data: true
            });

            this.appService.loginUser.emit({
              action: "update",
              data: res.data
            });
            this.userService.updateLastLogin(res.data.userId).subscribe(res => { });
            this.router.navigateByUrl('/dashboard');
          } 
          else if (res.status == "error") {
            this.noti.notification("Error", "Please enter a valid code", "danger");
            $(".submit-button").prop('disabled', false);
          }
        } else if (res.status == "error") {
          this.noti.notification("Error", "Please enter a valid code", "danger");
          $(".submit-button").prop('disabled', false);
        }
        this.isSubmit = false;
      }, (error) => {
        this.isSubmit = false;
        console.error("Error logging", error);
      });
    } else {
      this.userService.getuserVerification(this.email, this.code).subscribe(res => {
        //{status: "ok", data: "{"status":"ok","data":"not_verfied"}"}
        if (res.status == "ok") {
          if (res.data != null) {
            // console.log("get user verification:",res);
            
            localStorage.setItem('isAuthenticated', 'true')
            this.appService.isAuthenticated.emit({
              action: "isAuthenticated",
              data: true
            });

            this.appService.loginUser.emit({
              action: "update",
              data: res.data
            });
            this.userService.updateLastLogin(res.data.userId).subscribe(res => { });
            this.router.navigateByUrl('/dashboard');
          } else if (res.status == "error") {
            this.noti.notification("Error", "Please enter a valid code", "danger");
            $(".submit-button").prop('disabled', false);
          }
        } else if (res.status == "error") {
          this.noti.notification("Error", "Please enter a valid code", "danger");
          $(".submit-button").prop('disabled', false);
        }
        this.isSubmit = false;
      }, (error) => {
        this.isSubmit = false;
        console.error("Error logging", error);
      });
    }
  }

  togglePassword() {
    if (this.passType == 'password') {
      this.passType = 'text';
    } else {
      this.passType = 'password';
    }
  }
}
