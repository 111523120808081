import { NotificationService } from 'src/services/notification.service';
import { DashboardService } from 'src/services/dashboard.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TasksService } from 'src/services/tasks.service';
import { UserService } from 'src/services/user.service';
import { ValiService } from 'src/services/vali.service';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
declare var $;

@Component({
  selector: 'app-vali-logs',
  templateUrl: './vali-logs.component.html',
  styleUrls: ['./vali-logs.component.css']
})
export class ValiLogsComponent implements OnInit {
  public valiLogs: any = [];
  public logDetail: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  public isRequesting: boolean = false;
  public year: any = moment().format('YYYY');


  constructor(
    private appService: AppService,
    private userService: UserService,
    private valiService: ValiService,
    private noti: NotificationService,
    private tasksService: TasksService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Vali Logs"
    });
    this.getLogs();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getLogs() {
    this.isRequesting = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      ordering: false,
      //order: [[4, 'desc']],
      dom: 'frtlip',
      processing: true,
      "destroy": true,
      "columnDefs": [
        { "targets": 0 },
        { "targets": 1 },
        { "targets": 2 },
        { "targets": 3, "orderable": false },
        { "targets": 4, "orderable": false }
      ]
    };
    this.valiService.getLogs().subscribe(response => {


      if (response.status == "ok") {
        this.valiLogs = response.data;
        this.valiLogs.sort(function (a, b) {
          if (moment(a.codeGeneratedAt).format() > moment(b.codeGeneratedAt).format()) { return -1; }
          if (moment(a.codeGeneratedAt).format() < moment(b.codeGeneratedAt).format()) { return 1; }
          return 0;
        });
        this.isRequesting = false;
        this.rerender();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  viewDetail(data) {
    this.logDetail = data;
    $("#logDetail").modal("show");
  }
}
