import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "../app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable({
  providedIn: 'root'
})
export class AutomatedTestingService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) { }

  getTestingList(projectId, pageNumber, pageSize, sortBy, sortOrder, searchText) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/testing/getalltestdt?projectId=${projectId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchText=${searchText}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }

  getTestingById(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/testing/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }

  deleteTest(id){
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/testing/' + id;
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }

}
