import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { UserService } from 'src/services/user.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public user: any = '';
  public title: string = "";
  constructor(
    private userService: UserService,
    private appService: AppService,
    private router: Router,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    this.appService.headerTitle.subscribe((resp) => {
      setTimeout(() => {
        this.title = resp.data;
      },0);
    });

    this.user = JSON.parse(localStorage.getItem('user'));

    $(".dropdown-toggle").click(function () {
      $("#dropdown-arrow-toggle").toggleClass("select-toggle-i");
    });
  }
  signOut() {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
     this.cookieService.delete('_encoo');
    
    this.appService.isAuthenticated.emit({
      action: "isAuthenticated",
      data: false
    });
    this.router.navigateByUrl('/login');
  }

  
  
}
