import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

declare var $;

@Pipe({ name: 'replace' })
export class StrReplacePipe implements PipeTransform {
    transform(value: string, strToReplace: string, replacementStr: string): string {

        if(!value || ! strToReplace || ! replacementStr)
        {
          return value;
        }
    
     return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
      }
}