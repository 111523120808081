import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';
//import 'rxjs/add/operator/map';

@Injectable()

export class WebsiteService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) {
  }

  // getAllStatus(){
  //   let options = this.authService.setHeaderAuth();
  //   let request = this.baseApiUrl + 'api/Websites/getAllStatus';
  //   return this.http.get(request, options).pipe(map(
  //    response=> {
       
  //       return response.json()
  //     }
  //   ),
  //     catchError(err => {
  //       return "error";
  //     }
  //     ));
  // }

  getAllStatus() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/getAllStatus';
  
    return this.http.get(request, options).pipe(
      map((response: any) => {
        // Parse the response body if it's in the _body property
        let parsedResponse = JSON.parse(response._body);
        return parsedResponse;
      }),
      catchError(err => {
        console.error("Error occurred:", err);
        return "error";
      })
    );
  }

  getWebsiteList() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites';
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  addWebsite(website) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites';
    return this.http.post(request, website, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getWebsite(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  updateWebsite(website) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/' + website.id;
    return this.http.put(request, website, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  deleteWebsite(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/' + id;
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  //start web Logs methods
  getWebsiteLogs(pageNumber, pageSize, sortOrder, sortBy, search, websiteId, filterLog) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/Websites/websitelogs?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}&websiteId=${websiteId}&filterLog=${filterLog}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  addWebsiteLog(Log) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/websitelogs';
    return this.http.post(request, Log, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  geturlLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/websiteUrllogs/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getWebsiteLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/websitelogs/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getWebsite30daysLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/website30dayslogs/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

checkStatus(websiteId){
  let options = this.authService.setHeaderAuth();
  const request = this.baseApiUrl+'api/Websites/checkStatus/'+ websiteId ;
  return this.http.put(request, options).pipe(
    map(response => {
      
      return response.json();
    }),
    catchError(err => {
      
      console.error('Error occurred:', err);
      return ('error');
    })
  );
}

  getwebsitelogsstarttoend(startDate: string, endDate: string, websiteid: number) {
    let options = this.authService.setHeaderAuth();
    
    // Construct the URL with properly formatted query parameters
    const request = `${this.baseApiUrl}api/Websites/getwebsitelogsstarttoend?startdate=${(startDate)}&enddate=${(endDate)}&id=${websiteid}`;

    // Perform the HTTP GET request
    return this.http.get(request, options).pipe(
      map(response => {
        // Directly return the response object
        return response.json();
      }),
      catchError(err => {
        // Handle errors and return an observable with error message
        console.error('Error occurred:', err);
        return ('error');
      })
    );
  }

  getWebsite365daysLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/website365dayslogs/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getWebsite7daysLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/website7dayslogs/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  updateWebsiteLog(Log) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/websitelogs/' + Log.id;
    return this.http.put(request, Log, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  deleteWebsiteLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Websites/websitelogs/' + id;
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
}
