import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class AssetsService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
) {
  }

  addAsset(asset) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Assets';
    return this.http.post(request, asset, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  getAssets() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Assets';
    return this.http.get(request,options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  getAsset(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Assets/' + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  getassetscount() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Assets/getassetscount';
    return this.http.get(request,options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  updateAsset(asset) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Assets/' + asset.id;
    return this.http.put(request, asset, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  deleteAsset(id){
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/Assets/' + id;
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
}
