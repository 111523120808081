import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';
import { HttpErrorResponse } from '@angular/common/http/src/response';

@Injectable()

export class AppBuildService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
) {
  }

  uploadBuild(formData) {
    // public URL: string = this.baseUrl + "api/FileUpload/useruploadfile?userId=";
    // this.URL = this.URL + this.userId;
    // return this.http.post(this.baseApiUrl + 'api/AppBuild/fileupload', formData)
    //   .subscribe(respo => {
    //     return respo;
    //   }, (err: HttpErrorResponse) => {
    //     console.error(err);
    //     return err;
    //   });


      // let options = this.authService.setHeaderAuth();
      // let request = this.baseApiUrl + 'api/AppBuild/fileupload';
      // return this.http.post(request, formData, options).pipe(map(
      //   response => {
      //     return response.json()
      //   }
      // ),
      //   catchError(err => {
      //     return "error";
      //   }
      // ));


  }

  addBuild(log) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/AppBuild';
    return this.http.post(request, log, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }

  getBuildLogs(pageNumber, pageSize, sortOrder, sortBy, search) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/AppBuild?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}`;
    return this.http.get(request,options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
}
