import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { AppBuildService } from 'src/services/appBuild.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
declare var $;

@Component({
  selector: 'app-build-history',
  templateUrl: './build-history.component.html',
  styleUrls: ['./build-history.component.css']
})
export class BuildHistoryComponent implements OnInit {
  public logsList: any = [];
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  public pageNumber = 1;
  public pageSize = 25;
  public sortOrder = "";
  public sortBy = "";
  public search = "";

  constructor(
    private appService: AppService,
    private appBuildService: AppBuildService,
    private router: Router
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "App Builds"
    });
    $('[data-toggle="tooltip"]').tooltip();
    this.getLogs();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getLogs() {
    this.dtOptions = {
      dom: 'frtlip',
      pagingType: 'full_numbers',
      pageLength: this.pageSize,
      order: [[6, 'desc']],
      "columnDefs": [
        { "width": "20%", "targets": 0 },
        { "width": "15%", "targets": 1 },
        { "width": "10%", "targets": 2 },
        { "width": "10%", "targets": 3 },
        { "width": "25%", "targets": 4 },
        { "width": "7%", "targets": 5 },
        { "width": "13%", "targets": 6 }
      ],
      processing: false,
      serverSide: true,
      // orderMulti: false,
      ajax: (dTParameters: any, callback) => {
        this.pageSize = dTParameters['length'];
        this.pageNumber = dTParameters['start'] / this.pageSize + 1;
        this.sortOrder = dTParameters['order'][0].dir;
        this.search = dTParameters['search']['value'];
        let column = dTParameters['order'][0].column;
        switch (column) {
          case 0:
            this.sortBy = "AppName";
            break;
          case 1:
            this.sortBy = "AppType";
            break;
          case 6:
            this.sortBy = "DateAdded";
            break;
          default:
            this.sortBy = "DateAdded";
        }

        this.appBuildService.getBuildLogs(this.pageNumber, this.pageSize, this.sortOrder, this.sortBy, this.search).subscribe(response => {
          if (response != null) {
            if (response.status == "ok") {
              // this.logsList = response.data.value.items;
              // callback({
              //   recordsTotal: response.data.value.totalRecords,
              //   recordsFiltered: response.data.value.totalRecords,
              //   data: []
              // });
              $(".custom-loader").css("display", "none");
              this.logsList = response.data.value;
              let totalCount = 0;
              if (response.data.value.length > 0) {
                totalCount = response.data.value[0].totalRecords;
              } else {
                totalCount = 0;
              }
              if (this.logsList.length != 0) {
                setTimeout(() => {
                  $(".dataTables_empty").css("display", "none");
                }, 200);
              }
              callback({
                recordsTotal: totalCount,
                recordsFiltered: totalCount,
                data: []
              });
            }
          }
        });
      },
      columns: [
        { "data": 'appName' },
        { "data": 'appType' },
        { "data": 'version', "orderable": false },
        { "data": 'build', "orderable": false },
        { "data": 'description', "orderable": false },
        { "data": 'url', "orderable": false },
        { "data": 'dateAdded' }
      ]
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  copyUrl(text) {
    // 1) Add the text to the DOM (usually achieved with a hidden input field)
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = text;

    // 2) Select the text
    input.focus();
    input.select();

    // 3) Copy text to clipboard
    const isSuccessful = document.execCommand('copy');

    // 4) Catch errors
    if (!isSuccessful) {
      console.error('Failed to copy text.');
    }
  }
}
