import { Component, OnInit, ViewChild } from '@angular/core';
import { CodeCommitService } from 'src/services/codecommit.service';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
declare var $;
@Component({
  selector: 'app-repository-list',
  templateUrl: './repository-list.component.html',
  styleUrls: ['./repository-list.component.css']
})
export class RepositoryListComponent implements OnInit {
  public isSelected: boolean = false;
  public loginUser: any = '';
  public usersLastList = [];
  public year: any = moment().format('YYYY');
  public repositoriesDetail: any = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  constructor(
    private appService: AppService,
    private codeCommitService: CodeCommitService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "CodeCommit"
    });

    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.getRepositoriesDetails();
    this.getUsersLastModified();
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getRepositoriesDetails() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      dom: 'frtlip',
      ordering: false,
      // order: [[2, 'desc']],
      processing: true,
      "destroy": true,
      "columnDefs": [
        { "width": "25%", "orderable": false, "targets": 0 },
        { "orderable": false, "targets": 1 },
        { "orderable": false, "targets": 2 },
        { "width": "12%", "orderable": false, "targets": 3 },
       
      ]
    };
    this.codeCommitService.getRepositoriesDetail().subscribe(response => {
      if (response.status == "ok") {
        $(".custom-loader-aws").css("display", "none");
        $(".loader-response-aws").css("display", "block");
        this.repositoriesDetail = response.data.value;
        console.log(this.repositoriesDetail);
        this.rerender();
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  getUsersLastModified() {
    this.codeCommitService.getLastModified().subscribe((response) => {
      if (response.status == "ok") {
        this.usersLastList = response.data;
        // for (var i = 0; i < this.usersLastList.length; i++) {
        //   this.usersLastList[i].userName = this.usersLastList[i].userName.split(" ")[0];
        // }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  toggleCollapse() {
    if (this.isSelected) {
      this.isSelected = false;
    } else {
      this.isSelected = true;
    }
  }
}
