import { NotificationService } from 'src/services/notification.service';
import { ProjectService } from 'src/services/projects.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/services/constants.service';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ProjectListComponent implements OnInit {
  public loginUser: any = '';
  public projectList: any = [];
  public projectId = 0;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public year: any = moment().format('YYYY');


  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  constructor(
    private appService: AppService,
    private projectService: ProjectService,
    private noti: NotificationService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Projects"
    });
      this.loginUser = JSON.parse(localStorage.getItem('user'));
      this.getProjects();
      $('[data-toggle="tooltip"]').tooltip();  
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getProjects() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: 'frtlip',
      pageLength: 25,
      order: [[4, 'desc']],
      processing: true,
      "destroy": true,
      "columnDefs": [
        { "orderable": false, "width": "3%", "targets": 0 },
        { "width": "20%", "targets": 1 },
        { "width": "38%", "orderable": false, "targets": 2 },
        { "width": "15%", "targets": 3 },
        { "width": "10%", "targets": 4 },
        { "width": "15%", "targets": 5 },
      ]
    };
    this.projectService.getProjectList().subscribe(response => {
      if (response.status == "ok") {
        if (response.data.value.length > 0) {
          Constants.projectList = this.projectList = response.data.value;
          this.rerender();
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }
  confirmDelete(id) {
    this.projectId = id;
    $("#deleteModal").modal("show");
  }
  deleteProject() {
    this.projectService.deleteProject(this.projectId).subscribe(res => {
      if (res.status == "ok") {
        for (var i = 0; i < this.projectList.length; i++) {
          if (this.projectList[i].id == this.projectId) {
            this.projectList.splice(i, 1);
          }
        }
        this.rerender();
        this.noti.notification("Success", "Project Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Cannot Delete This Project.", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });

    $("#deleteModal").modal("hide");
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
