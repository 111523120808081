import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';

@Injectable()

export class AuthenticateService {
  constructor(private http: Http) {
  }

  setHeaderAuth() {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = 'Bearer ' + user.token;
    let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': "*", 'Authorization': token });
    let options = new RequestOptions({ headers: headers });
    return options;
  }

  formDataHeaderAuth() {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = 'Bearer ' + user.token;
    let headers = new Headers({ 'Access-Control-Allow-Origin': "*", 'Authorization': token });
    let options = new RequestOptions({ headers: headers });
    return options;
  }
}
