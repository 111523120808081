import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { UserService } from 'src/services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from 'src/services/notification.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
 encryptedEmail:string="";
 encryptedPassword : string="";
  Email="";
  Password="";
  UserId;
  DataEmail;
  isSubmit: boolean = false;
  public code = "000000";
  constructor(
    private appService: AppService,
    private userService: UserService,
    private router: Router,
     private cookieService: CookieService,
     private noti: NotificationService,
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Dashboard"
    });
   
    let authenticated = Boolean(localStorage.getItem('isAuthenticated'));
    if(authenticated == false){
    let getcookies = this.cookieService.get('_encoo');
      if (getcookies) {
        [this.encryptedEmail,this.encryptedPassword] = getcookies.split(':');
        this.Email = this.encryptedEmail;
        this.Password = this.encryptedPassword;
        this.userService.getuserbyemail(this.Email).subscribe(res => {
              if (res.status === "ok") {
                  if (res.data) {
                      var data = res.data.result;
                      this.UserId = data.userId;
                      this.DataEmail= data.email;
                      this.verifyCode();
                  }
              }
            });
      }
    }
  if(this.Email == this.DataEmail ){
    let user = JSON.parse(localStorage.getItem('user'));
    let authenticated = Boolean(localStorage.getItem('isAuthenticated'));
    this.subscribtion();
    if (authenticated) {
      this.userService.updateLastLogin(user.userId).subscribe((res) => {
        this.appService.loginUser.emit({
          action: "update",
          data: user
        });
      }, error => {
        if (error.status == 401) {
          this.appService.signOut();
        }
      });
    }
  }
  else{
    let user = JSON.parse(localStorage.getItem('user'));
    let authenticated = Boolean(localStorage.getItem('isAuthenticated'));
    this.subscribtion();
    if (authenticated) {
      this.userService.updateLastLogin(user.userId).subscribe((res) => {
        this.appService.loginUser.emit({
          action: "update",
          data: user
        });
      }, error => {
        if (error.status == 401) {
          this.appService.signOut();
        }
      });
    }
  }

  this.router.events.forEach((e) => {
      if (e instanceof NavigationEnd) {
        let user = JSON.parse(localStorage.getItem('user'));
    let authenticated = Boolean(localStorage.getItem('isAuthenticated'));
    
        authenticated = Boolean(localStorage.getItem('isAuthenticated'));
        const cookies = this.cookieService.get('_encoo');


        if (e.url != "/login") {
          if (!!user) {
            this.userService.getuserStatus(user.userId).subscribe((res) => {
              if (res.status == "error") {
                this.appService.signOut();
              }
            }, error => {
              if (error.status == 401) {
                this.appService.signOut();
              }
            });
          }
          if (authenticated) {
            this.appService.isAuthenticated.emit({
              action: "isAuthenticated",
              data: authenticated
            });
          } else {
            this.router.navigateByUrl('/login');
          }
        } else {
          if (authenticated ) {
            this.appService.isAuthenticated.emit({
              action: "isAuthenticated",
              data: authenticated
            });
            this.router.navigateByUrl('/dashboard');
          }
        }
      }
    });
  }

  verifyCode() {
    this.isSubmit = true;
    
      this.userService.getuserVerification(this.Email, this.code).subscribe(res => {
        if (res.status == "ok") {
          if (res.data != null) {
            localStorage.setItem('isAuthenticated', 'true')
            this.appService.isAuthenticated.emit({
              action: "isAuthenticated",
              data: true
            });
            this.appService.loginUser.emit({
              action: "update",
              data: res.data
            });
            this.userService.updateLastLogin(res.data.userId).subscribe(res => { });
            this.router.navigateByUrl('/dashboard');
          } else if (res.status == "error") {
            this.noti.notification("Error", "Please enter a valid code", "danger");
            $(".submit-button").prop('disabled', false);
          }
        } else if (res.status == "error") {
          this.noti.notification("Error", "Please enter a valid code", "danger");
          $(".submit-button").prop('disabled', false);
        }
        this.isSubmit = false;
      }, (error) => {
        this.isSubmit = false;
        console.error("Error logging", error);
      });
    }

  subscribtion() {
    this.appService.isAuthenticated.subscribe((resp) => {
      if (resp.action == "isAuthenticated") this.isAuthenticated = resp.data;
    });
    this.appService.loginUser.subscribe((resp) => {
      if (resp.action == "update") {
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(resp.data));
      }
      else if (resp.action == "remove") {
        localStorage.removeItem('user');
      }
    });
  }
}
