import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

declare var $;

@Pipe({ name: 'localDate',
   
 })
export class LocalDatePipe implements PipeTransform {
    transform(value: string, format: string): string {
        return moment.utc(value).local().format(format);
        //    return moment(value).fromNow();
    }
}


@Pipe({
  name: 'localDate1'
})
export class LocalDate1Pipe implements PipeTransform {

  transform(value: Date | string, format: string = 'MM/DD/YYYY hh:mm a'): string {
    if (!value) return '';

    const date = new Date(value);

    // Define month names
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  }
}
