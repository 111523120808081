import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { Constants } from '../../../services/constants.service';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from '../../../app/app.service';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AutomatedTestingService } from '../../../services/automated-testing.service';
import _ from 'lodash';
import { d } from '@angular/core/src/render3';
import { KeyValue } from '@angular/common';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class AutomatedTestingViewComponent implements OnInit {
  public loginUser: any = '';
  public test: any = [];
  public testId = 0;
  public metaData: any;
  public innerPages: any;
  public passTest: any;
  public failTest: any;
  public sampleValues: any;
  public year: any = moment().format('YYYY');
  public totalFailed: any = "";
  public pageUrl: any = "";

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  public isSampleValue: boolean = false;

  constructor(
    private appService: AppService,
    private noti: NotificationService,
    private autoTestSrv: AutomatedTestingService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Automated Testing"
    });
    this.testId = this.route.snapshot.queryParams.id;
    if (!!this.testId) {

    } else {
      this.router.navigateByUrl('/automated-testing/list');
    }
      this.loginUser = JSON.parse(localStorage.getItem('user'));
      $('[data-toggle="tooltip"]').tooltip();
      this.getTestById();
  }

  getTestById(){
    this.autoTestSrv.getTestingById(this.testId).subscribe(resp => {
          if (resp.status == "ok") {
              this.test = resp.data;
              this.metaData = JSON.parse(resp.data.metaData);
              this.pageUrl = this.metaData.page_url;
              this.totalFailed = this.metaData.total_failed;
              this.sampleValues = this.metaData.sample_values;
              this.innerPages = this.metaData.inner_data;
              this.passTest = _.filter(this.innerPages, function(o) { return o.status == 'pass'; });
              this.failTest = _.filter(this.innerPages, function(o) { return o.status == 'error'; });
              
          }
        }, error => {
          console.error(error)
          if (error.status == 401) { }
        });
  }

  showFailedTest(){
    $('#nav-pass').css("display", "none");
  }

  showPassTest(){
    $('#nav-pass').css("display", "block");
    // $('#nav-fail').css("display", "none");

  }
}
