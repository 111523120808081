import { NotificationService } from 'src/services/notification.service';
import { WebsiteService } from 'src/services/websites.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class MonitorListComponent implements OnInit {
  public loginUser: any = '';
  public websiteList: any = [];
  public websiteId = 0;
  public allStatus : any;
  isActive: boolean = true;
  activeUserText: string = 'Show InActive ';
  inactiveUserText: string =  ' Hide InActive';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public year: any = moment().format('YYYY');

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  //add website obj
  public websiteName = "";
  public websiteUrl = "";
  public websiteDescription = "";
  constructor(
    private appService: AppService,
    private websiteService: WebsiteService,
    private noti: NotificationService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "www Monitor"
    });
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.getWebsites();
    $('[data-toggle="tooltip"]').tooltip();
    $('#addWebiteModal').modal({
      backdrop: 'static',
      keyboard: false,
      show: false
    });

    this.GetAllStatus();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

 
    getExpirationStatus(sslExpiration: Date | string): string {
      const expirationDate = new Date(sslExpiration);
      const currentDate = new Date();
      const timeDifference = expirationDate.getTime() - currentDate.getTime();
      const dayDifference = timeDifference / (1000 * 3600 * 24);
  
      if (dayDifference < 0) {
        return 'expired'; // Already expired
      } else if (dayDifference <= 15) {
        return 'near'; // Expiring within 30 days
      } else {
        return 'valid'; // Valid SSL
      }
    }
  
    getArrowDirection(sslExpiration: Date | string): 'up' | 'down' {
      const status = this.getExpirationStatus(sslExpiration);
      return status === 'expired' ? 'down' : 'up';
    }
  
    getExpirationColor(sslExpiration: Date | string): string {
      const status = this.getExpirationStatus(sslExpiration);
      switch (status) {
        case 'expired':
          return 'expired'; 
        case 'near':
          return 'near'; 
        default:
          return 'green'; 
      }
    }
  
  
  

  
  // Function to format the date as 'Month Day, Year'
  formatDate(sslExpiration: Date | string): string {
    const date = new Date(sslExpiration);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthName = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${monthName} ${day}, ${year}`;
  }
  

  getWebsites() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      dom: 'frtlip',
      order: [[2, 'desc']],
     // processing: true,
      "destroy": true,
      columnDefs: [
        { width: "50%", targets: 0 },
        { width: "10%", targets: 1 },
        { width: "15%", targets: 2 },
        {width: "25%" , targets: 3},
        
      ]
    };
    this.websiteService.getWebsiteList().subscribe(response => {
      if (response.status === "ok") {
        if (response.data.length > 0) {
          // Filter out only active websites
          this.websiteList = response.data.filter(website => website.isActive);
          this.rerender();
        }
      }
   
    

    }, error => {
      if (error.status == 401) { }
    });
  }
  confirmDelete(id) {
    this.websiteId = id;
    $("#deleteModal").modal("show");
  }
  deleteWebsite() {
    this.websiteService.deleteWebsite(this.websiteId).subscribe(res => {
      if (res.status == "ok") {
        for (var i = 0; i < this.websiteList.length; i++) {
          if (this.websiteList[i].id == this.websiteId) {
            this.websiteList.splice(i, 1);
          }
        }
        this.rerender();
        this.noti.notification("Success", "Website Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Cannot Delete This Website.", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });

    $("#deleteModal").modal("hide");
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  todayDate: Date = new Date();
  openWebsiteModal() {
    this.websiteName = "";
    this.websiteUrl = "";
    $("#addWebiteModal").modal("show");
  }

  addWebsite() {
    $(".btn-submit").prop('disabled', true);
    let isValid = this.validate();
    if (isValid) {
      var website = {
        CommonName: '',
        Url: '',
       
      };
      website.CommonName = this.websiteName.trim();
      website.Url = this.websiteUrl.trim();
     if(website.CommonName != null && website.Url != null){
      //website.Description = this.websiteDescription;
      this.websiteService.addWebsite(website).subscribe(res => {
        if (res.status == "ok") {
          // this.websiteId = res.data.id;
          this.websiteName = "";
          this.websiteUrl = "";
          this.getWebsites();
          this.noti.notification("Success", "Website Added Successfully", "success");
          $("#addWebiteModal").modal("hide");
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      // this.noti.notification("Error", "Please fill all details", "danger");
      $(".btn-submit").prop('disabled', false);
    }
  }
  this.validate();
  }

  validate() {
    if (!(!!this.websiteName.trim())) {
      this.noti.notification("Error", "Please enter website name", "danger");
      return false;
    }
    
    if (!(/^https?:\/\/[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)+([\/\w .-]*)*\/?$/.test(this.websiteUrl.trim()))) {
      this.noti.notification("Error", "Please enter a valid domain name", "danger");
      return false;
    }
    
    return true;
    
}


GetAllStatus() {
  this.websiteService.getAllStatus().subscribe(
    (response: any) => {
      if (response && response.status === "ok") {
        this.allStatus = response.data; // Assuming 'data' holds the object
        console.log("Response Data:", this.allStatus);
      } else {
        console.warn("Unexpected response format:", response);
      }
    },
    error => {
      console.error("Error fetching status:", error);
      if (error.status === 401) {
        // Handle unauthorized access
      }
    }
  );
}

filterUsers(){
  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 100,
    dom: 'frtlip',
    order: [[2, 'desc']],
   // processing: true,
    "destroy": true,
    columnDefs: [
      { width: "50%", targets: 0 },
      { width: "10%", targets: 1 },
      { width: "15%", targets: 2 },
      {width: "25%" , targets: 3},
      
    ]
  };
  this.websiteService.getWebsiteList().subscribe(response => {
    if (response.status === "ok") {
      if (response.data.length > 0) {
        // Filter out only active websites 
        if(this.isActive){
        this.websiteList = response.data.filter(website => website.isActive);
        }
        else
        {
          this.websiteList = response.data.filter(website => website.isActive=== false);
        }
        this.rerender();
      }
    }
 
  

  }, error => {
    if (error.status == 401) { }
  });
}
toggleUserStatus() {
  this.isActive = !this.isActive;
  this.filterUsers();
}
getButtonText() {
  return this.isActive ? this.activeUserText : this.inactiveUserText;
}
}