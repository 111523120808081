import { BaltoroService } from 'src/services/baltoro.service';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { GlobalVariable } from "src/app/globals";
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
declare var $;

@Component({
  selector: 'app-baltoro-dashboard',
  templateUrl: './baltoro-dashboard.component.html',
  styleUrls: ['./baltoro-dashboard.component.css']
})
export class BaltoroDashboardComponent implements OnInit {
  public totalBucketSize: any;
  public allRecords = [];
  public backupList: any ;
  size: any[] = [];
  uploadSize : any[]=[];
  downloadSize:any[]=[];
  public downloadList: any ;
  public uploadRepository : any;
  public downloadRepository : any;
  public combineList:any;
  uniqueDatabaseCount: number = 0; 
  uniqueRepositoryCount: number = 0;// Variable to store the count of unique database names
  public startDate = moment().startOf('day').format('YYYY-MM-DD h:mm a');
  public endDate = moment().endOf('day').format('YYYY-MM-DD h:mm a');
  public startQuery = moment().format('MM/DD/YYYY');
  public endQuery = moment().format('MM/DD/YYYY');
  public runAtPrevious = moment().format('YYYY-MM-DD h:mm a');
  public nextDayDate = moment().add(1, 'days').startOf('day').format('MM-DD-YYYY');
  public todayDayDate = moment().startOf('day').format('MM-DD-YYYY');
  public runAtNext = moment().format('YYYY-MM-DD h:mm a');
  public schedulerTiming;
  public dateShow = '';
  public year: any = moment().format('YYYY');
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  dtOptions4: DataTables.Settings = {};
  dtTrigger4: Subject<any> = new Subject();

  dtOptions5: DataTables.Settings = {};
  dtTrigger5: Subject<any> = new Subject();

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  constructor(
    private baltoroService: BaltoroService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Baltoro"
    });

    this.dateShow = moment(this.startDate).format('MMM DD');
    if (this.startDate == moment().startOf('day').format('YYYY-MM-DD h:mm a')) {
      $("#nextBtn").css("cursor", "not-allowed");
    }
    this.totalSum();
    this.totalUploadSum();
    this.getBucketSize();
    this.totalDownloadSum();
    this.combineLists();
    
    this.getBackup();
    this.ShowDownloadRepository();
    this.ShowDownload();
    this.ShowUploadRepository();

 
   
  }

  getBucketSize() {
    this.baltoroService.getBBSize().subscribe(res => {
      if (res.status == "ok") {
        this.totalBucketSize = res.data.toFixed(2);
      }
    }, error => {
      if (error.status == 401) { }
    });
  }
  totalDownloadSum(){
    this.baltoroService.getAllDownloadRepostory().subscribe(response => {
      if (response.status === "ok") {
        this.downloadSize = response.data || []; 
      }
    }, error => {
      if (error.status == 401) { /* Handle error */ }
    });
  }
  calculateTotalDownloadSizeInGB(): number {
    if (!this.downloadSize || this.downloadSize.length === 0) {
      return 0;
    }
    
    const totalFileSizeMBs = this.downloadSize.reduce((total, item) => {
      const fileSizeMB = parseFloat(item.size) || 0;
      return total + fileSizeMB;
    }, 0);
  
    // Convert MB to GB
    return totalFileSizeMBs / 1024;
  }

  totalUploadSum(){
    this.baltoroService.getAllUploadRepostory().subscribe(response => {
      if (response.status === "ok") {
        this.uploadSize = response.data || []; 
      }
    }, error => {
      if (error.status == 401) { /* Handle error */ }
    });
  }
  calculateTotalUploadSizeInGB(): number {
    if (!this.uploadSize || this.uploadSize.length === 0) {
      return 0;
    }
    
    const totalFileSizeMBs = this.uploadSize.reduce((total, item) => {
      const fileSizeMB = parseFloat(item.size) || 0;
      return total + fileSizeMB;
    }, 0);
  
    // Convert MB to GB
    return totalFileSizeMBs / 1024;
  }

  totalSum(){
    this.baltoroService.getAllDownload().subscribe(response => {
      if (response.status === "ok") {
        this.size = response.data || []; 
      }
    }, error => {
      if (error.status == 401) { /* Handle error */ }
    });
  }

  calculateTotalFileSizeInGB(): number {
    if (!this.size || this.size.length === 0) {
      return 0;
    }
    
    const totalFileSizeMB = this.size.reduce((total, item) => {
      const fileSizeMB = parseFloat(item.fileSize) || 0;
      return total + fileSizeMB;
    }, 0);
  
    // Convert MB to GB
    return totalFileSizeMB / 1024;
  }
  
  resetDates() {
    $(".checkmark").removeClass('error');
    $(".checkmark").removeClass('success');
    $(".checkmark").removeClass('default');
    $(".loader-response").css("display", "none");
    $(".loader-container").css("display", "table");
    this.startDate = moment().startOf('day').format('YYYY-MM-DD h:mm a');
    this.endDate = moment().endOf('day').format('YYYY-MM-DD h:mm a');
    this.dateShow = moment(this.startDate).format('MMM DD');
    if (this.startDate == moment().startOf('day').format('YYYY-MM-DD h:mm a')) {
      $("#nextBtn").css("cursor", "not-allowed");
    }
    this.runAtPrevious = moment().format('YYYY-MM-DD h:mm a');
    this.nextDayDate = moment().add(1, 'days').startOf('day').format('MM-DD-YYYY');
    this.todayDayDate = moment().startOf('day').format('MM-DD-YYYY');
    this.runAtNext = moment().format('YYYY-MM-DD h:mm a');

    this.startQuery = moment().format('MM/DD/YYYY');
    this.endQuery = moment().format('MM/DD/YYYY');
    //this.dashboardInit();
  }

  // dashboardInit() {
  //   $.ajax({
  //     type: "GET",
  //     url: "../../../assets/js/scheduler.json", //GET scheduler times url
  //     data: {},
  //     success: (data) => {
  //       this.schedulerTiming = data;
  //       $(".runAtGitBackup").text(this.todayDayDate + ' ' + data['daily'].gitBackup);
  //       $(".nextRunGitBackup").text(this.nextDayDate + ' ' + data['daily'].gitBackup);
  //       var actionType = ['gitUpload', 'dbBackup', 'dbUpload', 'dbDownload'];

  //       for (var i = 0; i < actionType.length; i++) {
  //         let iosWeek = data['weekly'][actionType[i]].iso_week;
  //         //set next run time 
  //         this.runAtPrevious = moment().isoWeekday(iosWeek).startOf('day').format('MM-DD-YYYY');

  //         $(".runAt" + actionType[i]).text(this.runAtPrevious + ' ' + data['weekly'][actionType[i]].time);
  //         //set run time
  //         this.runAtNext = moment().isoWeekday(iosWeek).startOf('day').format('YYYY-MM-DD h:mm a');

  //         if (this.startDate >= this.runAtNext) {
  //           this.runAtNext = moment().add(1, 'week').isoWeekday(iosWeek).endOf('day').format('MM-DD-YYYY');
  //         } else {
  //           this.runAtNext = moment().isoWeekday(iosWeek).endOf('day').format('MM-DD-YYYY');
  //         }
  //         $(".nextRun" + actionType[i]).text(this.runAtNext + ' ' + data['weekly'][actionType[i]].time);
  //       }
  //       // load all records
  //       this.getAllLogs();
  //     },
  //     error: (error) => {
  //       // console.error(error);
  //     }
  //   });
  // }

  // getAllLogs() {
  //   this.allRecords = [];
  //   this.baltoroService.getFilterLogs(this.startQuery, this.endQuery).subscribe(res => {
  //     if (res.status == "ok") {
  //       if (res.data.length > 0) {
  //         this.allRecords = res.data;
  //         this.getDataByGravity().then(() => {
  //           //second funtion call
  //           this.getDataByRocket().then(() => {
  //             //third funtion call
  //             this.getDataByApproachMe().then(() => {
  //               //fourth funtion call
  //               this.getDataBySkinnyShrimp().then(() => {
  //                 //hide loader
  //                 $(".loader-container").css("display", "none");
  //                 $(".loader-response").css("display", "block");
  //               }).catch(() => { });
  //             }).catch(() => { });
  //           }).catch(() => { });
  //         }).catch(() => { });
  //       } else {
  //         $("#isGravityLog").addClass('error');
  //         $("#isRocketLog").addClass('error');
  //         $("#isuploadtoGlacier").addClass('error');
  //         $(".loader-container").css("display", "none");
  //         $(".loader-response").css("display", "block");
  //         if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //           $("#isdeploycodedownloadGlacier").addClass('error');
  //           $("#isdbdownloadToGlacier").addClass('error');
  //         } else {
  //           $("#isdeploycodedownloadGlacier").addClass('default');
  //           $("#isdbdownloadToGlacier").addClass('default');
  //         }
  //       }
  //     }
  //   });
  // }

  // dateToggle(data) {
  //   if (data == 'pre') {
  //     this.startDate = moment(this.startDate).subtract(1, 'days').startOf('day').format('YYYY-MM-DD h:mm a');
  //     this.todayDayDate = moment(this.startDate).startOf('day').format('MM-DD-YYYY');
  //     this.endDate = moment(this.startDate).endOf('day').format('YYYY-MM-DD h:mm a');
  //     this.dateShow = moment(this.startDate).format('MMM DD');
  //     $(".runAtGitBackup").text(this.todayDayDate + ' ' + this.schedulerTiming['daily'].gitBackup);
  //     $(".loader-response").css("display", "none");
  //     $(".loader-container").css("display", "table");
  //     this.startQuery = moment(this.startQuery).subtract(1, 'day').format('MM/DD/YYYY');
  //     this.endQuery = moment(this.startQuery).format('MM/DD/YYYY');
  //     $(".checkmark").removeClass('error');
  //     $(".checkmark").removeClass('success');
  //     $(".checkmark").removeClass('default');

  //     //  this.startQueryDate = moment(this.startQueryDate).subtract(1, 'day').startOf('day').format('YYYY-MM-DD h:mm a');//.subtract(1, 'week')
  //     // this.startQueryAdd = moment(this.startQueryDate).add(5, 'hours');
  //     //  this.startQuery = moment(this.startQuery).subtract(1, 'day').format('MM/DD/YYYY');

  //     // this.endQueryDate = moment(this.startQueryDate).endOf('day').format('YYYY-MM-DD h:mm a');//subtract(1, 'day')
  //     // this.endQueryAdd = moment(this.endQueryDate).add(5, 'hours');

  //     //this.getAllLogs();
  //   } else if (data == 'next' && this.startQuery != moment().format('MM/DD/YYYY')) {
  //     this.startDate = moment(this.startDate).add(1, 'days').startOf('day').format('YYYY-MM-DD h:mm a');
  //     this.todayDayDate = moment(this.startDate).startOf('day').format('MM-DD-YYYY');
  //     this.endDate = moment(this.startDate).endOf('day').format('YYYY-MM-DD h:mm a');
  //     this.dateShow = moment(this.startDate).format('MMM DD');
  //     $(".runAtGitBackup").text(this.todayDayDate + ' ' + this.schedulerTiming['daily'].gitBackup);
  //     $(".loader-response").css("display", "none");
  //     $(".loader-container").css("display", "table");
  //     $(".checkmark").removeClass('error');
  //     $(".checkmark").removeClass('success');
  //     $(".checkmark").removeClass('default');
  //     this.startQuery = moment(this.startQuery).add(1, 'day').format('MM/DD/YYYY');
  //     this.endQuery = moment(this.startQuery).format('MM/DD/YYYY');
  //     //this.getAllLogs();
  //     this.getAllLogs();
  //   }
  //   if (this.startQuery == moment().format('MM/DD/YYYY')) {
  //     $("#nextBtn").css("cursor", "not-allowed");
  //   } else {
  //     $("#nextBtn").css("cursor", "pointer");
  //   }
  // }

  
  
  
  
  
  countAllDatabaseNames(): number {
    let databaseCount = 0; // Initialize a counter for all database names
    
    this.backupList.forEach(item => {
      const dbName = item.databaseName; // Adjust property name as needed
      if (dbName) {
        databaseCount++; // Increment the counter for each database name
      }
    });
    
    return databaseCount; // Return the total count of all database names
  }
  countAllRepositoryNames(): number {
    let repositoryCount = 0; // Initialize a counter for all repository names
    
    this.uploadRepository.forEach(item => {
      const repositoryName = item.repositoryName; // Make sure the property name matches your data
      if (repositoryName) {
        repositoryCount++; // Increment the counter for each repository name found
      }
    });
    
    return repositoryCount; // Return the total count of all repository names
  }

  // ShowUploadRepository() {
  //   this.dtOptions3 = {
  //     pagingType: 'full_numbers',
  //     pageLength: 100,
  //     dom: 'rtlip',
  //     order: [[4, 'desc']],
  //     destroy: true,
  //     autoWidth: false,
  //     columnDefs: [
  //       { width: "20%", targets: 0 },
  //       { width: "20%", targets: 1 },
  //       { width: "35%", targets: 2 },
  //       { width: "10%", targets: 3 },
  //       { width: "15%", targets: 4 }
  //     ]
  //   };
  //   this.baltoroService.getAllUploadRepostory().subscribe(response => {
  //     if (response.status === "ok") {
  //       console.log(response.status.value);
  //       this.uploadRepository = response.data;   
  //       this.uniqueRepositoryCount = this.countAllRepositoryNames(); 
  //       console.log(this.uniqueRepositoryCount);   
  //       this.rerender(2);
  //     }
  //   }, error => {
  //     if (error.status === 401) {
  //       // Handle unauthorized access
  //     }
  //   });
  // }

  // // Show Download List
  // ShowDownload() {
  //   this.dtOptions2 = {
  //     pagingType: 'full_numbers',
  //     pageLength: 100,
  //     dom: 'rtlip',
  //     order: [[4, 'Desc']],
  //     destroy: true,
  //     autoWidth: false,
  //     columnDefs: [
  //       { width: "20%", targets: 0 },
  //       { width: "20%", targets: 1 },
  //       { width: "35%", targets: 2 },
  //       { width: "10%", targets: 3 },
  //       { width: "15%", targets: 4 }
  //     ]
  //   };
  //   this.baltoroService.getAllDownload().subscribe(response => {
  //     if (response.status === "ok") {
  //       console.log(response.status.value);
  //       this.downloadList = response.data;      
  //       this.rerender(1);
  //     }
  //   }, error => {
  //     if (error.status === 401) {
  //       // Handle unauthorized access
  //     }
  //   });
  // }
  
  // // Get Backup List
  // getBackup() {
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     pageLength: 100,
  //     dom: 'rtlip',
  //     order: [[4, 'Desc']],
  //     destroy: true,
  //     columnDefs: [
  //       { width: "20%", targets: 0 },
  //       { width: "20%", targets: 1 },
  //       { width: "35%", targets: 2 },
  //       { width: "10%", targets: 3 },
  //       { width: "15%", targets: 4 }
  //     ]
  //   };
  //   this.baltoroService.getAll().subscribe(response => {
  //     if (response.status === "ok") {
  //       this.backupList = response.data;
  //       this.uniqueDatabaseCount = this.countAllDatabaseNames(); 
  //       console.log(this.uniqueDatabaseCount); // Update the unique database count
  //       this.rerender(0);
  //     }
  //   }, error => {
  //     if (error.status === 401) {
  //       // Handle unauthorized access
  //     }
  //   });
  // }

  // // Show Download Repository
  // ShowDownloadRepository() {
  //   this.dtOptions4 = {
  //     pagingType: 'full_numbers',
  //     pageLength: 100,
  //     dom: 'rtlip',
  //     order: [[4, 'Desc']],
  //     destroy: true,
  //     autoWidth: false,
  //     columnDefs: [
  //       { width: "20%", targets: 0 },
  //       { width: "20%", targets: 1 },
  //       { width: "35%", targets: 2 },
  //       { width: "10%", targets: 3 },
  //       { width: "15%", targets: 4 }
  //     ]
  //   };
  //   this.baltoroService.getAllDownloadRepostory().subscribe(response => {
  //     if (response.status === "ok") {
  //       console.log(response.status.value);
  //       this.DownloadRepository = response.data;    
  //       this.rerender(3);
  //     }
  //   }, error => {
  //     if (error.status === 401) {
  //       // Handle unauthorized access
  //     }
  //   });
  // }

  // // Combine List
  // combineList() {
  //   this.dtOptions5 = {
  //     pagingType: 'full_numbers',
  //     pageLength: 100,
  //     dom: 'rtlip',
  //     order: [[0, 'Desc']],
  //     destroy: true,
  //     autoWidth: false,
  //     columnDefs: [
  //       { width: "20%", targets: 0 },
  //     ]
  //   };
  //   this.baltoroService.combineList().subscribe(response => {
  //     if (response.status === "ok") {
  //       console.log(response.status.value);
  //       this.CombineList = response.data;
  //       console.log("this is " + this.CombineList); 
  //       this.rerender(4);
  //     }
  //   }, error => {
  //     if (error.status === 401) {
  //       // Handle unauthorized access
  //     }
  //   });
  // }

  // // Rerender DataTables
  // rerender(index: number): void {
  //   this.dtElements.toArray()[index].dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.destroy(); // Destroy the table first
  //     switch (index) {
  //       case 0: this.dtTrigger.next(); break; // Trigger re-render for backupList table
  //       case 1: this.dtTrigger2.next(); break; // Trigger re-render for downloadList table
  //       case 2: this.dtTrigger3.next(); break; // Trigger re-render for uploadRepository table
  //       case 3: this.dtTrigger4.next(); break; // Trigger re-render for DownloadRepository table
  //       case 4: this.dtTrigger5.next(); break; // Trigger re-render for CombineList table
  //     }
  //   });
  // }
  
  // // Lifecycle Hooks
  // ngAfterViewInit(): void {
  //   this.getBackup(); // Ensure to fetch backup data on component initialization
  //   this.dtTrigger.next();
  //   this.dtTrigger2.next();
  //   this.dtTrigger3.next();
  //   this.dtTrigger4.next();
  //   this.dtTrigger5.next();
  // }
  
  // ngOnDestroy(): void {
  //   // Unsubscribe from triggers
  //   this.dtTrigger.unsubscribe();
  //   this.dtTrigger2.unsubscribe();
  //   this.dtTrigger3.unsubscribe();
  //   this.dtTrigger4.unsubscribe();
  //   this.dtTrigger5.unsubscribe();
  // }

 

  ShowDownload() {
    
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 100,
      dom: 'rtlip',
      order: [[4, 'desc']],
     // processing: true,
      "destroy": true,
      autoWidth: false,
      columnDefs: [
        { width: "20%", targets: 0,orderable:false },
        { width: "20%", targets: 1 ,orderable:false},
        { width: "35%", targets: 2,orderable:false },
        {width: "10%" , targets: 3,orderable:false},
        {width: "15%" , targets: 4,orderable: true },
       
        
      ]
    };
    this.baltoroService.getAllDownload().subscribe(response => {
      if (response.status === "ok") {
       
        //console.log(response.status.value);
          // Filter out only active websites
          this.downloadList = response.data;      
         
          this.rerender(1);
        
      }
   
    

    }, error => {
      if (error.status == 401) { }
    });
  }
  
  getBackup() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      dom: 'rtlip',
      order: [[4, 'desc']], 
      // processing: true,
      "destroy": true,
      autoWidth: false,
      columnDefs: [
        { width: "20%", targets: 0, orderable: false },
        { width: "20%", targets: 1, orderable: false },
        { width: "35%", targets: 2, orderable: false },
        { width: "10%", targets: 3, orderable: false },
        { width: "15%", targets: 4 } // Ensure the type for date sorting is correct
      ]
    };
    
    
    this.baltoroService.getAll().subscribe(response => {
      if (response.status === "ok") {
          this.backupList = response.data;
          console.log(this.backupList);
          this.uniqueDatabaseCount = this.countAllDatabaseNames(); 
          //console.log(this.uniqueDatabaseCount);// Update the unique database count
          this.rerender(0);
      }
   
    

    }, error => {
      if (error.status == 401) { }
    });
  }

  ShowUploadRepository() {
    
    this.dtOptions3 = {
      pagingType: 'full_numbers',
      pageLength: 100,
      dom: 'rtlip',
      order: [[4, 'desc']],
     // processing: true,
      "destroy": true,
      autoWidth: false,
      columnDefs: [
        { width: "20%", targets: 0 },
        { width: "20%", targets: 1 },
        { width: "35%", targets: 2 },
        {width: "10%" , targets: 3},
        {width: "15%" , targets: 4},
       
        
      ]
    };
    this.baltoroService.getAllUploadRepostory().subscribe(response => {
      if (response.status === "ok") {
       
        //console.log(response.status.value);
          // Filter out only active websites
          this.uploadRepository = response.data;   
          console.log(this.uploadRepository);
          this.uniqueRepositoryCount = this.countAllRepositoryNames(); 
         // console.log(this.uniqueRepositoryCount);   
         
          this.rerender(2);
        
      }
   
    

    }, error => {
      if (error.status == 401) { }
    });
  }
ShowDownloadRepository(){
  this.dtOptions4 = {
    pagingType: 'full_numbers',
    pageLength: 100,
    dom: 'rtlip',
    order: [[4, 'desc']],
   // processing: true,
    "destroy": true,
    autoWidth: false,
    columnDefs: [
      { width: "20%", targets: 0,orderable:false },
      { width: "20%", targets: 1 ,orderable:false},
      { width: "35%", targets: 2,orderable:false },
      {width: "10%" , targets: 3,orderable:false},
      {width: "15%" , targets: 4},
     
      
    ]
  };
  this.baltoroService.getAllDownloadRepostory().subscribe(response => {
    if (response.status === "ok") {
     
      //console.log(response.status.value);
        this.downloadRepository = response.data;    
        this.rerender(3);
      
    }
  }, error => {
    if (error.status == 401) { }
  });
}
combineLists(){
  this.dtOptions5 = {
    pagingType: 'full_numbers',
    pageLength: 100,
    dom: 'rtlip',
   // order: [[0, 'Desc']],
   // processing: true,
    "destroy": true,
    autoWidth: false,
    columnDefs: [
      { width: "20%", targets: 0 },
      

    ]
  };
  this.baltoroService.combineList().subscribe(response => {
    if (response.status === "ok") {
     
    //  console.log(response.status.value);
       this.combineList=response.data;
      // console.log("this is "+ this.combineList); 
       this.rerender(4);
      
    }
  }, error => {
    if (error.status == 401) { }
  });
}

  rerender(index: number): void {
    this.dtElements.toArray()[index].dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy(); // Destroy the table first
      if (index === 0) {
        this.dtTrigger.next(); // Trigger re-render for backupList table
      } else if (index === 1) {
        this.dtTrigger2.next(); // Trigger re-render for downloadList table
      }
      else if (index === 2) {
        this.dtTrigger3.next(); // Trigger re-render for downloadList table
      }
      else if (index === 3) {
        this.dtTrigger4.next(); // Trigger re-render for downloadList table
      }
      else if (index === 4) {
        this.dtTrigger5.next(); // Trigger re-render for downloadList table
      }
    });
  }
  
  // Lifecycle Hooks
  ngAfterViewInit(): void {
  //  this.getBackup(); 
    this.dtTrigger.next();
    this.dtTrigger2.next();
    this.dtTrigger3.next();
    this.dtTrigger4.next();
    this.dtTrigger5.next();
  }
  
  // ngOnDestroy(): void {
  //   // Unsubscribe from triggers
  //   this.dtTrigger.unsubscribe();
  //   this.dtTrigger2.unsubscribe();
  //   this.dtTrigger3.unsubscribe();
  //   this.dtTrigger4.unsubscribe();
  //   this.dtTrigger5.unsubscribe();
  // }
  // refreshAllRecords() {
  //   this.getDataByGravity().then(() => {
  //     //second funtion call
  //     this.getDataByRocket().then(() => {
  //       //third funtion call
  //       this.getDataByApproachMe().then(() => {
  //         //fourth funtion call
  //         this.getDataBySkinnyShrimp().then(() => {
  //           //hide loader                       
  //           $(".loader-container").css("display", "none");
  //           $(".loader-response").css("display", "block");
  //         }).catch(() => { });
  //       }).catch(() => { });
  //     }).catch(() => { });
  //   }).catch(() => { });
  // }

  // getDataByGravity() {
  //   return new Promise((resolve, reject) => {
  //     var isGravityLog = 0;
  //     var isdeploycodedownloadGlacier = 0;
  //     var isdbdownloadToGlacier = 0;
  //     for (var i = 0; i < this.allRecords.length; i++) {
  //       if (this.allRecords[i].host == "Gravity") {
  //         var checkDate = moment.utc(this.allRecords[i].dateAdded).local().format('YYYY-MM-DD h:mm a');
  //         if (this.allRecords[i].description == "Git BackUp End") {
  //           if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //             isGravityLog++
  //           }
  //         }
  //         if (this.allRecords[i].serviceName == "db-download") {
  //           if (this.allRecords[i].type == "db-download-database-start") {
  //             if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //               isdbdownloadToGlacier++
  //             }
  //           }
  //         }
  //         if (this.allRecords[i].serviceName == "db-download") {
  //           if (this.allRecords[i].type == "db-download-deployed-apps-start") {
  //             if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //               isdeploycodedownloadGlacier++
  //             }
  //           }
  //         }
  //       }
  //     }

  //     // $(".lastPullGravityLog").text(gitBackupDate.length > 0 ? (moment(gitBackupDate[gitBackupDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(gitBackupDate[gitBackupDate.length - 1]).fromNow() + ")") : '');
  //     // $(".lastdbdownloadToGlacierLog").text(dbdownloadDate.length > 0 ? (moment(dbdownloadDate[dbdownloadDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(dbdownloadDate[dbdownloadDate.length - 1]).fromNow() + ")") : '');
  //     // $(".lastdeploycodedownloadLog").text(dcdownloadDate.length > 0 ? (moment(dcdownloadDate[dcdownloadDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(dcdownloadDate[dcdownloadDate.length - 1]).fromNow() + ")") : '');

  //     if (isGravityLog == 0) {
  //       if (this.schedulerTiming['daily'].gitBackup <= moment().format('h:mm a')) {
  //         $("#isGravityLog").addClass('error');
  //       } else {
  //         $("#isGravityLog").addClass('default');
  //       }
  //     } else {
  //       $("#isGravityLog").addClass('success');
  //     }
  //     if (isdbdownloadToGlacier == 0) {

  //       if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //         $("#isdbdownloadToGlacier").addClass('error');
  //       } else {
  //         $("#isdbdownloadToGlacier").addClass('default');
  //       }
  //     } else {
  //       $("#isdbdownloadToGlacier").addClass('success');
  //     }

  //     if (isdeploycodedownloadGlacier == 0) {
  //       if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //         $("#isdeploycodedownloadGlacier").addClass('error');
  //       } else {
  //         $("#isdeploycodedownloadGlacier").addClass('default');
  //       }
  //     } else {
  //       $("#isdeploycodedownloadGlacier").addClass('success');
  //     }
  //     resolve();
  //   });
  // }

  // getDataByRocket() {
  //   return new Promise((resolve, reject) => {
  //     var isRocketLog = 0;
  //     var isuploadtoGlacier = 0;
  //     for (var i = 0; i < this.allRecords.length; i++) {
  //       if (this.allRecords[i].host == "Rocket") {
  //         var checkDate = moment.utc(this.allRecords[i].dateAdded).local().format('YYYY-MM-DD h:mm a');
  //         if (this.allRecords[i].description == "Git BackUp End") {
  //           if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //             isRocketLog++
  //           }
  //           // }
  //         }

  //         if (this.allRecords[i].description == "Git Upload End") {
  //           if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //             isuploadtoGlacier++
  //           }
  //           // }
  //         }
  //       }
  //     }

  //     // $(".lastPullRocketLog").text(gitBackupDate.length > 0 ? (moment(gitBackupDate[gitBackupDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(gitBackupDate[gitBackupDate.length - 1]).fromNow() + ")") : '');
  //     // $(".lastuploadtoGlacierLog").text(gitUploadDate.length > 0 ? (moment(gitUploadDate[gitUploadDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(gitUploadDate[gitUploadDate.length - 1]).fromNow() + ")") : '');

  //     if (isRocketLog == 0) {
  //       if (this.schedulerTiming['daily'].gitBackup <= moment().format('h:mm a')) {
  //         $("#isRocketLog").addClass('error');
  //       } else {
  //         $("#isRocketLog").addClass('default');
  //       }
  //     } else {
  //       $("#isRocketLog").addClass('success');
  //     }

  //     if (isuploadtoGlacier == 0) {
  //       if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //         $("#isuploadtoGlacier").addClass('error');
  //       } else {
  //         $("#isuploadtoGlacier").addClass('default');
  //       }
  //     } else {
  //       $("#isuploadtoGlacier").addClass('success');
  //     }
  //     resolve();
  //   });
  // }

  // getDataByApproachMe() {
  //   return new Promise((resolve, reject) => {
  //     var isdbBackupApproacheMe = 0;
  //     var isUploadApproacheMe = 0;
  //     var isdeploycodeBackupAM = 0;
  //     for (var i = 0; i < this.allRecords.length; i++) {
  //       if (this.allRecords[i].host == "ApproachMe Server") {
  //         var checkDate = moment.utc(this.allRecords[i].dateAdded).local().format('YYYY-MM-DD h:mm a');
  //         if (this.allRecords[i].serviceName == "db-backup") {
  //           if (this.allRecords[i].type == "db-backup-start") {
  //             if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //               isdbBackupApproacheMe++
  //             }
  //           }
  //         }

  //         if (this.allRecords[i].type == "db-upload-start") {
  //           if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //             isUploadApproacheMe++
  //           }
  //         }

  //         if (this.allRecords[i].serviceName == "backup-box") {
  //           if (this.allRecords[i].type == "backup-box-start") {
  //             // backupBoxDate.push(checkDate);
  //             if (this.allRecords[i].status == 'success') {
  //               isdeploycodeBackupAM++
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // $(".lastBackupApproacheMeLog").text(dbBackupDate.length > 0 ? (moment(dbBackupDate[dbBackupDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(dbBackupDate[dbBackupDate.length - 1]).fromNow() + ")") : '');
  //     // $(".lastUploadApproacheMeLog").text(dbUploadDate.length > 0 ? (moment(dbUploadDate[dbUploadDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(dbUploadDate[dbUploadDate.length - 1]).fromNow() + ")") : '');
  //     // $(".lastdeploycodeBackupAMLog").text(backupBoxDate.length > 0 ? (moment(backupBoxDate[backupBoxDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(backupBoxDate[backupBoxDate.length - 1]).fromNow() + ")") : '');

  //     if (isdbBackupApproacheMe == 0) {
  //       if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //         $("#isdbBackupApproacheMe").addClass('error');
  //       } else {
  //         $("#isdbBackupApproacheMe").addClass('default');
  //       }
  //     } else {
  //       $("#isdbBackupApproacheMe").addClass('success');
  //     }
  //     if (isUploadApproacheMe == 0) {
  //       if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //         $("#isUploadApproacheMe").addClass('error');
  //       } else {
  //         $("#isUploadApproacheMe").addClass('default');
  //       }
  //     } else {
  //       $("#isUploadApproacheMe").addClass('success');
  //     }
  //     if (isdeploycodeBackupAM == 0) {
  //       $("#isdeploycodeBackupAM").addClass('error');
  //     } else {
  //       $("#isdeploycodeBackupAM").addClass('success');
  //     }
  //     resolve();
  //   });
  // }

  // getDataBySkinnyShrimp() {
  //   return new Promise((resolve, reject) => {
  //     var isdbBackupSkinnyShrimp = 0;
  //     var isUploadSkinnyShrimp = 0;
  //     var isdeploycodeBackupSS = 0;
  //     var dbUploadDate = [];
  //     for (var i = 0; i < this.allRecords.length; i++) {
  //       if (this.allRecords[i].host == "SkinnyShrimp Server") {
  //         var checkDate = moment.utc(this.allRecords[i].dateAdded).local().format('YYYY-MM-DD h:mm a');
  //         if (this.allRecords[i].serviceName == "db-backup") {
  //           if (this.allRecords[i].type == "db-backup-start") {
  //             if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //               isdbBackupSkinnyShrimp++
  //             }
  //           }
  //         }

  //         if (this.allRecords[i].type == "db-upload-start") {
  //           dbUploadDate.push(checkDate);
  //           if (moment(checkDate).isBetween(this.startDate, this.endDate)) {
  //             isUploadSkinnyShrimp++
  //           }
  //         }

  //         if (this.allRecords[i].serviceName == "backup-box") {
  //           if (this.allRecords[i].type == "backup-box-start") {
  //             if (this.allRecords[i].status == 'success') {
  //               isdeploycodeBackupSS++
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // $(".lastBackupSkinnyShrimpLog").text(dbBackupDate.length > 0 ? (moment(dbBackupDate[dbBackupDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(dbBackupDate[dbBackupDate.length - 1]).fromNow() + ")") : '');
  //     // $(".lastUploadSkinnyShrimpLog").text(dbUploadDate.length > 0 ? (moment(dbUploadDate[dbUploadDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(dbUploadDate[dbUploadDate.length - 1]).fromNow() + ")") : '');
  //     // $(".lastdeploycodeBackupSSLog").text(backupBoxDate.length > 0 ? (moment(backupBoxDate[backupBoxDate.length - 1]).format('MM-DD-YYYY h:mm a') + " (" + moment(backupBoxDate[backupBoxDate.length - 1]).fromNow() + ")") : '');

  //     if (isdbBackupSkinnyShrimp == 0) {
  //       if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //         $("#isdbBackupSkinnyShrimp").addClass('error');
  //       } else {
  //         $("#isdbBackupSkinnyShrimp").addClass('default');
  //       }
  //     } else {
  //       $("#isdbBackupSkinnyShrimp").addClass('success');
  //     }

  //     if (isUploadSkinnyShrimp == 0) {
  //       if (this.runAtPrevious == moment(this.startDate).format('MM-DD-YYYY')) {
  //         $("#isUploadSkinnyShrimp").addClass('error');
  //       } else {
  //         $("#isUploadSkinnyShrimp").addClass('default');
  //       }
  //     } else {
  //       $("#isUploadSkinnyShrimp").addClass('success');
  //     }

  //     if (isdeploycodeBackupSS == 0) {
  //       $("#isdeploycodeBackupSS").addClass('error');
  //     } else {
  //       $("#isdeploycodeBackupSS").addClass('success');
  //     }
  //     resolve();
  //   });
  // }

  // getLastSuccessful() {
  //   this.baltoroService.getLastSuccess().subscribe(res => {
  //     if (res.status == "ok") {
  //       //lastsuccessful Upload to Glacier By Rocket
  //       $(".lastuploadtoGlacierLog").text(res.data.uploadToGlacier != null ? (moment.utc(res.data.uploadToGlacier.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.uploadToGlacier.dateAdded).local().fromNow() + ")") : '');

  //       //lastsuccessful Git Pull By Gravity and Rocket
  //       $(".lastPullRocketLog").text(res.data.pullRocket != null ? (moment.utc(res.data.pullRocket.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.pullRocket.dateAdded).local().fromNow() + ")") : '');
  //       $(".lastPullGravityLog").text(res.data.pullGravity != null ? (moment.utc(res.data.pullGravity.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.pullGravity.dateAdded).local().fromNow() + ")") : '');

  //       //lastsuccessful Download database to Gravity from Glacier
  //       $(".lastdbdownloadToGlacierLog").text(res.data.dbDownloadToGlacier != null ? (moment.utc(res.data.dbDownloadToGlacier.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.dbDownloadToGlacier.dateAdded).local().fromNow() + ")") : '');

  //       //lastsuccessful Download Deployed Code to Gravity from Glacier
  //       $(".lastdeploycodedownloadLog").text(res.data.deployCodeDownload != null ? (moment.utc(res.data.deployCodeDownload.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.deployCodeDownload.dateAdded).local().fromNow() + ")") : '');

  //       //lastsuccessful database backup SkinnyShrimp and ApproachMe
  //       $(".lastBackupSkinnyShrimpLog").text(res.data.backupSS != null ? (moment.utc(res.data.backupSS.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.backupSS.dateAdded).local().fromNow() + ")") : '');
  //       $(".lastBackupApproacheMeLog").text(res.data.backupAM != null ? (moment.utc(res.data.backupAM.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.backupAM.dateAdded).local().fromNow() + ")") : '');

  //       //lastsuccessful database upload SkinnyShrimp and ApproachMe
  //       $(".lastUploadSkinnyShrimpLog").text(res.data.uploadSSp != null ? (moment.utc(res.data.uploadSSp.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.uploadSSp.dateAdded).local().fromNow() + ")") : '');
  //       $(".lastUploadApproacheMeLog").text(res.data.uploadAM != null ? (moment.utc(res.data.uploadAM.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.uploadAM.dateAdded).local().fromNow() + ")") : '');

  //       //lastsuccessful backup-box SkinnyShrimp and ApproachMe
  //       $(".lastdeploycodeBackupSSLog").text(res.data.deployCodeBackupSS != null ? (moment.utc(res.data.deployCodeBackupSS.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.deployCodeBackupSS.dateAdded).local().fromNow() + ")") : '');
  //       $(".lastdeploycodeBackupAMLog").text(res.data.deployCodeBackupAM != null ? (moment.utc(res.data.deployCodeBackupAM.dateAdded).local().format('MM-DD-YYYY h:mm a') + " (" + moment.utc(res.data.deployCodeBackupAM.dateAdded).local().fromNow() + ")") : '');
  //     }
  //   }, error => {
  //     if (error.status == 401) { }
  //   });
  // }
}
