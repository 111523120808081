import { Component, OnInit } from '@angular/core';
import { TaskService } from 'src/services/task.service';
import { AppService } from 'src/app/app.service';
import { DashboardService } from 'src/services/dashboard.service';
import * as moment from 'moment';

@Component({
  selector: 'app-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.css']
})
export class WeeklyComponent implements OnInit {
  public startDate = moment().format('MM/DD/YYYY');
  public endDate = moment().format('MM/DD/YYYY');
  public year: any = moment().format('YYYY');
  public selectDate: any;
  public projectsList = [];
  public usersList = [];
  public monTime = 0;
  public tueTime = 0;
  public wesTime = 0;
  public thuTime = 0;
  public friTime = 0;
  public satTime = 0;
  public sunTime = 0;
  public weekTime = 0;
  public dailyMonTime = 0;
  public dailyTueTime = 0
  public dailyWesTime = 0;
  public dailyThuTime = 0;
  public dailyFriTime = 0;
  public dailySatTime = 0;
  public dailySunTime = 0;
  constructor(
    private taskService: TaskService,
    private appService: AppService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Weekly Report"
    });
    this.changeDate('today');
  }

  changeDate(data?: any) {
    let todayDate = moment().endOf('week').add(1, 'day').format('MM/DD/YYYY');//.subtract(1, 'w')
    let requestCall = false;
    if (data == 'prev') {
      this.startDate = moment(this.startDate).subtract(1, 'w').format('MM/DD/YYYY');
      this.endDate = moment(this.startDate).add(1, 'w').subtract(1, 'day').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'next' && todayDate != this.endDate) {
      this.startDate = moment(this.startDate).add(1, 'w').format('MM/DD/YYYY');
      this.endDate = moment(this.startDate).add(1, 'w').subtract(1, 'day').format('MM/DD/YYYY');
      requestCall = true;
    } else if (data == 'today') {
      this.startDate = moment().startOf('week').add(1, 'day').format('MM/DD/YYYY');//.subtract(1, 'w')
      this.endDate = moment(this.startDate).endOf('week').add(1, 'day').format('MM/DD/YYYY');
      requestCall = true;
    }

    if (requestCall) {
      $(".custom-loader").css("display", "table");
      $(".loader-response").css("display", "none");
      this.selectDate = moment(this.startDate).format('MM/DD/YYYY') + " - " + moment(this.endDate).format('MM/DD/YYYY');
      this.getTasksByProjects(this.startDate, this.endDate);
      this.getTasksByUsers(this.startDate, this.endDate);
    }

    if (this.endDate == todayDate) {
      $("#nextBtn").css("cursor", "not-allowed");
    } else {
      $("#nextBtn").css("cursor", "pointer");
    }

  }

  getTasksByProjects(startDate, endDate) {
    this.dashboardService.getProjectsReport(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        let data = res.data;
        if (data.length == 0) {
          this.projectsList = [];
          // this.changeDate('prev');
        } else {
          this.projectsList = data;
          this.projectsList.sort((a, b) => { return b.totalHours - a.totalHours; });
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  getTasksByUsers(startDate, endDate) {
    this.dashboardService.getUsersReport(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        let data = res.data;
        if (data.length == 0) {
          this.usersList = [];
          // this.changeDate('prev');
        } else {
          this.usersList = data;
          this.usersList.sort((a, b) => { return b.totalHours - a.totalHours; });
        }
        $(".custom-loader").css("display", "none");
        $(".loader-response").css("display", "block");
      }
    }, error => {
      if (error.status == 401) { }
    });
  }
}
