import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'year'
})
export class yearPipe implements PipeTransform {

  transform(value: Date | string, format: string = 'MM/DD/YYYY'): string {
    if (!value) return '';

    const date = new Date(value);

    // Define month names
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Extract the month name
    const monthName = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as 'Month Day, Year'
    return `${monthName} ${day}, ${year}`;
  }
}
