import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class RatingService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
) {
  }

  addRating(rating) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/UserRating';
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(request, rating, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  getRatings(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/UserRating/getuserratings?id=${id}`;
    return this.http.get(request,options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  getRating(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/UserRating/' + id;
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  updateRating(rating) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/UserRating/' + rating.id;
    // let headers = new Headers({ 'Content-Type': 'application/json' ,'Access-Control-Allow-Origin':true});
    // let options = new RequestOptions({ headers: headers });
    return this.http.put(request, rating, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
  deleteRating(id){
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/UserRating/' + id;
    // let headers = new Headers({ 'Content-Type': 'application/json' ,'Access-Control-Allow-Origin':true});
    // let options = new RequestOptions({ headers: headers });
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
    ));
  }
}
