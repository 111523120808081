import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/services/projects.service';
import { NotificationService } from 'src/services/notification.service';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';
import { GlobalVariable } from "src/app/globals";
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class ProjectAddComponent implements OnInit {
  projectName: string = '';
  description: string = '';
  isActive: boolean = true;
  files: FileList;
  private baseUrl = GlobalVariable.BASE_API_URL;
  public URL: string = this.baseUrl + "api/FileUpload/projectuploadfile?id=";
  formData: FormData = new FormData();
  projectId: number = null;
  public year: any = moment().format('YYYY');
  
  constructor(
    private appService: AppService,
    private projectService: ProjectService,
    private noti: NotificationService,
    private router: Router,
    private http: Http
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Projects"
    });
  }
  addProject() {
    $(".btn-submit").prop('disabled', true);
    let isValid = this.validate();
    if (isValid) {
      var project = {
        ProjectName: '',
        Description: '',
        IsActive: true,
      };
      project.ProjectName = this.projectName.trim();
      project.Description = this.description.trim();
      project.IsActive = this.isActive;
      this.projectService.addProject(project).subscribe(res => {
        if (res.status == "ok") {
          this.projectId = res.data.id;
          if (this.files) {
            this.uploadFile();
          } else {
            this.noti.notification("Success", "Project Added Successfully", "success");
            this.router.navigateByUrl('/project/list');
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.noti.notification("Error", "Please fill all details", "danger");
      $(".btn-submit").prop('disabled', false);
    }

  }
  validate() {
    if (!(!!this.projectName.trim())) {
      return false;
    }
    if (!(!!this.description.trim())) {
      return false;
    }
    return true;
  }

  addFiles(event) {
    this.formData.delete('files')
    if (event.target.files && event.target.files[0]) {
      //var reader = new FileReader();
      const fileReader: FileReader = new FileReader();

      fileReader.onload = (e) => {
        $('#profile-viewer').attr('src', e.target.result);
        //$('#profile-viewer').attr('src', fileReader.result.toString());
      }
      fileReader.readAsDataURL(event.target.files[0]);
    }

    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      this.formData.append('files', this.files[i], this.files[i].name)
    }
  }
  uploadFile() {
    this.URL = this.URL + this.projectId;
    this.http.post(this.URL, this.formData)
      .subscribe(respo => {
        this.noti.notification("Success", "Project Added Successfully", "success");
        this.router.navigateByUrl('/project/list');
      }, (error: HttpErrorResponse) => {
        // console.error(error);
      });
  }
}
