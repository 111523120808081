import { CodeCommitService } from 'src/services/codecommit.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
declare var $;

@Component({
  selector: 'app-view-repository',
  templateUrl: './view-repository.component.html',
  styleUrls: ['./view-repository.component.css']
})
export class ViewRepositoryComponent implements OnInit {
  public loginUser: any = '';
  public repositoryLogs: any = [];
  public repositoryName = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  public pageNumber = 1;
  public pageSize = 25;
  public sortOrder = "desc";
  public sortBy = "DateAdded";
  public search = "";
  public year: any = moment().format('YYYY');
  public totalCount: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private codeCommitService: CodeCommitService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "CodeCommit"
    });
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    $('[data-toggle="tooltip"]').tooltip();
    let param = this.route.snapshot.queryParams.id;
    if (!!param) {
      this.getRepositoryLogs(param);
    } else {
      this.router.navigateByUrl('/codecommit/repository-list');
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getRepositoryLogs(id) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageSize,
      dom: 'frtlip',
      // dom: 'Bfrtip',
      order: [[4, 'desc']],
      "columnDefs": [
        { "width": "10%", "orderable": false, "targets": 0 },//"width": "25%", 
        { "width": "8%", "orderable": false, "targets": 1 },
        { "targets": 2 },
        { "orderable": false, "targets": 3 },
        { "orderable": false, "targets": 4 },
        { "width": "12%", "targets": 5 }
      ],
      //processing: true,
      serverSide: true,
      // orderMulti: false,
      ajax: (dTParameters: any, callback) => {
        this.pageSize = dTParameters['length'];
        this.pageNumber = dTParameters['start'] / this.pageSize + 1;
        this.sortOrder = dTParameters['order'][0].dir;
        this.search = dTParameters['search']['value'];
        let column = dTParameters['order'][0].column;
        switch (column) {
          case 3:
            this.sortBy = "DateAdded";
            break;
          case 1:
            this.sortBy = "CommitterName";
            break;
          // case 5:
          //   this.sortBy = "RepositoryName";
          //   break;
          default:
            this.sortBy = "DateAdded";
        }

        this.codeCommitService.getRepositoryLogs(this.pageNumber, this.pageSize, this.sortBy, this.sortOrder, this.search, id).subscribe(response => {
          if (response != null) {
            if (response.status == "ok") {
              this.repositoryLogs = response.data;
              if (response.data.length > 0) {
                this.repositoryName = response.data[0].repositoryName
                this.totalCount = response.data[0].totalRecords;
              } else {
                this.totalCount = 0;
              }
              if (this.repositoryLogs.length != 0) {
                setTimeout(() => {
                  $(".dataTables_empty").css("display", "none");
                }, 200);
              }
              callback({
                recordsTotal: this.totalCount,
                recordsFiltered: this.totalCount,
                data: []
              });
            }
          }
        }, error => {
          if (error.status == 401) { }
        });
      },
      columns: [
        { "data": 'committerId', "orderable": false },
        { "data": 'branch', "orderable": false },
        { "data": 'committerName' },
        { "data": 'committerMessage', "orderable": false },
        { "data": 'metaData', "orderable": false },
        { "data": 'committerDate' }
      ]
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
