import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/services/user.service';
import { NotificationService } from 'src/services/notification.service';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';

declare var $;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class UserListComponent implements OnInit {
  public loginUser: any = '';
  public userList: any = [];
  public userId = 0;
  public cookies=0;
  public year: any = moment().format('YYYY');
  isActive: boolean = true;
  activeUserText: string = 'Show InActive User ';
  inactiveUserText: string =  ' Hide InActive User';
  // public description = "";
  // public userName = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  constructor(
    private appService: AppService,
    private userService: UserService,
    private noti: NotificationService
  ) {

  }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "People"
    });
    $('[data-toggle="tooltip"]').tooltip();
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.getUsers();
  }
  
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  // DataTable Show data 
  getUsers() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      dom: 'frtlip',
      order: [[5, 'asc']],
      processing: true,
      "destroy": true,
      "columnDefs": [
        { "width": "2%", "orderable": false, "targets": 0 },
        { "width": "20%", "targets": 1 },
        { "width": "32%", "orderable": false, "targets": 2 },
        { "width": "16%", "targets": 3 },
        { "width": "15%", "targets": 4 },
        { "width": "15%", "orderable": false, "targets": 5 },
        // { "width": "10%", "orderable": false, "targets": 6 },
      ]
    };

    
    // User/GetAll Api Call 
    this.userService.getAll().subscribe(response => {
      if (response != null) {
        if (response.status == "ok") {
          let data = response.data.value.usersItems;
          if (data.length > 0) {
            this.userList = data;
            for (let i = 0; i < this.userList.length; i++) {
              this.userList = data.filter(user => $.trim(user.isActive) === 'true');
            //  $.trim(this.userList[i].isActive) == "true" ? this.userList[i].isActive = true : this.userList[i].isActive = false;
              // this.userList[i].lastLogin = moment(this.userList[i].lastLogin).fromNow();
              // this.userList[i].dateAdded = moment(this.userList[i].dateAdded).fromNow();
            }
            this.rerender();
          }
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }


  // Delete Function But Front End comment
  confirmDelete(id) {
    this.userId = id;
    $("#deleteModal").modal("show");
  }

  deleteUser() {
    this.userService.deleteUser(this.userId).subscribe(res => {
      if (res.status == "ok") {
        for (var i = 0; i < this.userList.length; i++) {
          if (this.userList[i].userId == this.userId) {
            this.userList.splice(i, 1);
          }
        }
        this.rerender();
        this.noti.notification("Success", "User Deleted Successfully", "success");
      } else {
        this.noti.notification("Error", "Cannot Delete This User", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });

    $("#deleteModal").modal("hide");
  }

  // Filter User check Active or Inactive

  filterUsers() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      dom: 'frtlip',
      order: [[5, 'asc']],
      processing: true,
      "destroy": true,
      columnDefs: [
        { width: "2%", orderable: false, targets: 0 },
        { width: "20%", targets: 1 },
        { width: "32%", orderable: false, targets: 2 },
        { width: "16%", targets: 3 },
        { width: "15%", targets: 4 },
        { width: "15%", orderable: false, targets: 5 },
      ]
    };

    this.userService.getAll().subscribe(response => {
      if (response != null) {
        if (response.status === "ok") {
          let data = response.data.value.usersItems;
          if (data.length > 0) {
            if (this.isActive) {
              this.userList = data.filter(user => $.trim(user.isActive) === 'true');
            } else {
              this.userList = data.filter(user => $.trim(user.isActive) === 'false');
            }
            this.rerender();
          }
        }
      }
    }, error => {
      if (error.status == 401) {
       
      }
    });
  }

  toggleUserStatus() {
    this.isActive = !this.isActive;
    this.filterUsers();
  }

  getButtonText() {
    return this.isActive ? this.activeUserText : this.inactiveUserText;
  }


  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
