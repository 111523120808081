import { Http, RequestOptions, Headers } from '@angular/http';
import { GlobalVariable } from "src/app/globals";
import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

@Injectable()

export class TaskService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  public loginUser: any = '';
  public userId: number = 0;

  constructor(private http: Http) {
   
  }

  getTaskList(pageNumber, pageSize, sortOrder, sortBy, search,id) {
    let request = this.baseApiUrl + `api/usertasks/getusertasks?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}&userId=${id}`;
    return this.http.get(request).pipe(map(response => { return response.json() }));
  }

  addTask(task) {
    let request = this.baseApiUrl + 'api/usertasks';
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(request, task, options).pipe(map(response => { return response.json() }));
  }

  getTask(id) {
    let request = this.baseApiUrl + 'api/usertasks/' + id;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(request, options).pipe(map(response => { return response.json() }));
  }

  updateTask(task) {
    let request = this.baseApiUrl + 'api/usertasks/' + task.id;
    let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': true });
    let options = new RequestOptions({ headers: headers });
    return this.http.put(request, task, options).pipe(map(response => { return response.json() }));
  }

  deleteTask(id) {
    let request = this.baseApiUrl + 'api/usertasks/' + id;
    let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': true });
    let options = new RequestOptions({ headers: headers });
    return this.http.delete(request, options).pipe(map(response => { return response.json() }));
  }

  getTasksFilter(startDate, endDate) {
    let request = this.baseApiUrl + `api/usertasks/gettasksbyfilter?startDate=${startDate}&endDate=${endDate}`;
    let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': "*" });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(request, options).pipe(map(response => { return response.json() }));
  }
 
  getProjectsReport(startDate, endDate) {
    let request = this.baseApiUrl + `api/usertasks/getprojectsreport?startDate=${startDate}&endDate=${endDate}`;
    let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': "*" });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(request, options).pipe(map(response => { return response.json() }));
  }

  getUsersReport(startDate, endDate) {
    let request = this.baseApiUrl + `api/usertasks/getusersreport?startDate=${startDate}&endDate=${endDate}`;
    let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': "*" });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(request, options).pipe(map(response => { return response.json() }));
  }

  getAssignTask(id) {
    let request = this.baseApiUrl + `api/usertasks/getassigntasks?id=${id}`;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(request, options).pipe(map(response => { return response.json() }));
  }
}
