import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { NotificationService } from 'src/services/notification.service';
import { ProjectService } from 'src/services/projects.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { GlobalVariable } from "src/app/globals";
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class ProjectEditComponent implements OnInit {
  projectId: string = '';
  projectDetail: any = [];
  projectName: string = '';
  description: string = '';
  isActive: boolean;
  files: FileList;
  private baseUrl = GlobalVariable.BASE_API_URL;
  public URL: string = this.baseUrl + "api/FileUpload/projectuploadfile?id=";
  formData: FormData = new FormData();
  public year: any = moment().format('YYYY');


  constructor(
    private projectService: ProjectService,
    private noti: NotificationService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private http: Http
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Projects"
    });
    this.projectId = this.route.snapshot.queryParams.id;
    if (!!this.projectId) {
      this.getProject();
    } else {
      this.router.navigateByUrl('/project/list');
    }
  }
  getProject() {
    if (!!this.projectId) {
      this.projectService.getProject(this.projectId).subscribe(response => {
        if (response.status == "ok") {
          if (!!response.data) {
            this.projectDetail = response.data;
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.noti.notification("Error", "Project not Found", "danger");
    }
  }
  editProject() {
    $(".btn-submit").prop('disabled', true);
    let isValid = this.validate();
    if (isValid) {
      this.projectService.updateProject(this.projectDetail).subscribe(res => {
        if (res.status == "ok") {
          if (this.files) {
            this.uploadFile();
          } else {
            this.noti.notification("Success", "Project Edited Successfully", "success");
            this.router.navigateByUrl('/project/list');
          }
        } else {
          this.noti.notification("Error", "Project not Updated", "danger");
          $(".btn-submit").prop('disabled', false);
        }
      }, error => {
        if (error.status == 401) { }
      });
    }
  }
  validate() {
    if (!(!!this.projectDetail.projectName.trim())) {
      return false;
    }
    if (!(!!this.projectDetail.description.trim())) {
      return false;
    }
    return true;
  }
  addFiles(event) {
    this.formData.delete('files')
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#profile-viewer').attr('src', e.target.result);
      }
      reader.readAsDataURL(event.target.files[0]);
    }

    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      this.formData.append('files', this.files[i], this.files[i].name)
    }
  }
  uploadFile() {
    this.URL = this.URL + this.projectId;
    this.http.post(this.URL, this.formData)
      .subscribe(respo => {
        this.noti.notification("Success", "Project Edited Successfully", "success");
        this.router.navigateByUrl('/project/list');
      }, (error: HttpErrorResponse) => {
        // console.error(error);
      });
  }
}
